/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Drawer, Typography, Divider } from '@mui/material';
import { Table, Switch, Space } from 'antd';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import WJHeaderNew from '../../../WJ_components/WJ_Layout/WJ_HeaderNew';
import View from '../../../WJ_assets/images/view.png';
import Download from '../../../WJ_assets/images/download.png';
import UploadDoc from '../../../WJ_assets/images/UploadDoc.png';
import Dropdown from '../../../WJ_assets/images/Dropdown.png';
import invoice from '../../../WJ_assets/images/invoice.png';
import DownloadBlue from '../../../WJ_assets/images/DownloadBlue.png';
import { colors } from '../../../WJ_assets/css/color';
import WJ_Header from '../../../WJ_components/WJ_Layout/WJ_UserPagesHeader';

const drawerWidth = 240;
const Root = styled('div')({
  display: 'flex',
});

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const SideBar = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  overflow: 'auto',
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}));

/// /// styles ////////
const useStyles = makeStyles(() => ({
  customTypography: {
    color: '#525252',
    fontSize: '12px',
  },
  customTypographyBoldText: {
    fontWeight: 'bold',
    // backgroundColor: 'red',
  },
  customDisplayFlexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  customDisplayFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  customBorderRadius: {
    borderRadius: '6px',
  },
  customStyleForStatus: {
    cursor: 'pointer',
    padding: '5px',
  },
}));
/// /// styles end////////

function WJ_DocumentNew() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const columns = [
    {
      title: 'Document name',
      showSorterTooltip: false,
      render: (record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span data-label="Country" className="documwntRowData fontstyle">
            {record.DocumentName}
          </span>
          <span data-label="Country" className="fontstyle">
            Sample Document
          </span>
        </div>
      ),
    },
    {
      title: 'Received at',
      showSorterTooltip: false,
      render: (record) => (
        <span className="documwntRowData fontstyle" data-label="City">
          {record.Receivedat}
        </span>
      ),
    },
    {
      title: 'Status',
      render: (record) => (
        <span
          className={clsx(
            classes.customBorderRadius,
            classes.customStyleForStatus,
            'fontstyle'
          )}
          data-label="Email"
          style={{
            backgroundColor: '#FFE399',
            color: '#1C1D1E',
          }}
        >
          {record.Status}
        </span>
      ),
    },
    {
      title: 'View',
      render: () => (
        <img
          src={View}
          alt=""
          style={{
            width: '30px',
            height: '30px',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/documentdetails');
          }}
        />
      ),
    },
    {
      title: 'Download',
      render: () => (
        <img
          src={Download}
          alt=""
          style={{
            width: '30px',
            height: '30px',
            cursor: 'pointer',
          }}
        />
      ),
    },
  ];

  const dataSource = [
    {
      key: '1',
      DocumentName: 'Annual TAX Invoice',
      Receivedat: '9:30 PM, 22/09/2022',
      Status: 'In Progress',
      View: '',
      Download: '',
    },
    {
      key: '2',
      DocumentName: 'Annual TAX Invoice',
      Receivedat: '9:30 PM, 22/09/2022',
      Status: 'Extracted',
      View: '',
      Download: '',
    },
    {
      key: '3',
      DocumentName: 'Annual TAX Invoice',
      Receivedat: 32,
      Status: 'Complete',
      View: '',
      Download: '',
    },
    {
      key: '4',
      DocumentName: 'Mike',
      Receivedat: '9:30 PM, 22/09/2022',
      Status: 'Failed',
      View: '',
      Download: '',
    },
    {
      key: '5',
      DocumentName: 'Mike',
      Receivedat: 32,
      Status: 'In Progress',
      View: '',
      Download: '',
    },
  ];
  return (
    <Box
      sx={{ backgroundColor: colors.appBackgroundLightBlue, height: '100vh' }}
    >
      <WJ_Header />
      <Root
        sx={{
          padding: '0px 30px',
        }}
      >
        <SideBar
          className="sidebar"
          sx={{
            width: {
              lg: open ? '500px' : '0',
              md: open ? '350px' : '0',
              sm: open ? '280px' : '0',
            },
            display: { xs: 'block', sm: 'block' },
            height: '500px',
            overflowY: 'auto',
          }}
          variant="persistent"
          open={open}
        >
          <Box
            className={classes.customDisplayFlexColumn}
            sx={{
              padding: '20px 40px',
              gap: '30px',
              justifyContent: 'center',
            }}
          >
            <Typography
              className={classes.customTypographyBoldTex}
              sx={{ fontWeight: 700 }}
            >
              Annual TAX Invoice
            </Typography>
            <Box
              className={classes.customDisplayFlexColumn}
              sx={{
                margin: '0px 50px',
                gap: '20px',
              }}
            >
              <img
                src={invoice}
                alt=""
                style={{
                  width: '100%',
                  height: '350px',
                  cursor: 'pointer',
                }}
              />
              <Box
                className={classes.customBorderRadius}
                sx={{
                  backgroundColor: '#F4F4F4',
                  padding: '10px',
                }}
              >
                <Grid container>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      width: '100%',
                    }}
                  >
                    <Typography className={classes.customTypography}>
                      Document Type
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Sample Document
                    </Typography>
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid>
                        <Typography className={classes.customTypography}>
                          Received at
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          22-09-2022
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography className={classes.customTypography}>
                          Modified at
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          22-09-2022
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography className={classes.customTypography}>
                          Modified by
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          22-09-2022
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box
                className={classes.customDisplayFlexRow}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                }}
              >
                <Typography
                  className={classes.customTypographyBoldText}
                  sx={{ color: colors.navyBlue, gap: '10px' }}
                >
                  Download
                </Typography>
                <img
                  src={DownloadBlue}
                  alt=""
                  style={{
                    width: '25px',
                    height: '25px',
                    cursor: 'pointer',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </SideBar>
        <Main>
          <Box>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              <Grid item sx={{ display: 'flex', gap: '10px' }}>
                <Typography
                  className={classes.customTypographyBoldText}
                  sx={{ fontWeight: 800, fontSize: '25px' }}
                >
                  Document
                </Typography>
                <img
                  src={Dropdown}
                  alt=""
                  style={{
                    width: '30px',
                    height: '30px',
                  }}
                />
              </Grid>
              <Grid item sx={{ display: 'flex', gap: '30px' }}>
                <Grid sx={{ display: 'flex', gap: '10px' }}>
                  <Typography variant="">Show Sample Document</Typography>
                  <Space direction="vertical">
                    <Switch
                      className="switchButton"
                      checkedChildren="OFF"
                      unCheckedChildren="ON"
                      defaultChecked
                      onClick={handleToggle}
                    />
                  </Space>
                </Grid>
                <Grid sx={{ display: 'flex', gap: '10px' }}>
                  <Typography
                    className={classes.customTypographyBoldText}
                    sx={{ color: colors.navyBlue }}
                  >
                    Upload Document
                  </Typography>
                  <img
                    src={UploadDoc}
                    alt=""
                    style={{
                      width: '30px',
                      height: '30px',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: 400, width: '100%' }}>
            <Table
              className="DocumentTable"
              columns={columns}
              dataSource={dataSource}
            />
            <Divider orientation="horizontal" />
            <Typography
              sx={{
                margin: '10px 45px',
              }}
            >
              Copyright © 2024 Winjit Inc | All Rights Reserved | Powered by
              Winjit Technologies
            </Typography>
          </Box>
        </Main>
      </Root>
    </Box>
  );
}

export default WJ_DocumentNew;
