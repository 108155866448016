import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Input, List, message, Tag, Form, Typography, Select, Space, Divider, Popconfirm, Checkbox, Tooltip } from 'antd';
import { MinusCircleOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { IoMdAdd } from "react-icons/io";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { HiMiniPencil, HiOutlinePencil } from "react-icons/hi2";

const DocumentTypeSelector = ({
    documentData,
    onDocumentTypeChange,
    selectedDocumentType,
    onCreateNewType,
    onDeleteType,
    onEditKeys,
    isUserAdded,

}) => {
    const [newTypeName, setNewTypeName] = useState("");
    const [newTypeKeys, setNewTypeKeys] = useState([]);
    const [newSingleKey, setNewSingleKey] = useState("");
    const [isNewTypeModalOpen, setIsNewTypeModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isTableDataSelected, setIsTableDataSelected] = useState(false);
    const [isTableDataSelectedForNew, setIsTableDataSelectedForNew] = useState(false);
    // const [newField, setNewField] = useState("");
    // const [editKeys, setEditKeys] = useState([]);
    const [form] = Form.useForm();
    const { confirm } = Modal;

    const handleAddNewSingleKey = () => {
        const keysToAdd = newSingleKey.split(',').map(key => key.trim()).filter(key => key !== '');

        if (newTypeKeys.length + keysToAdd.length > 15) {
            message.error(`Cannot add more than 15 keys. You can add ${15 - newTypeKeys.length} more.`);
            return;
        }

        keysToAdd.forEach(key => {
            if (key !== '' && !newTypeKeys.map(k => k.toLowerCase()).includes(key.toLowerCase())) {
                setNewTypeKeys(prevKeys => [...prevKeys, key]);
            } else if (key !== '') {
                message.error(`Key '${key}' already exists.`);
            }
        });


        setNewSingleKey("");
    };

    useEffect(() => {
        setIsTableDataSelected(documentData?.useraddedkeys?.[selectedDocumentType]?.isTableDataSelected ?? false);
        // console.log(documentData.useraddedkeys[selectedDocumentType].isTableDataSelected)
    }, [documentData, selectedDocumentType]);

    const handleCreateNewType = () => {
        if (newTypeName.trim() && newTypeKeys.length > 0 && newTypeKeys.length < 16) {
            onCreateNewType(newTypeName, newTypeKeys, isTableDataSelectedForNew);

            setNewTypeName("");
            setNewTypeKeys([]);
            setIsNewTypeModalOpen(false);
        } else {
            message.error('Type name and keys cannot be empty');
        }
    };

    const handleEditField = (values) => {
        let uniqueElements = new Set(values.keys);
        if (uniqueElements.size !== values.keys.length) {
            message.error("Duplicate fields not allowed!!");
        } else {
            onEditKeys(selectedDocumentType, values.keys, isTableDataSelected);
            setIsEditMode(false);
        }
    };

    const handleEditClick = (e) => {
        e.stopPropagation();
        setIsEditMode(!isEditMode); // Toggle edit mode
        if (!isEditMode) {
            form.setFieldsValue({ keys: documentData.useraddedkeys[selectedDocumentType]?.keys });
        }
    };

    const checkboxClass = isTableDataSelected ? 'text-green-600' : 'text-red-600';

    const handleTableSelectorChange = (e) => {
        console.log('checked = ', e.target.checked);
        setIsTableDataSelected(e.target.checked);
    }

    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure delete this Document Type?',
            icon: <ExclamationCircleFilled />,
            // content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                onDeleteType(selectedDocumentType);
                onDocumentTypeChange('');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <div className="p-4 bg-white rounded-md shadow-lg">
            <div className="flex justify-between items-center mb-4">
                <Typography.Title level={4}>Document Type</Typography.Title>
                <Button type="primary" onClick={() => setIsNewTypeModalOpen(true)}>
                    <IoMdAdd className="text-white text-xl" /> New Type
                </Button>
                <Modal
                    title="New Document Type"
                    visible={isNewTypeModalOpen}
                    onCancel={() => {
                        setIsNewTypeModalOpen(false); setNewTypeName("");
                        setNewTypeKeys([]);
                        setIsTableDataSelectedForNew(false);
                    }}
                    footer={[
                        <Button key="back" onClick={() => {
                            setIsNewTypeModalOpen(false); setNewTypeName("");
                            setNewTypeKeys([]);
                        }}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" disabled={newTypeKeys.length < 1 || newTypeName.length < 1} onClick={handleCreateNewType}>
                            Save
                        </Button>,
                    ]}
                >
                    <Input
                        placeholder="*Enter new type name"
                        required={true}
                        value={newTypeName}
                        onChange={(e) => setNewTypeName(e.target.value)}
                        className="mb-4"
                    />
                    <Input
                        placeholder="*Add multiple Keys using , "
                        required={true}
                        maxLength={200}
                        classNames="max-w-full"
                        value={newSingleKey}
                        disabled={newTypeKeys.length >= 15}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter' && newTypeKeys.length < 15) {
                                e.preventDefault();

                                handleAddNewSingleKey();
                            }
                        }}
                        onChange={(e) => setNewSingleKey(e.target.value)}
                        className="mb-4"
                    />
                    <div className="w-full flex items-center justify-between p-2">
                        <Button onClick={handleAddNewSingleKey} disabled={newTypeKeys.length >= 15}>
                            Add Key
                        </Button>

                        <Checkbox
                            style={{ marginLeft: 8 }}
                            className={`ml-2 ${isTableDataSelectedForNew ? 'text-green-600' : 'text-red-600'}   transition-colors duration-500 ease-in-out`}
                            checked={isTableDataSelectedForNew}
                            onChange={() => { setIsTableDataSelectedForNew(!isTableDataSelectedForNew) }}
                        >
                            {'Tabular Data'}
                        </Checkbox>

                    </div>

                    <List
                        dataSource={newTypeKeys}
                        renderItem={(item, index) => (
                            <List.Item
                                actions={[
                                    // <Button
                                    //     type="text"
                                    //     icon={<DeleteOutlined className="text-red-600" />}
                                    //     onClick={() => setNewTypeKeys(newTypeKeys.filter((_, i) => i !== index))}
                                    // />

                                    <Popconfirm
                                        title="Are you sure to delete this Key?"
                                        danger
                                        onConfirm={() => setNewTypeKeys(newTypeKeys.filter((_, i) => i !== index))}
                                    // style={{ marginLeft: 8 }}
                                    >
                                        <MdOutlineDeleteOutline className="text-red-600 text-2xl" />
                                    </Popconfirm>
                                ]}
                            >
                                {item}
                            </List.Item>
                        )}
                    />
                </Modal>
            </div>

            <div className="mb-4">
                <Typography.Title level={5}>Select Document Type</Typography.Title>
                <Select
                    placeholder="Please select a document type"
                    mode="single"
                    value={selectedDocumentType.length > 1 ? selectedDocumentType : null}
                    onChange={(value) => {
                        onDocumentTypeChange(value);
                        setIsEditMode(false);
                    }}
                    showSearch
                    className="w-full"
                    size="large"

                >
                    {Object.keys(documentData.keys || {}).map((type) => (
                        <Select.Option key={type} value={type}>
                            {type}
                        </Select.Option>
                    ))}
                    {Object.keys(documentData.useraddedkeys || {}).map((type) => (
                        <Select.Option key={type} value={type}>
                            {type}

                        </Select.Option>
                    ))}
                </Select>
            </div>

            {isUserAdded &&
                <div className="flex ml-1">
                    <Popconfirm
                        title="Are you sure to delete this Type?"
                        danger
                        onConfirm={() => {
                            onDeleteType(selectedDocumentType);
                            onDocumentTypeChange('');
                        }}
                        style={{ marginLeft: 8 }}
                    >
                        <MdOutlineDeleteOutline className="text-red-600 text-2xl" />
                    </Popconfirm>
                    <span
                        className="flex items-center justify-center text-xl text-yellow-600 hover:cursor-pointer"
                        onClick={handleEditClick}
                        style={{ marginLeft: 8 }}
                    >
                        {isEditMode ? <HiMiniPencil /> : <HiOutlinePencil />}
                    </span>

                    <span className="w-full text-right transition-all duration-500">
                        <Tooltip title={!isEditMode ? "Enable edit mode to change" : ""}>
                            <Checkbox
                                style={{ marginLeft: 8 }}
                                disabled={!isEditMode}
                                className={`ml-2 ${checkboxClass} transition-colors duration-500 ease-in-out`}
                                checked={isTableDataSelected}
                                onChange={handleTableSelectorChange}
                            >
                                {"Tabular Data"}
                            </Checkbox>
                        </Tooltip>
                    </span>
                </div>
            }

            {selectedDocumentType && !isEditMode && (
                <>
                    <Divider orientation="center">Fields for {selectedDocumentType}</Divider>
                    <List
                        className=" max-h-[45vh] overflow-y-auto"
                        // header={<div>Fields</div>}
                        bordered

                        dataSource={documentData?.keys?.[selectedDocumentType]?.keys || documentData?.useraddedkeys?.[selectedDocumentType]?.keys || []}
                        renderItem={item => (
                            <List.Item>
                                <Typography.Text>{item}</Typography.Text>
                            </List.Item>
                        )}
                    />
                </>
            )}

            {selectedDocumentType && isEditMode && (
                <>
                    <Form form={form} name="dynamic_form_item" className="w-full" onFinish={handleEditField}>

                        <Form.List name="keys">
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    <Divider orientation="center">Fields for {selectedDocumentType}</Divider>
                                    <div className="max-h-[50vh] overflow-y-auto">
                                        {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                            <div key={key} className="flex items-center mb-2">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name]}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input field name or delete this field.",
                                                        },
                                                    ]}
                                                    className="flex-grow"
                                                    noStyle
                                                >
                                                    <Input placeholder="Field" maxLength={200} className="w-full" />
                                                </Form.Item>
                                                {fields.length > 1 && (
                                                    <MinusCircleOutlined
                                                        className="text-red-600 text-xl ml-2 hover:cursor-pointer"
                                                        onClick={() => remove(name)}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {fields.length < 15 && (
                                        <Form.Item className="mt-4">
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                icon={<PlusOutlined />}
                                                className="w-full text-blue-600"

                                            >
                                                Add field
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    )}
                                </>
                            )}
                        </Form.List>

                        <Form.Item {...{
                            labelCol: { span: 4 },
                            wrapperCol: { span: 20 }
                        }}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                                <Button onClick={() => setIsEditMode(false)}>
                                    Cancel
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </>
            )
            }
        </div >
    );
};

DocumentTypeSelector.propTypes = {
    documentData: PropTypes.object.isRequired,
    selectedDocumentType: PropTypes.string,
    onDocumentTypeChange: PropTypes.func.isRequired,
    onDeleteType: PropTypes.func.isRequired,
    onCreateNewType: PropTypes.func.isRequired,
    isUserAdded: PropTypes.bool,
    onEditKeys: PropTypes.func.isRequired
};

export default DocumentTypeSelector;
