/* eslint-disable camelcase */
import { AppBar, Box, Grid, Typography } from '@mui/material';
import React from 'react';
import raphael_cloud2 from '../../WJ_assets/images/raphael_cloud2.png';
import DropdownWhite from '../../WJ_assets/images/DropdownWhite.png';

function WJ_HeaderNew() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AppBar
        position="static"
        sx={{ backgroundColor: '#034EA2' }}
        // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '12px 50px',
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <img
              src={raphael_cloud2}
              alt=""
              style={{
                width: '40px',
                height: '40px',
              }}
            />
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, lineHeight: '1.5px' }}
            >
              Docusense
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', gap: '10px' }}>
            <Typography variant="">Rid Richard</Typography>
            <img
              src={DropdownWhite}
              alt=""
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          </Grid>
        </Grid>
      </AppBar>
    </Box>
  );
}

export default WJ_HeaderNew;
