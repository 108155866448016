/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */

import React, { useEffect, useState } from 'react';

const WJ_useLogout = (startTime) => {
  const [timer, setTimer] = useState(startTime);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
    const resetTimeout = () => {
      setTimer(startTime);
    };
    const events = ['load', 'mousedown', 'click', 'scroll', 'keypress'];
    for (let i = 0; i < events.length; ) {
      window.addEventListener(events[i], resetTimeout);
      i += 1;
    }
    return () => {
      clearInterval(myInterval);
      for (let i = 0; i < events.length; ) {
        window.removeEventListener(events[i], resetTimeout);
        i += 1;
      }
    };
  });
  return timer;
};

export default WJ_useLogout;
