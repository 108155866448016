/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingOutlined } from '@ant-design/icons';
import { Space, Spin } from "antd";
// function WJ_Loader(props) {
//   return (
//     <Backdrop
//       sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//       open={props.isLoading}
//     >
//       <CircularProgress />
//     </Backdrop>
//   );
// }

function WJ_Loader(props) {
  const spinIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />; // Larger icon size

  return (
    props.isLoading && (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 9999,
      }}>
        <Space size="large">
          <Spin indicator={spinIcon} size="large" />
        </Space>
      </div>
    )
  );
}
export default WJ_Loader;

