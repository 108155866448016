import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import download from './../../WJ_assets/images/download.png'
import { PiFileCsv } from "react-icons/pi";

const TableComponent = ({ tableData, id }) => {
  // Check if the table data exists and has tables
  const tableExists = tableData?.find((table) => table.id === id);

  const rows = tableExists?.rows;

  const downloadCSV = () => {
    const escapeForCSV = (text) => {
      if (typeof text === 'string') {
        // Escape double quotes by doubling them
        text = text.replace(/"/g, '""');
        // Enclose text in double quotes if it contains a comma or double quotes
        if (text.includes(',') || text.includes('"')) {
          text = `"${text}"`;
        }
      }
      return text;
    };

    let csvContent = '';

    // Add header row
    csvContent += rows[0].map(cell => escapeForCSV(cell.text)).join(',') + '\n';

    // Add data rows
    for (let i = 1; i < rows.length; i++) {
      csvContent += rows[i].map(cell => escapeForCSV(cell.text)).join(',') + '\n';
    }

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'tableData.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {tableExists && (
        <>
          <div className="flex justify-end item-start mb-2">
            <div className='text-right'>
            <button onClick={downloadCSV} className="text-gray-500 hover:text-gray-700">
              <PiFileCsv size={28} />
            </button>
            </div>
           
          </div>
          <div key={id} className="bg-white border border-gray-300 w-full p-0 rounded-lg shadow-lg overflow-scroll" id={`table-${id}-tr`}>
            <Table className="min-w-full">
              <TableBody>
                {rows.map((row, rowIndex) => (
                  <TableRow key={rowIndex} className="even:bg-gray-100">
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cell.id} id={cell.id} className="border p-2 text-sm">
                        {cell.text}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default TableComponent;