/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable */
import axios from "axios";
import api from "../WJ_networkService/WJ_api";
import * as WJ_constants from "../../WJ_utils/WJ_Constants";
// export const WJ_Gemeni_url = 'https://20.197.24.53:6010'
export const WJ_Gemeni_url = 'https://staging.winjit.info:6010'
// export const WJ_Gemeni_url = 'http://localhost:6010'


// Call get upload signed url api

export async function WJ_getUploadPresignedUrl(input) {

  try {
    const url = `${WJ_constants.WJ_getUploadPresignedApiUrl}?document_type=${
      input.documentType
    }&file_name=${encodeURIComponent(input.fileName)}&file_type=${
      input.fileType
    }&extraction_keys=${input.extractionKeys}&isTableDataSelected=${input.isTableDataSelected}`;
    const response = await api.get(url);
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call upload document api
export async function WJ_uploadDocument(input) {
  try {
    const response = await api.post(input.url, input.data, {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    });
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call get user documents api
export async function WJ_getUserDocuments() {
  try {
    const response = await api.get(WJ_constants.WJ_getUserDocumentsApiUrl);
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call get download signed url api
export async function WJ_getDownloadPresignedUrl(input) {

  try {
    const url = `${WJ_constants.WJ_getDownloadPresignedApiUrl}?document_id=${
      input.documentId
    }&document_type=${input.documentType}&name=${encodeURIComponent(
      input.fileName
    )}`;
    const response = await api.get(url);
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call get user profile details api
export async function WJ_getUserDetails() {
  try {
    const response = await api.get(WJ_constants.WJ_getUserDetailsApiUrl);
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call update user profile details api
export async function WJ_updateUserDetails(input) {
  try {
    const response = await api.post(
      WJ_constants.WJ_updateUserDetailsApiUrl,
      input.data
    );
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call get configurations api
export async function WJ_getConfigurations() {
  try {
    const response = await api.get(WJ_constants.WJ_getConfigurationsApiUrl);
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}

// Call get sample documents api
export async function WJ_getSampleDocuments() {
  try {
    const response = await api.get(WJ_constants.WJ_getSampleDocumentsApiUrl);
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call get extraction data api
export async function WJ_getExtractionData(input) {
  try {
    const url = `${WJ_constants.WJ_getExtractionDataApiUrl}?document_id=${input.documentId}&document_type=${input.documentType}&file_name=${input.name}`;
    const response = await api.get(url);
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
export async function WJ_getGeminiData(input) {
  const idtoken = input.id_Token

  try {
    const url = `${WJ_Gemeni_url}/documents/iosa?document_id=${input.documentId}&document_type=${input.documentType}&file_name=${input.name}`;
    const headers = {
      'Authorization': `Bearer ${idtoken}` // Use Bearer token format
    };
    const response = await axios.get(url, {headers});
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call images, extraction data and extracted data urls
export async function WJ_getImages(url) {
  try {
    const response = await api.get(url);
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call get Field-List api
export async function WJ_getFieldListApi() {
  try {
    const response = await api.get(
      `${WJ_constants.WJ_getFieldListApiUrl}/${'keys'}`
    );
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call save extraction data api
export async function WJ_saveExtractionData(input) {
  try {
    const response = await api.post(
      WJ_constants.WJ_saveExtractionDataApiUrl,
      input.data
    );
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call export document api
export async function WJ_exportDocument(input) {
  try {
    const url = `${WJ_constants.WJ_exportApiUrl}?document_id=${input.documentId}&download_as=${input.type}&document_type=${input.documentType}`;
    const response = await api.get(url);
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
// Call contact us api
export async function WJ_contactUs(input) {
  try {
    const response = await api.post(
      WJ_constants.WJ_contactUsApiUrl,
      input.data
    );
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}

// Call get access tokens from code
export async function WJ_getTokens(authCode) {
  try {
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("client_id", process.env.REACT_APP_CLIENT_ID);
    params.append("code", authCode);
    // eslint-disable-next-line prettier/prettier
    params.append("redirect_uri", process.env.REACT_APP_COGNITO_REDIRECT_URL);
    const response = await api.post(
      `${process.env.REACT_APP_COGNITO_HOST}/${WJ_constants.WJ_OauthApiUrl}`,
      params,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Basic MnNubjgwcDR0MjJ0bnBoMmRvZmJkcmhuamo6MWI3ajRjZmZtNGFtN2E2cjRicmYxMzk1ODUwMjVmaWR1ZHNkNTVtNHV0Y3NqbjdjaTZjbQ==",
        },
      }
    );
    return response;
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
}
