/* eslint-disable prettier/prettier */
export const initialState = {
    fileTypeVal: '',
    documentTypeValue: '',
    file: {},
    fileName: '',

    fileTypeError: null,
    documentTypeError: null,
    fileError: null,

    fileTypes: '',
    documentTypeOptions: '',
    maxFileSize: '',
    fileSizeString: '',

    userAddedKeys: [],
    keys: [],
    keyError: '',
    keysData: [],
};

export const actions = {
    SET_FILETYPEVAL: 'SET_FILETYPEVAL',
    SET_DOCUMENTTYPEVALUE: 'SET_DOCUMENTTYPEVALUE',
    SET_FILE: 'SET_FILE',
    SET_FILENAME: 'SET_FILENAME',

    SET_FILETYPERROR: 'SET_FILETYPERROR',
    SET_DOCUMENTTYPEERROR: 'SET_DOCUMENTTYPEERROR',
    SET_FILEERROR: 'SET_FILEERROR',

    SET_FILETYPES: 'SET_FILETYPES',
    SET_DOCUMENTTYPEOPTIONS: 'SET_DOCUMENTTYPEOPTIONS',
    SET_MAXFILESIZE: 'SET_MAXFILESIZE',
    SET_FILESIZESTRING: 'SET_FILESIZESTRING',

    SET_USERADDEDKEYS: 'SET_USERADDEDKEYS',
    SET_KEYS: 'SET_KEYS',
    SET_KEYERROR: 'SET_KEYERROR',
    SET_KEYSDATA: 'SET_KEYSDATA'
};