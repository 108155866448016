import axios from "axios";
import { WJ_Gemeni_url, WJ_getUploadPresignedUrl, WJ_uploadDocument } from "../../../WJ_services/WJ_apiService/WJ_apis";
import { getFriendlyFileType } from "../../../WJ_utils/WJ_Utils";
import WJ_AuthContext from "../../../WJ_utils/WJ_Auth";


const formatDate = (date) => {
  if (!date || date.toString().toLowerCase() === "invalid date") return "-";
  return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
};

const getPresignedUrl = async ({ documentType, extractionKeys, isTableDataSelected, fileName, fileType, idToken }) => {

  try {
    const response = await WJ_getUploadPresignedUrl({
      documentType,
      extractionKeys,
      isTableDataSelected,
      fileName,
      fileType,
      id_Token: idToken,
    });

    if (response.data && response.data.status) {
      return response.data;
    } else if (response.status === 401) {
      const retryResponse = await WJ_getUploadPresignedUrl({
        documentType,
        extractionKeys,
        fileName,
        fileType,
        id_Token: idToken,
      });
      return retryResponse.data;
    } else {
      throw new Error(response.data?.message ?? "Failed to get presigned URL");
    }
  } catch (error) {
    console.error("Error getting presigned URL:", error);
    throw error;
  }
};

const uploadDocumentToPresignedUrl = async (data, file) => {
  const formData = new FormData();
  console.log(data)
  Object.entries(data?.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("file", file);

  try {
    const response = await WJ_uploadDocument({
      url: data.url,
      data: formData,
    });

    if (response.status !== 204) {
      throw new Error("Failed to upload document");
    }
  } catch (error) {
    console.error("Error uploading document:", error);
    throw error;
  }
};

const callGeminiAPI = async (file, extractionKeys, documentType, idToken, keyString) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("extraction_keys", extractionKeys);
  formData.append("document_type", documentType);
  formData.append("user", idToken);
  formData.append("key", keyString);

  try {
    await axios.post(`${WJ_Gemeni_url}/process_invoice`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.error("Error calling Gemini API:", error);
    // throw error; // Commented out because we already log the error
  }
};

export const handleDocumentUpload = async (uploadedFiles, selectedDocumentType, extractionKeys,isTableDataSelected, idToken) => {
  const file = uploadedFiles[0];

  const presignedUrlData = await getPresignedUrl({
    documentType: selectedDocumentType,
    extractionKeys,
    isTableDataSelected: isTableDataSelected,
    fileName: file.name,
    fileType: getFriendlyFileType(file.type),
    idToken,
  });

  if (presignedUrlData.status === 200) {
    console.log(presignedUrlData)
    // await callGeminiAPI(file, extractionKeys, selectedDocumentType, idToken, presignedUrlData?.data?.fields?.key);
    await uploadDocumentToPresignedUrl(presignedUrlData.data, file);

    return {
      name: file.name,
      documentType: getFriendlyFileType(file.type),
      createdDate: formatDate(new Date()),
    };
  } else if (presignedUrlData.status === 201) {
    throw new Error("User inactivation required");
  } else {
    throw new Error("Failed to upload document");
  }
};

export const inegrityCheck = (data) => {
  console.log(data);
  for (let category in data) {
    for (let type in data[category]) {
      let values = data[category][type];
      if (values.length > 16) {
        data[category][type] = values.slice(0, 15);
      } 
    }
  }
  console.log(data);
  return data;
};
