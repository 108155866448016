/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable */
import { Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import WJ_HomePage from '../WJ_pages/WJ_HomePage/WJ_HomePage';
import WJ_Dashboard from '../WJ_pages/WJ_Dashboard/WJ_Dashboard';
import WJ_Document from '../WJ_pages/WJ_Document/WJ_Document';
import WJ_UserProfile from '../WJ_pages/WJ_UserProfile/WJ_UserProfile';
import WJ_AuthContext from '../WJ_utils/WJ_Auth';
import WJ_DocumentNew from '../WJ_pages/WJ_Dashboard/WJ_UserDocuments/WJ_DocumentNew';
import WJ_DocumentDetails from '../WJ_pages/WJ_Document/WJ_DocumentDetails';
import WJ_UploadDocument from '../WJ_pages/WJ_Dashboard/WJ_UploadDocument/WJ_UploadDocument';
import  LLMResponse  from '../WJ_pages/WJ_Document/WJ_ChatWithFile';
import UploadDocument2 from '../WJ_pages/WJ_Dashboard/WJ_UploadDocument/page';

function WJ_Navigation() {
  const authCtx = useContext(WJ_AuthContext);
  const { idToken } = authCtx;
  return (
    <Routes>
      <Route
        path="/"
        element={
          !idToken ? <WJ_HomePage /> : <Navigate replace to="/dashboard" />
        }
        exact
      />
      <Route
        path="/dashboard"
        element={idToken ? <WJ_Dashboard /> : <WJ_HomePage />}
        exact
      />
      <Route
        path="/document/:id/:type/:name"
        element={idToken ? <WJ_Document /> : <Navigate replace to="/" />}
        exact
      />
      <Route
        path="/LlmResponse/:id/:type/:name"
        element={idToken ? <LLMResponse /> : <Navigate replace to="/" />}
        exact
      />
      {/* <Route
        path="/document/:id/:type/:name"
        element={idToken ? <WJ_DocumentNew /> : <Navigate replace to="/" />}
        exact
      /> */}
      <Route
        path="/profile"
        element={idToken ? <WJ_UserProfile /> : <Navigate replace to="/" />}
        exact
      />
      <Route path="/documentnew" element={<WJ_DocumentNew />} exact />
      <Route path="/documentdetails" element={<WJ_DocumentDetails />} exact />
      {/* <Route path="/uploaddocument" element={<WJ_UploadDocument />} exact /> */}
      <Route path="/uploaddocument" element={<UploadDocument2 />} exact />
      <Route path="*" element={<Navigate replace to="/" />} exact />
    </Routes>
  );
}

export default WJ_Navigation;
