/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */

import { createTheme } from '@mui/material/styles';

const WJ_theme = createTheme({
  palette: {
    primary: {
      main: '#ED1C24',
      light: '#FF6F4D',
      lighter: '#F9AC6699',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#000000',
      light: '#333333',
      contrastText: '#FFFFFF',
    },
    white: {
      main: '#ffffff',
      dark: '#F0EFEC',
    },
  },
});

export default WJ_theme;
