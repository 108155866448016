/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import Axios from 'axios';
import { WJ_OauthApiUrl } from '../../WJ_utils/WJ_Constants';

function WJ_createAxios() {
  const axios = Axios.create();
  axios.defaults.baseURL = `${process.env.REACT_APP_API_BASEURL}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.timeout = 120000; // 2*60*1000 = 120000 = 2 minutes

  async function WJ_getTokenFromRefresh() {
    try {
      const params = new URLSearchParams();
      params.append('grant_type', 'refresh_token');
      params.append('client_id', process.env.REACT_APP_CLIENT_ID);
      params.append('refresh_token', sessionStorage.getItem('refresh_token'));
      const response = await axios.post(
        `${process.env.REACT_APP_COGNITO_HOST}/${WJ_OauthApiUrl}`,
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      sessionStorage.setItem('id_token', response.data.id_token);
      console.log('item***************', response.data.id_token);
      return response.data.id_token;
    } catch (error) {
      return {
        status: 'error',
        message: error.message,
      };
    }
  }

  axios.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('id_token');
    if (
      config?.url &&
      config.url.indexOf('http://') === -1 &&
      config.url.indexOf('https://') === -1
    ) {
      config.headers.Authorization = token;
      config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    }
    return config;
  });
  axios.interceptors.response.use(undefined, async (err) => {
    const prevRequest = err?.config;
    if (err.response.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const idToken = await WJ_getTokenFromRefresh();
      prevRequest.headers.Authorization = idToken;
      return axios(prevRequest);
    }
    return Promise.reject(err);
  });

  return axios;
}

// Initialize Axios
const api = WJ_createAxios();

export default {
  get: api.get,
  post: api.post,
  put: api.put,
  delete: api.delete,
};
