/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable  */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { WJ_AuthContextProvider } from "./WJ_utils/WJ_Auth";
import { DataContextProvider } from "./WJ_utils/WJ_docList";
import { UploadDocProvider } from "./state/uploadDocumentState/UploadDocProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WJ_AuthContextProvider>
    <UploadDocProvider>
      <DataContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </DataContextProvider>
    </UploadDocProvider>
  </WJ_AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
