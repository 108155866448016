/* eslint-disable camelcase */
import { Box, Stack, Typography, Grid } from '@mui/material';
import featureImg from '../../../WJ_assets/images/features-image.png';
import DataCaptureicon from '../../../WJ_assets/images/Data-Capture-icon.png';
import PostProcessingicon from '../../../WJ_assets/images/Post-Processing-icon.png';
import PreProcessingicon from '../../../WJ_assets/images/Pre-Processing-icon.png';
import ReportingInsightsicon from '../../../WJ_assets/images/Reporting-Insights-icon.png';
import Validationsicon from '../../../WJ_assets/images/Validations-icon.png';

function WJ_Section1() {
  return (
    <Box
      sx={{
        margin: '4% 10%',
      }}
    >
      <Grid container>
        <Grid item md={12}>
          <Typography className="content1-box-heading-text">
            Docusense brings your entire document processing operation together
            on a single cloud solution. From intake to integrations with your
            existing systems, Docusense was built from the start for the cloud
            as an extensible, open platform
          </Typography>
        </Grid>
        <Grid item md={5}>
          <img
            src={featureImg}
            alt=""
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Grid>
        <Grid className="parenttext" item md={7} sx={{ paddingLeft: '2%' }}>
          <Stack direction="row" gap="0.7rem">
            <img src={PreProcessingicon} alt="" />
            <Box>
              <Typography
                className="content1-box-right-box-text"
                sx={{
                  color: '#000000',
                }}
              >
                Pre-Processing
              </Typography>
              <Typography
                className="content1-box-right-text3"
                sx={{
                  color: '#65646A',
                }}
              >
                Automatically reduplicate, reroute document to the Queue
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" gap="0.7rem">
            <img src={DataCaptureicon} alt="" />
            <Box>
              <Typography
                className="content1-box-right-box-text"
                sx={{
                  color: '#000000',
                }}
              >
                Data Capture
              </Typography>
              <Typography
                className="content1-box-right-text3"
                sx={{
                  color: '#65646A',
                }}
              >
                Accurately read document contents with the confidence even when
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" gap="0.7rem">
            <img src={Validationsicon} alt="" />
            <Box>
              <Typography
                className="content1-box-right-box-text"
                sx={{
                  color: '#000000',
                }}
              >
                Validations
              </Typography>
              <Typography
                className="content1-box-right-text3"
                sx={{
                  color: '#65646A',
                }}
              >
                Robust UI and Validation engine to highlight the fields
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" gap="0.7rem">
            <img src={PostProcessingicon} alt="" />
            <Box>
              <Typography
                className="content1-box-right-box-text"
                sx={{
                  color: '#000000',
                }}
              >
                Post-Processing
              </Typography>
              <Typography
                className="content1-box-right-text3"
                sx={{
                  color: '#65646A',
                }}
              >
                Saves time by automapping of the document field with the
                extracted date, transformation and calculations.
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" gap="0.7rem">
            <img src={ReportingInsightsicon} alt="" />
            <Box>
              <Typography
                className="content1-box-right-box-text"
                sx={{
                  color: '#000000',
                }}
              >
                Reporting Insights
              </Typography>
              <Typography
                className="content1-box-right-text3"
                sx={{
                  color: '#65646A',
                }}
              >
                Provide the accuracy % and the confidence at the field level
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WJ_Section1;
