import { useContext, useEffect, useRef, useState } from "react";
import { Layout, Menu, Button, message } from 'antd';
import { FaFileMedical, FaUpload } from "react-icons/fa";
import { GrTask } from "react-icons/gr";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import WJ_Header from "../../../WJ_components/WJ_Layout/WJ_UserPagesHeader";
import DocumentTypeSelector from "./DocumentTypesSection";
import FileUploader from "./FileUploader";
import { WJ_getFieldListApi } from "../../../WJ_services/WJ_apiService/WJ_apis";
import WJ_AuthContext from "../../../WJ_utils/WJ_Auth";
import api from '../../../WJ_services/WJ_networkService/WJ_api';
import { handleDocumentUpload, inegrityCheck } from "./apiCalling";
import { WJ_generateRandomString } from "../../../WJ_utils/WJ_Utils";
import backarrow from "../../../WJ_assets/images/backarrow.png";

const { Sider, Content } = Layout;

const UploadDocument2 = () => {
    const [currentView, setCurrentView] = useState('uploadDocument');
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [documentData, setDocumentData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [previews, setPreviews] = useState([]);
    const [error, setError] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [contentMargin, setContentMargin] = useState(350);
    const authCtx = useContext(WJ_AuthContext);
    console.log(selectedDocumentType)
    const navigate = useNavigate();
    const sidebarRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await WJ_getFieldListApi();
                const data = res?.data?.data || {};
                const correctedData = inegrityCheck(data);
                setDocumentData(correctedData);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(true);
                navigate("/dashboard");
            } finally {
                authCtx.hideLoader();
                setLoading(false);

            }
        };
        fetchData();
    }, []);

    //Code for tabluar Data selection on document type change

    useEffect(() => {
        setContentMargin(collapsed ? 80 : 350);
    }, [collapsed]);

    const handleFilesChange = (files, previews) => {
        setUploadedFiles(files);
        setPreviews(previews);
    };

    const allDocumentTypes = {
        ...documentData?.keys,
        ...documentData?.useraddedkeys
    };

    const handleDocumentTypeChange = (type) => {
        setSelectedDocumentType(type);
        
    };

    // const handleDeleteField = async (key) => {
    //     const type = selectedDocumentType;
    //     try {
    //         const payload = { document_type: type, key };
    //         const response = await api.post("doc-type/delete-key", payload);

    //         if (response.status === 200) {
    //             const updatedKeys = documentData.useraddedkeys[type].filter((_, i) => i !== index);
    //             setSelectedDocumentType('')
    //             setDocumentData((prevData) => ({
    //                 ...prevData,
    //                 useraddedkeys: {
    //                     ...prevData.useraddedkeys,
    //                     [type]: updatedKeys,
    //                 },
    //             }));

    //             toast.success('Field deleted successfully');
    //         } else {
    //             toast.error('Failed to delete field');
    //         }
    //     } catch (error) {
    //         console.error('Error deleting field:', error);
    //         toast.error('Something went wrong');
    //     }
    // };

    const handleDeleteType = async (type) => {
        authCtx.showLoader();
        try {
            const payload = { document_type: type };
            const response = await api.post("doc-type/delete-doc-type", payload);

            if (response.status === 200) {
                const updatedUserAddedKeys = { ...documentData.useraddedkeys };
                delete updatedUserAddedKeys[type];
                setDocumentData((prevData) => ({
                    ...prevData,
                    useraddedkeys: updatedUserAddedKeys,
                }));
                authCtx.hideLoader();
                message.success('Document type deleted successfully');
            } else {
                authCtx.hideLoader();
                message.error('Failed to delete document type');
            }
        } catch (error) {
            authCtx.hideLoader();
            console.error('Error deleting document type:', error);
            message.error('Something went wrong');
        }
    };

    const handleCreateNewType = async (newType, keys, isTableDataSelected) => {
        authCtx.showLoader();
        if (newType.trim() && keys.length > 0) {
            const payload = { document_type: newType, keys: keys, isTableDataSelected: isTableDataSelected };
            try {
                const response = await api.post("doc-type/addDocType", payload);

                if (response.status === 200) {
                    setDocumentData((prevData) => ({
                        ...prevData,
                        useraddedkeys: {
                            ...prevData.useraddedkeys,
                            [newType]: {
                                ...prevData.useraddedkeys[newType], // Preserve existing properties
                                keys: keys, // Update the 'keys' property
                                isTableDataSelected: isTableDataSelected, // Ensure to include this if needed
                            },
                        },
                    }));
                    authCtx.hideLoader();
                    message.success('Changes saved successfully');
                }
            } catch (error) {
                authCtx.hideLoader();
                console.error('Error saving document type:', error);
                message.error('Something went wrong');
                navigate("/dashboard");
            }
        }
    };

    const handleEditKeys = async (type, editedKeys, isTableDataSelected) => {
        authCtx.showLoader()
        if (type.trim() && editedKeys.length > 0) {
            const payload = { document_type: type, keys: editedKeys, isTableDataSelected: isTableDataSelected };
            try {
                const response = await api.post("doc-type/addDocType", payload);

                if (response.status === 200) {
                    setDocumentData((prevData) => ({
                        ...prevData,
                        useraddedkeys: {
                            ...prevData.useraddedkeys,
                            [type]: {
                                ...prevData.useraddedkeys[type], // Preserve existing properties
                                keys: editedKeys, // Update the 'keys' property
                                isTableDataSelected: isTableDataSelected, // Ensure to include this if needed
                            },
                        },
                    }));
                    authCtx.hideLoader();
                    message.success('Changes saved successfully');
                }
            } catch (error) {
                authCtx.hideLoader();
                console.error('Error saving document type:', error);
                message.error('Something went wrong');
                navigate("/dashboard");
            }
        }
    };
    console.log(allDocumentTypes)
    const handleUpload = async () => {
        try {
            authCtx.showLoader();

            const uploadedDocument = await handleDocumentUpload(
                uploadedFiles,
                selectedDocumentType,
                allDocumentTypes[selectedDocumentType].keys,
                allDocumentTypes[selectedDocumentType].isTableDataSelected ? allDocumentTypes[selectedDocumentType].isTableDataSelected : false,
                authCtx.idToken
            );

            localStorage.setItem("newUploadedDocument", JSON.stringify({
                id: WJ_generateRandomString(10),
                name: uploadedDocument.name,
                document_type: uploadedDocument.documentType,
                status: "in-progress",
                page_count: 0,
                created_date: uploadedDocument.createdDate,
            }));

            toast.success("Document uploaded successfully.");
            navigate("/dashboard");
        } catch (error) {
            console.log(error)
            if (error.message === "User inactivation required") {
                authCtx.inactivateUser();
                navigate("/dashboard");

            }
            if (error.message == 'Failed to get presigned URL') {
                navigate("/dashboard");
            }
            toast.error(error.message || "Document upload failed, please try again later.");
        } finally {
            navigate("/dashboard");
            authCtx.hideLoader();
        }
    };

    console.log(sidebarRef?.current?.clientWidth)
    return (
        <>
            <WJ_Header />
            <Layout style={{ minHeight: "100vh", paddingTop: `64px` }}>
                <Sider width={350} theme="light" collapsible collapsed={collapsed} onCollapse={setCollapsed} ref={sidebarRef} style={{ position: 'fixed', left: 0, width: '25%', top: '64px', bottom: 0 }}>
                    <Menu theme="light" defaultSelectedKeys={['1']} mode="inline" className="pt-10">
                        <Menu.Item key="1" icon={<FaFileMedical />} onClick={() => setCurrentView('uploadDocument')}>
                            Upload Document
                        </Menu.Item>
                        <Menu.Item key="2" icon={<GrTask />} onClick={() => setCurrentView('documentType')}>
                            Document Type
                        </Menu.Item>
                        <Menu.Item key="3" icon={<FaUpload />} disabled={uploadedFiles.length < 1 || selectedDocumentType.length < 1} onClick={handleUpload}>
                            Upload
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout overflow-hidden" style={{ marginLeft: `${contentMargin}px`, paddingTop: "16px" }}>
                    <Content style={{ margin: "0 16px" }}>
                        <Button className="m-3 flex items-center" onClick={(e) => { e.preventDefault(); navigate(-1); }}>
                            <img src={backarrow} alt="Back" width="30" height="30" />
                            <span className="ml-2 text-lg">Go Back</span>
                        </Button>
                        <div className="mt-4 h-full">
                            {loading ? (
                                authCtx.showLoader()
                            ) : error ? (
                                <div>
                                    <p>Error fetching data. Please try again.</p>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setLoading(true);
                                            setError(false);
                                            fetchData();
                                        }}
                                    >
                                        Retry
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    {currentView === 'uploadDocument' && (
                                        <FileUploader
                                            maxFiles={1}
                                            allowedTypes={[
                                                'image/jpeg',
                                                'image/png',
                                                'image/tiff',
                                                'video/*',
                                                'application/pdf',
                                            ]}
                                            maxSize={10485760}
                                            uploadedPreviews={previews}
                                            uploadedFiles={uploadedFiles}
                                            onFilesChange={handleFilesChange}
                                        />
                                    )}
                                    {currentView === 'documentType' && (
                                        <DocumentTypeSelector
                                            documentData={documentData}
                                            selectedDocumentType={selectedDocumentType}
                                            onDocumentTypeChange={handleDocumentTypeChange}
                                            onDeleteType={handleDeleteType}
                                            onCreateNewType={handleCreateNewType}
                                            isUserAdded={documentData?.useraddedkeys?.hasOwnProperty(selectedDocumentType)}
                                            onEditKeys={handleEditKeys}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

export default UploadDocument2;
