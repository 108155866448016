import { actions } from './state';

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_FILETYPEVAL:
      return { ...state, fileTypeVal: action.payload };
    case actions.SET_DOCUMENTTYPEVALUE:
      return { ...state, documentTypeValue: action.payload };
    case actions.SET_FILE:
      return { ...state, file: action.payload };
    case actions.SET_FILENAME:
      return { ...state, fileName: action.payload };

    case actions.SET_FILETYPERROR:
      return { ...state, fileTypeError: action.payload };
    case actions.SET_DOCUMENTTYPEERROR:
      return { ...state, documentTypeError: action.payload };
    case actions.SET_FILEERROR:
      return { ...state, fileError: action.payload };

    case actions.SET_FILETYPES:
      return { ...state, fileTypes: action.payload };
    case actions.SET_DOCUMENTTYPEOPTIONS:
      return { ...state, documentTypeOptions: action.payload };
    case actions.SET_MAXFILESIZE:
      return { ...state, maxFileSize: action.payload };
    case actions.SET_FILESIZESTRING:
      return { ...state, fileSizeString: action.payload };

    case actions.SET_USERADDEDKEYS:
      return { ...state, userAddedKeys: action.payload };
    case actions.SET_KEYS:
      return { ...state, keys: action.payload };
    case actions.SET_KEYERROR:
      return { ...state, keyError: action.payload };
    case actions.SET_KEYSDATA:
      return { ...state, keysData: action.payload };

    default:
      return state;
  }
};

export default reducer;
