/* eslint-disable */
import React, { useEffect, useContext, useState, useRef } from "react";
import { useXarrow } from "react-xarrows";
import { Box, Stack } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import WJ_Header from "../../WJ_components/WJ_Layout/WJ_UserPagesHeader";
import WJ_Extraction from "./WJ_Extraction";
import WJ_Image from "./WJ_Image";
import WJ_MappingLine from "../WJ_UserProfile/WJ_MappingLine";
import DownloadBlue from "../../WJ_assets/images/DownloadBlue.png";
import {
  WJ_getImages,
  WJ_saveExtractionData,
  WJ_exportDocument,
  WJ_getGeminiData,
} from "../../WJ_services/WJ_apiService/WJ_apis";
import WJ_AuthContext from "../../WJ_utils/WJ_Auth";
import { WJ_zoomConfig } from "../../WJ_utils/WJ_Constants";
import WJ_FileDownloadedModal from "../../WJ_components/WJ_Modal/WJ_FileDownloadedModal";
import { WJ_getRandomNumber } from "../../WJ_utils/WJ_Utils";
import WJ_LeavePageConfirmationModal from "../../WJ_components/WJ_Modal/WJ_LeavePageConfirmationModal";
import DataContext from "../../WJ_utils/WJ_docList";

// Generate id for custom mapping
const WJ_generateRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    const randomNumber = WJ_getRandomNumber();
    const res = randomNumber * 2 ** -32;
    result += characters.charAt(Math.floor(res * charactersLength));
  }
  return result;
};

function WJ_Document() {
  const navigate = useNavigate();
  const frequencyConfig = WJ_zoomConfig.frequency ?? 10;
  const params = useParams();
  const authCtx = useContext(WJ_AuthContext);
  const location = useLocation();
  const { docStatus } = location.state;
  const [keyValue, setKeyValue] = useState({});
  const [mappedKeyValues, setMappedKeyValues] = useState([]);
  const [extractionData, setExtractionData] = useState([]);
  const [documentImages, setImages] = useState([]);
  const [activeTrId, setActiveTrId] = useState(null);
  const [activeParentTrId, setActiveParentTrId] = useState(null);
  const [activeDivId, setActiveDivId] = useState(null);
  const [activeLabel, setActiveLabel] = useState(null);
  const [minWidth, setMinWidth] = useState(100);
  const [maxWidth, setMaxWidth] = useState(100);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [defaultWidth, setDefaultWidth] = useState(0);
  const [defaultHeight, setDefaultHeight] = useState(0);
  const [mappedIds, setMappedIds] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [WJ_tableColumns, setTableColumns] = useState([]);
  const [newRowColumns, setNewRowColumns] = useState({});
  const [defaultValue, setDefaultValue] = useState("");
  const [customTop, setCustomTop] = useState(0);
  const [customLeft, setCustomLeft] = useState(0);
  const [customWidth, setCustomWidth] = useState(0);
  const [customHeight, setCustomHeight] = useState(0);
  const [captureMove, setCaptureMove] = useState(false);
  const [customDivNumber, setCustomDivNumber] = useState(null);
  const [firstTop, setFirstTop] = useState(0);
  const [firstLeft, setFirstLeft] = useState(0);
  const [open, setOpen] = useState(false);
  const [disableExport, setDisableExport] = useState(true);
  const [tableActivePage, setTableActivePage] = useState(0);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [oldMappingData, setOldMappingData] = useState({});
  const [openTable, toggleTableOpen] = useState(false);
  const [fields, setFields] = useState([]);
  const [fieldsError, setFieldsError] = useState({});
  const [backClicked, setBackClicked] = useState(false);
  const [changesDone, setChangesDone] = useState(false);
  const [imageDimensions, setImageDimensions] = useState([]);
  const [defaultImageDimensions, setDefaultImageDimensions] = useState([]);
  const [mappingBoxOpen, setMappingBoxOpen] = useState(true);
  const [lineItemClicked, setLineItemClicked] = useState(false);
  const WJ_updateXarrow = useXarrow();
  const toastId = useRef(null);

  // Call api to read s3 bucket files
  const WJ_getResponseApiHandler = async (url) => {
    const fileResponse = await WJ_getImages(url);
    return fileResponse;
  };
  // Fetch image and create object url of image
  const WJ_getImageDataURL = async (url) => {
    const res = await fetch(url);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
  };

  // Draw line between left side row and right side div
  const WJ_drawLeaderLine = (trId, divId) => {
    const endId = document.getElementById(`${divId}-div`);
    if (endId) {
      endId.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  // Show first key row active
  const WJ_activateFirstRow = (input) => {
    const firstObj = input[Object.keys(input)[0]] ?? [];
    if (firstObj.length > 0) {
      if (firstObj[0].key !== "") {
        setActiveTrId(firstObj[0].key ?? "");
        setActiveParentTrId(firstObj[0].parentKey ?? "");
        setActiveLabel(firstObj[0].label ?? "");
      }
      if (firstObj[0].Id !== "") {
        setActiveDivId(firstObj[0].Id);
        setDefaultValue(firstObj[0].value ?? "");
      }
      if (firstObj[0].key !== "" && firstObj[0].Id !== "") {
        setTimeout(() => {
          // WJ_drawLeaderLine(firstObj[0].key, firstObj[0].Id);
        }, 300);
      } else {
        for (let i = 0; i < Object.entries(input).length; i += 1) {
          // console.log("Array222---------", input[Object.keys(input)[i]].length);
          for (let j = 0; j < input[Object.keys(input)[i]].length; j += 1) {
            // console.log("Array333------", input[Object.keys(input)[j]]);
            const firstObj1 = input[Object.keys(input)[j]] ?? [];
            // console.log("firstObj1------", firstObj1[j]);
          }
          // const firstObj1 = input[Object.keys(input)[i]] ?? [];
          // console.log('LENGTH firstObj1------', firstObj1[i]);
          // if (firstObj1.length > 0) {
          //   // console.log('LENGTH IF------', firstObj1);
          //   if (firstObj1[i].key !== '') {
          //     setActiveTrId(firstObj1[i].key ?? '');
          //     setActiveParentTrId(firstObj1[i].parentKey ?? '');
          //     setActiveLabel(firstObj1[i].label ?? '');
          //   }
          //   if (firstObj1[i].Id !== '') {
          //     setActiveDivId(firstObj1[i].Id);
          //     setDefaultValue(firstObj1[i].value ?? '');
          //   }
          //   if (firstObj1[i].key !== '' && firstObj[i].Id !== '') {
          //     setTimeout(() => {
          //       WJ_drawLeaderLine(firstObj1[i].key, firstObj1[i].Id);
          //     }, 300);
          //   }
          // }
        }
      }
    }
  };
  // Create object for image height and width
  const WJ_getImageDimension = (response) => {
    const tempDimensions = [];
    for (let i = 0; i < response.data.image_urls.length; i += 1) {
      const imgObj1 = document.getElementById(`img-${i}`);
      const width1 = imgObj1.clientWidth ?? 0;
      const height1 = imgObj1.clientHeight ?? 0;
      tempDimensions.push({ width1, height1 });
    }
    setImageDimensions(tempDimensions);
    setDefaultImageDimensions(tempDimensions);
  };
  // Call api to get extraction data of document
  // const setExtractionDataFromApi = async (response) => {
  //   console.log("chat with resposne ======", response);
  //   if (response.data && response.data.data.textract_url) {
  //     const textractFileResponse = await WJ_getResponseApiHandler(
  //       response.data.data.textract_url
  //     );
  //     if (textractFileResponse.status === 200) {
  //       setExtractionData(textractFileResponse.data ?? []);
  //     }
  //   }
  // };
  const WJ_setImageDataFromApi = async (response) => {
    if (response.data.data && response.data.data.image_urls) {
      const imageData = [];
      for (const url of response.data.data.image_urls) {
        const urlBlob = await WJ_getImageDataURL(url);
        imageData.push({ url: urlBlob });
        setImages([...imageData]);
      }
    }
  };
  const WJ_setTimeoutForInitialLoad = (response) => {
    setTimeout(() => {
      try {
        WJ_getImageDimension(response.data);
        WJ_activateFirstRow(keyValue);
        WJ_activateFirstRow(inputFieldListData);
        const imgObj = document.getElementById("img-0");
        setDefaultWidth(imgObj.clientWidth ?? 0);
        setDefaultHeight(imgObj.clientHeight ?? 0);
        setWidth(imgObj.clientWidth ?? 0);
        setHeight(imgObj.clientHeight ?? 0);
      } catch (err) {
        console.log(err);
      }
    }, 1000);
  };

  const WJ_getFieldListObj = (field, key, i, parent) => {
    const key1 = key;
    const group = "basic info";
    const order = i + 1;
    const label = key;
    let Id = "";
    let value = "";
    let bbox = {};
    let page = 0;
    let polygon = [];
    let blockType = "";
    if (field.Id || field.value) {
      Id = field.Id && field.Id !== "" ? field.Id : WJ_generateRandomString(8); // Using the generateRandomString function
      value = field.value ?? "";
      bbox = field.bbox ?? {};
      page = field.page ? field.page - 1 : 0;
      polygon = field.polygon ?? [];
      blockType = field.blockType ?? "";
    }
    const newObj = {
      key: key1,
      parentKey: parent,
      group,
      order,
      label,
      Id,
      value,
      bbox,
      page,
      polygon,
      blockType,
    };
    if (Id !== "") {
      mappedKeyValues.push(newObj);
      mappedIds.push(Id);
    }
    return newObj;
  };

  const WJ_setTableColumnData = (table) => {
    const updatedRows = [];
    const columns = [];
    const defaultColumns = {};
    // add foreach
    table.map((data, index) => {
      const newId = data.id && data.id !== "" ? data.id : WJ_getRandomNumber();
      updatedRows.push({
        ...data,
        id: newId,
      });
      if (index === 0) {
        for (const [key] of Object.entries(data)) {
          if (key !== "id") {
            columns.push({
              field: key,
              headerName: key.charAt(0).toUpperCase() + key.slice(1),
              width: 200,
              editable: true,
              sortable: false,
            });
            defaultColumns[key] = "";
          }
        }
      }
    });
    setTableData(updatedRows);
    setTableColumns(columns);
    setNewRowColumns(defaultColumns);
  };
  const WJ_getMappingData = (data) => {
    const mappedData = data.header && data.header.data ? data.header.data : {};
    const hasNonEmptyValue = Object.values(mappedData).some((item) => item.value !== "");
    // console.log("mappedData>>", hasNonEmptyValue);

    // Set the disableExport state based on the condition
    setDisableExport(hasNonEmptyValue);
    return mappedData;
  };
  const WJ_getTableData = (data) => {
    const table = data.table && data.table.data ? data.table.data : [];
    return table;
  };
  const WJ_getExtractionDataApiHandler = async () => {
    authCtx.showLoader();

    const response = await WJ_getGeminiData({
      id_Token: authCtx.idToken,
      documentId: params.id,
      documentType: params.type,
      name: params.name,
    });
    // console.log(response);
    if (response.status && response.status === 200) {
      // await setExtractionDataFromApi(response);
      if (response.data && response.data.data.data_url) {
        const dataFileResponse = await WJ_getResponseApiHandler(response.data.data.data_url);
        if (dataFileResponse.status === 200 && dataFileResponse.data) {
          const mappedData = WJ_getMappingData(dataFileResponse.data);
          const table = WJ_getTableData(dataFileResponse.data);

          let i = 0;
          const sameObjectasInputFields = [];
          let parent = "basic info";
          if (!sameObjectasInputFields[parent]) sameObjectasInputFields[parent] = [];
          for (const key in mappedData) {
            if (mappedData.hasOwnProperty(key)) {
              const field = mappedData[key];
              sameObjectasInputFields[parent].push(WJ_getFieldListObj(field, key, i, parent));
              i = i + 1;
            }
          }

          setKeyValue(sameObjectasInputFields);
          setOldMappingData(mappedData);

          // if (table.length > 0 && Object.keys(table[0]).length > 0) {
          //   WJ_setTableColumnData(table);
          //   inputFieldListData['line items'] = [];
          //   inputFieldListData['line items'].push({
          //     key: 'line_item',
          //     parentKey: 'line items',
          //     group: 'line items',
          //     order: 0,
          //     label: 'Line Item',
          //     Id: 'table-data',
          //     value: table.length,
          //   });
          // }
          // if (table) {
          //   // WJ_setTableColumnData(table);
          //   inputFieldListData["line items"] = [];
          //   inputFieldListData["line items"].push({
          //     key: "line_item",
          //     parentKey: "line items",
          //     group: "line items",
          //     order: 0,
          //     label: "Line Item",
          //     Id: "table-data",
          //   });
          // }
        }
      }
      await WJ_setImageDataFromApi(response);
      WJ_setTimeoutForInitialLoad(response);
      authCtx.hideLoader();
    } else {
      authCtx.hideLoader();
      console.log("FAIL=============");
      toast.error("Something went wrong, Please try again later");
      navigate("/dashboard");
    }
  };

  // Call api to get fields to extract
  // const WJ_getTypeFieldListData = (typeFields) => {
  //   const fieldListData = [];
  //   typeFields.map((field) => {
  //     if (
  //       field.group &&
  //       field.group !== "" &&
  //       field.key &&
  //       field.key !== "" &&
  //       field.value &&
  //       field.value !== ""
  //     ) {
  //       const isExist = fieldListData[field.group];
  //       if (isExist === undefined) {
  //         fieldListData[field.group] = [];
  //       }
  //       fieldListData[field.group].push(field);
  //     }
  //     return true;
  //   });
  //   return fieldListData;
  // };

  // const WJ_getTypeFields = (fieldsResponse) => {
  //   let typeFields = [];
  //   if (
  //     fieldsResponse.status &&
  //     fieldsResponse.status === 200 &&
  //     fieldsResponse.data.data &&
  //     Array.isArray(fieldsResponse.data.data)
  //   ) {
  //     typeFields = fieldsResponse.data.data ?? [];
  //   }

  //   return typeFields;
  // };

  // const WJ_getFieldListApiHandlerr = async () => {
  //   const localFields = JSON.parse(localStorage.getItem("docTypeFields"));
  //   let typeFields = [];
  //   if (
  //     localFields &&
  //     localFields[params.type] &&
  //     localFields[params.type].length > 0
  //   ) {
  //     typeFields = localFields[params.type];
  //   } else {
  //     authCtx.showLoader();
  //     const fieldsResponse = await WJ_getFieldListApi({ type: params.type });
  //     typeFields = WJ_getTypeFields(fieldsResponse);
  //     authCtx.hideLoader();
  //   }

  //   if (typeFields.length > 0) {
  //     setFields(typeFields);
  //     WJ_getExtractionDataApiHandler(WJ_getTypeFieldListData(typeFields));
  //   } else {
  //     toast.error("Something went wrong, Please try again later");
  //     // navigate('/dashboard');
  //   }
  // };

  // Call api after component loaded
  useEffect(() => {
    if (Object.keys(keyValue).length === 0) {
      // Only call the API if keyValue is empty
      WJ_getExtractionDataApiHandler();
    }

    // WJ_getFieldListApiHandlerr();
    return () => {
      document.querySelectorAll(".leader-line").forEach((el) => el.remove());
      document.body.classList.remove("hide-scroll");
    };
  }, []);
  // Handle click of left side section row
  const rowClickHandler = (trId, divId, parentTrId, label, defaultExtValue) => {
    setMappingBoxOpen(true);
    if (document.getElementById("myTextarea") && document.getElementById("myTextarea") !== null) {
      document.getElementById("myTextarea").style.height = "52px";
    }
    if (trId !== "") {
      if (trId === "line_item") {
        setLineItemClicked(true);
        if (!openTable) {
          setDefaultValue(defaultExtValue);
          setActiveTrId(trId);
          setActiveDivId(divId);
          setActiveParentTrId(parentTrId);
          setActiveLabel(label);
          if (divId !== "") {
            for (const item of keyValue) {
              if (typeof item === "object" && item.key === label) {
                WJ_drawLeaderLine(trId, divId);
                break; // Exit loop after finding the first match
              }
            }
          }
        } else {
          setDefaultValue("");
          setActiveTrId(null);
          setActiveDivId(null);
          setActiveParentTrId(null);
          setActiveLabel(null);
        }
        toggleTableOpen(!openTable);
      } else {
        setDefaultValue(defaultExtValue);
        setActiveTrId(trId);
        setActiveDivId(divId);
        setActiveParentTrId(parentTrId);
        setActiveLabel(label);
        if (divId !== "") {
          for (const item of keyValue) {
            if (typeof item === "object" && item.bbox.length) {
              WJ_drawLeaderLine(trId, divId);
              break; // Exit loop after finding the first match
            }
          }
        }
        toggleTableOpen(false);
      }
    }
  };
  // Validate text mapped to field
  const WJ_validateText = (text) => {
    const fieldObj = fields.filter((obj) => obj.key === activeTrId);
    const minLength =
      fieldObj[0] && fieldObj[0].validations && fieldObj[0].validations.min_length
        ? fieldObj[0].validations.min_length
        : null;
    const maxLength =
      fieldObj[0] && fieldObj[0].validations && fieldObj[0].validations.max_length
        ? fieldObj[0].validations.max_length
        : null;
    if (minLength && maxLength && (text.trim().length < minLength || text.trim().length > maxLength)) {
      fieldsError[activeTrId] = `Text length must be between ${minLength} to ${maxLength}`;
      setFieldsError({ ...fieldsError });
    } else {
      fieldsError[activeTrId] = "";
      setFieldsError({ ...fieldsError });
    }
  };
  // Handle save and delete of mapping box
  const WJ_deleteClickHandler = (action, parentKey, childKey) => {
    setChangesDone(true);
    setLineItemClicked(false);
    const parentKeyData = keyValue[parentKey] ?? [];
    if (parentKeyData.length > 0) {
      let updatedItems;
      const index = parentKeyData.findIndex((obj) => obj.key === childKey);
      if (index !== -1) {
        const existingItem = parentKeyData[index];
        let updatedItem = {};
        updatedItem = {
          ...existingItem,
          value: "",
          Id: "",
          bbox: "",
          saved: false,
          polygon: [],
          blockType: "",
        };
        fieldsError[childKey] = "";
        setFieldsError({ ...fieldsError });
        updatedItems = keyValue;
        updatedItems[parentKey][index] = updatedItem;
        const remainingMappedKeyValues = mappedKeyValues.filter((data) => data.key !== childKey);
        setActiveDivId(null);
        setMappedKeyValues(remainingMappedKeyValues);
        setKeyValue(updatedItems);
      }
    }
  };
  const saveActiveTrAndDivId = (
    nextActiveTrId,
    nextActiveDivId,
    nextActiveParentId,
    nextActiveLabel,
    nextActiveValue
  ) => {
    if (nextActiveTrId !== "") {
      setActiveTrId(nextActiveTrId);
      setActiveParentTrId(nextActiveParentId);
      setActiveLabel(nextActiveLabel);
    }
    if (nextActiveDivId !== "") {
      setActiveDivId(nextActiveDivId);
      setDefaultValue(nextActiveValue);
    }
    if (nextActiveTrId !== "" && nextActiveDivId !== "") {
      if (Object.keys(keyValue).length === 0) {
        // Only call the API if keyValue is empty
        WJ_getExtractionDataApiHandler();
      }
      WJ_drawLeaderLine(nextActiveTrId, nextActiveDivId);
      if (nextActiveTrId === "line_item") {
        toggleTableOpen(true);
      } else {
        toggleTableOpen(false);
      }
    }
  };
  const WJ_saveNextRowData = (nextRowData) => {
    const nextActiveTrId = nextRowData.key ?? "";
    const nextActiveParentId = nextRowData.parentKey ?? "";
    const nextActiveLabel = nextRowData.label ?? "";
    const nextActiveDivId = nextRowData.Id ?? "";
    const nextActiveValue = nextRowData.value ?? "";
    saveActiveTrAndDivId(nextActiveTrId, nextActiveDivId, nextActiveParentId, nextActiveLabel, nextActiveValue);
  };
  const WJ_saveNextKeyData = (nextKeyData) => {
    const nextActiveTrId = nextKeyData.key ?? "";
    const nextActiveParentId = nextKeyData.parentKey ?? "";
    const nextActiveLabel = nextKeyData.label ?? "";
    const nextActiveDivId = nextKeyData.Id ?? "";
    const nextActiveValue = nextKeyData.value ?? "";
    saveActiveTrAndDivId(nextActiveTrId, nextActiveDivId, nextActiveParentId, nextActiveLabel, nextActiveValue);
  };

  const WJ_createSaveNextKeyDataObject = (parentKey) => {
    const keys = Object.keys(keyValue);
    const keyIndex = keys.findIndex((obj) => obj === parentKey);
    const nextKeyData =
      keyValue[keys[keyIndex + 1]] && keyValue[keys[keyIndex + 1]][0] ? keyValue[keys[keyIndex + 1]][0] : {};
    if (Object.keys(nextKeyData).length > 0) {
      WJ_saveNextKeyData(nextKeyData);
    }
  };
  const WJ_saveClickHandler = (action, parentKey, childKey, value) => {
    setChangesDone(true);
    setLineItemClicked(false);
    const parentKeyData = keyValue[parentKey] ?? [];
    if (parentKeyData.length > 0) {
      let updatedItems;
      const index = parentKeyData.findIndex((obj) => obj.key === childKey);
      if (index !== -1) {
        const existingItem = parentKeyData[index];
        let updatedItem = {};

        updatedItem = {
          ...existingItem,
          value,
          saved: true,
        };
        WJ_validateText(value);

        updatedItems = keyValue;
        updatedItems[parentKey][index] = updatedItem;
        const updatedMappedKeyValues = mappedKeyValues;
        const index1 = updatedMappedKeyValues.findIndex((data) => data.key === childKey);
        if (index1 !== -1) {
          updatedMappedKeyValues[index1].value = value;
        }
        setMappedKeyValues(updatedMappedKeyValues);
        setActiveTrId(null);
        setActiveParentTrId(null);
        setActiveLabel(null);
        setActiveDivId(null);
        const nextRowData = parentKeyData[index + 1];
        if (nextRowData !== undefined) {
          WJ_saveNextRowData(nextRowData);
        } else {
          WJ_createSaveNextKeyDataObject(parentKey);
        }

        setKeyValue(updatedItems);
      }
    }
  };
  // Handle mapping on click of right side section div
  const WJ_extractionDivClickHandler = (data) => {
    setMappingBoxOpen(true);
    if (document.getElementById("myTextarea") && document.getElementById("myTextarea") !== null) {
      document.getElementById("myTextarea").style.height = "52px";
    }
    if (activeParentTrId !== null && activeTrId !== null && activeTrId !== "line_item") {
      const parentKeyData = keyValue["basic info"] ?? [];
      let updatedItems;
      if (parentKeyData.length > 0) {
        const index = parentKeyData.findIndex((obj) => obj.key === activeTrId);
        if (index !== -1) {
          fieldsError[activeTrId] = "";
          setFieldsError({ ...fieldsError });
          const existingItem = parentKeyData[index];
          let updatedItem = {};
          updatedItem = {
            ...existingItem,
            value: data.text,
            Id: data.Id,
            bbox: data.bbox,
            page: data.page,
            polygon: data.polygon ?? [],
            blockType: data.blockType ?? "",
          };
          updatedItems = keyValue;
          updatedItems["basic info"][index] = updatedItem;
          const newMappedKeyValues = mappedKeyValues.filter((obj) => obj.key !== activeTrId);
          newMappedKeyValues.push({
            key: activeTrId,
            parentKey: activeParentTrId,
            label: activeLabel,
            value: data.text,
            Id: data.Id,
            bbox: data.bbox,
            page: data.page,
            polygon: data.polygon ?? [],
            blockType: data.blockType ?? "",
          });
          const oldMappedIds = mappedIds;
          oldMappedIds.push(data.Id ?? "");
          setDefaultValue(data.text);
          setMappedIds(oldMappedIds);
          setKeyValue(updatedItems);
          setMappedKeyValues(newMappedKeyValues);
          setActiveDivId(data.Id);

          if (item.key == activeLabel && item.bbox.lenght > 0) {
            WJ_drawLeaderLine(activeTrId, data.Id);
          }
        }
      }
    }
  };
  // Update Image dimension function
  const WJ_updateImageDimensions = (newWidth) => {
    const tempDimensions = [];
    for (let i = 0; i < imageDimensions.length; i += 1) {
      const imageWidth = defaultImageDimensions[i].width1 * (newWidth / 100);
      const imageHeight = defaultImageDimensions[i].height1 * (newWidth / 100);
      tempDimensions.push({ width1: imageWidth, height1: imageHeight });
    }
    setImageDimensions(tempDimensions);
  };
  // Handle zoom in and out click
  const WJ_zoomClickHandler = (type) => {
    let newWidth = minWidth;
    if (type === "in") {
      newWidth = minWidth + frequencyConfig;
    } else if (type === "out") {
      newWidth = minWidth - frequencyConfig;
    } else if (type === "reset") {
      newWidth = 100;
    }
    WJ_updateImageDimensions(newWidth);
    const imageWidth = defaultWidth * (newWidth / 100);
    const imageHeight = defaultHeight * (newWidth / 100);
    setMinWidth(newWidth);
    setMaxWidth(newWidth);
    setWidth(imageWidth);
    setHeight(imageHeight);
    setTimeout(() => {
      WJ_updateXarrow();
    }, 100);
  };
  // Call api to save extraction data
  const WJ_getConfirmDataObject = (data, status) => {
    const dataObject = {
      value: data.value ?? "",
      Id: data.Id ? data.Id : "",
      bbox: data.bbox ?? {},
      page: data.page ? Number(data.page) + 1 : 1,
      polygon: data.polygon ?? [],
      status,
      blockType: data.blockType ?? "",
    };
    return dataObject;
  };
  const WJ_getStatusOfField = (oldKeyData, data) => {
    let status = "";
    if (
      oldKeyData.value !== data.value ||
      (oldKeyData.value === data.value && oldKeyData.bbox.Left !== data.bbox.Left)
    ) {
      status = "changed";
    }
    return status;
  };
  const WJ_getConfirmedData = () => {
    const confirmedData = {};
    Object.keys(keyValue).forEach((key) => {
      const parentData = keyValue[key] ?? [];

      // add foreach
      parentData?.forEach((data) => {
        let status = "";
        const oldKeyData = oldMappingData[data.key] ?? {};

        if (Object.keys(oldKeyData).length > 0 && oldKeyData.value !== undefined && oldKeyData.bbox !== undefined) {
          status = WJ_getStatusOfField(oldKeyData, data);
        }
        confirmedData[data.key] = {};
        confirmedData[data.key] = WJ_getConfirmDataObject(data, status);
      });
    });
    return confirmedData;
  };

  const WJ_saveExtractionDataApiHandler = async () => {
    const confirmedData = WJ_getConfirmedData();
    console.log("confirmeeddd ===>", confirmedData);
    const hasNonEmptyValue = Object.values(confirmedData).some((item) => item.value !== "");
    // Set the disableExport state based on the condition
    setDisableExport(hasNonEmptyValue);
    console.log("setDisableExport", disableExport);
    console.log("setDisableExport", confirmedData);
    if (Object.keys(confirmedData).length > 0) {
      authCtx.showLoader();
      const formData = {
        document_id: params.id,
        document_type: params.type,
        // document_sub_type: subtypeToSend.toLowerCase() || "",
        header: {
          data: confirmedData,
        },
        table: {
          data: tableData,
        },
      };
      const response = await WJ_saveExtractionData({
        id_Token: authCtx.idToken,
        data: formData,
      });
      if (response.status && response.status === 200) {
        setChangesDone(false);
        // setDisableExport(false);
        toast.success(
          response.data.message
            ? response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            : "Document saved successfully."
        );
      } else {
        toast.error(
          response.data && response.data.message
            ? response.data.message
            : "Something went wrong, Please try again later."
        );
      }
      authCtx.hideLoader();
    }
  };
  // Table edit save delete click handler
  const WJ_updateTableData = (action, input) => {
    setChangesDone(true);
    if (action === "add") {
      delete input.isNew;
      setTableData([...tableData, input]);
    } else if (action === "update") {
      delete input.isNew;
      setTableData(tableData.map((row) => (row.id === input.id ? input : row)));
    } else if (action === "delete") {
      setTableData(tableData.filter((row) => row.id !== input.id));
    }
  };
  // Handle mouse down event of image section to capture data for multiline mapping
  const WJ_imageDivMouseDownHandler = (event) => {
    if (activeDivId == null && lineItemClicked) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select a label from left menu");
      }
    }
    const id = event.target && event.target.id ? event.target.id.split("-")[1] : "";
    if (id !== "" && (activeDivId === null || activeDivId === "")) {
      const parent = document.getElementById(`${id}-img`);
      const imgWidth = imageDimensions[id] && imageDimensions[id].width1 ? imageDimensions[id].width1 : 0;
      const imgHeight = imageDimensions[id] && imageDimensions[id].height1 ? imageDimensions[id].height1 : 0;
      if (parent) {
        const bounds = parent.getBoundingClientRect();
        setCustomDivNumber(id);
        setCustomTop((event.clientY - bounds.top) / imgHeight);
        setCustomLeft((event.clientX - bounds.left) / imgWidth);
        setCustomHeight(0);
        setCustomWidth(0);
        setCaptureMove(true);
        setFirstLeft((event.clientX - bounds.left) / imgWidth);
        setFirstTop((event.clientY - bounds.top) / imgHeight);
      }
    }
  };
  // Handle mouse move event of image section to capture data for multiline mapping
  const WJ_imageDivMoveHandler = (event) => {
    if (captureMove) {
      const parent = document.getElementById(`${customDivNumber}-img`);
      const bounds = parent.getBoundingClientRect();
      const imgWidth =
        imageDimensions[customDivNumber] && imageDimensions[customDivNumber].width1
          ? imageDimensions[customDivNumber].width1
          : 0;
      const imgHeight =
        imageDimensions[customDivNumber] && imageDimensions[customDivNumber].height1
          ? imageDimensions[customDivNumber].height1
          : 0;
      const widthC = (event.clientX - bounds.left) / imgWidth;
      const heightC = (event.clientY - bounds.top) / imgHeight;
      if (widthC < customLeft) {
        setCustomLeft(widthC);
        setCustomWidth(firstLeft - widthC);
      } else {
        setCustomWidth(widthC - customLeft);
      }
      if (heightC < customTop) {
        setCustomTop(heightC);
        setCustomHeight(firstTop - heightC);
      } else {
        setCustomHeight(heightC - customTop);
      }
    }
  };
  // Check for extraction text using co-ordinates of selected area
  const WJ_getValueForBlock = (lineLeft, lineTop, input, blockText) => {
    let value = "";
    if (
      lineLeft > input.left &&
      lineLeft < input.left + input.width &&
      lineTop > input.top &&
      lineTop < input.top + input.height
    ) {
      value = blockText ? blockText.concat(" ") : "";
    }
    return value;
  };
  // const WJ_checkForContent = (input) => {
  //   let value = "";
  //   const extData = extractionData[input.key] ?? {};
  //   if (Object.keys(extData).length > 0 && extData.Blocks) {
  //     extData.Blocks.map((block) => {
  //       if (
  //         block.BlockType === "WORD" ||
  //         ("CUSTOM" &&
  //           block.Geometry &&
  //           block.Geometry.BoundingBox &&
  //           Object.keys(block.Geometry.BoundingBox).length > 0)
  //       ) {
  //         const lineLeft = block.Geometry.BoundingBox.Left;
  //         const lineTop = block.Geometry.BoundingBox.Top;
  //         value += WJ_getValueForBlock(lineLeft, lineTop, input, block.Text);
  //       }
  //     });
  //   }
  //   return value;
  // };
  // Handle mouse up event of image section to capture data for multiline mapping
  const WJ_imageDivMouseUpHandler = () => {
    setCaptureMove(false);
    if ((activeDivId === null || activeDivId === "") && activeTrId !== null && activeTrId !== "line_item") {
      const responseText = WJ_checkForContent({
        key: customDivNumber,
        left: customLeft,
        top: customTop,
        width: customWidth,
        height: customHeight,
      });
      const page = customDivNumber;
      if (responseText !== "") {
        const randomString = WJ_generateRandomString(8);
        const oldMappedKeyValue = mappedKeyValues;
        oldMappedKeyValue.push({
          Id: randomString,
          bbox: {
            Left: customLeft,
            Top: customTop,
            Width: customWidth,
            Height: customHeight,
          },
          page,
          value: responseText,
          actualData: true,
          group: activeParentTrId,
          key: activeTrId,
          label: activeLabel,
          parentKey: activeParentTrId,
          polygon: [
            { X: customLeft, Y: customTop },
            { X: customLeft + customWidth, Y: customTop },
            { X: customLeft + customWidth, Y: customTop + customHeight },
            { X: customLeft, Y: customTop + customHeight },
          ],
          blockType: "CUSTOM",
        });
        const parentKeyData = keyValue[activeParentTrId] ?? [];
        if (parentKeyData.length > 0) {
          let updatedItems;
          const index = parentKeyData.findIndex((obj) => obj.key === activeTrId);
          if (index !== -1) {
            const existingItem = parentKeyData[index];
            let updatedItem = {};
            updatedItem = {
              ...existingItem,
              value: responseText,
              Id: randomString,
              bbox: {
                Left: customLeft,
                Top: customTop,
                Width: customWidth,
                Height: customHeight,
              },
              page,
              polygon: [
                { X: customLeft, Y: customTop },
                { X: customLeft + customWidth, Y: customTop },
                { X: customLeft + customWidth, Y: customTop + customHeight },
                { X: customLeft, Y: customTop + customHeight },
              ],
              blockType: "CUSTOM",
            };
            updatedItems = keyValue;
            updatedItems[activeParentTrId][index] = updatedItem;
            const oldMappedIds = mappedIds;
            oldMappedIds.push(randomString);
            setDefaultValue(responseText);
            setMappedIds(oldMappedIds);
            setMappedKeyValues(oldMappedKeyValue);
            setKeyValue(updatedItems);
            setActiveDivId(randomString);
            setTimeout(() => {
              WJ_drawLeaderLine(activeTrId, randomString);
            }, 200);
          }
        }
      }
    }
    setCustomDivNumber(null);
    setCustomTop(0);
    setCustomLeft(0);
    setCustomHeight(0);
    setCustomWidth(0);
  };
  // Call export api
  const WJ_exportApiHandler = async (type) => {
    authCtx.showLoader();
    const name = params.name ? params.name.split(".")[0] : "";
    const exportResponse = await WJ_exportDocument({
      id_Token: authCtx.idToken,
      documentId: params.id,
      documentType: params.type,
      fileName: name,
      type,
    });
    if (exportResponse.status && exportResponse.status === 200) {
      setOpen(false);
      if (exportResponse.data && exportResponse.data.data !== null && exportResponse.data.data !== "") {
        try {
          const res = exportResponse.data.data;
          const link = document.createElement("a");
          link.href = `data:application/${type};charset=utf-8,${encodeURIComponent(
            type === "json" ? JSON.stringify(res) : res
          )}`;
          link.setAttribute("download", `${name}-output.${type}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          authCtx.hideLoader();
          setOpenDownloadModal(true);
        } catch (err) {
          authCtx.hideLoader();
          toast.error("Something went wrong, please try again later.");
        }
      } else {
        authCtx.hideLoader();
        toast.error("Something went wrong, please try again later.");
      }
    } else {
      setOpen(false);
      authCtx.hideLoader();
      toast.error(exportResponse.message ?? "Something went wrong, please try again later.");
    }
  };
  const WJ_openConfirmationHandler = () => {
    if (changesDone) {
      setBackClicked(true);
    } else {
      navigate("/dashboard");
    }
  };
  const WJ_handleNoClick = () => {
    setBackClicked(false);
  };
  const WJ_handleYesClick = () => {
    setBackClicked(false);
    navigate("/dashboard");
  };
  const tableCloseHandler = () => {
    setActiveDivId(null);
    setActiveLabel(null);
    setActiveParentTrId(null);
    setActiveTrId(null);
    toggleTableOpen(!openTable);
    setLineItemClicked(true);
  };

  return (
    <>
      <WJ_Header docName={params.name} onClick={WJ_openConfirmationHandler} changesDone={changesDone} />
      <Box sx={{ marginTop: "80px" }}>
        <WJ_LeavePageConfirmationModal open={backClicked} noClicked={WJ_handleNoClick} yesClicked={WJ_handleYesClick} />
        {/* <WJ_MappingLine activeTrId={activeTrId} activeDivId={activeDivId} /> */}
        <Stack className="extraction" direction="row">
          <WJ_Extraction
            data={keyValue}
            activeTrId={activeTrId}
            onClick={rowClickHandler}
            tableData={tableData}
            onConfirm={WJ_saveExtractionDataApiHandler}
            onExport={WJ_exportApiHandler}
            // open={open}
            // setOpen={setOpen}
            disableExport={disableExport}
            docStatus={docStatus}
            onScroll={WJ_updateXarrow}
          />

          <WJ_Image
            documentName={params.name}
            documentType={params.type}
            open={open}
            setOpen={setOpen}
            disableExport={disableExport}
            onConfirm={WJ_saveExtractionDataApiHandler}
            onExport={WJ_exportApiHandler}
            images={documentImages}
            data={keyValue}
            height={height}
            width={width}
            dimension={imageDimensions}
            extractionData={extractionData}
            mappedKeyValues={mappedKeyValues}
            // activeTrId={activeTrId}
            // activeDivId={activeDivId}
            minWidth={minWidth}
            maxWidth={maxWidth}
            onSaveButtonClick={WJ_saveClickHandler}
            onDeleteButtonClick={WJ_deleteClickHandler}
            onExtractionDivClick={WJ_extractionDivClickHandler}
            onZoomClick={WJ_zoomClickHandler}
            mappedIds={mappedIds}
            tableData={tableData}
            WJ_tableColumns={WJ_tableColumns}
            newRowColumns={newRowColumns}
            setTableColumns={setTableColumns}
            WJ_updateTableData={WJ_updateTableData}
            defaultValue={defaultValue}
            customTop={customTop}
            customLeft={customLeft}
            customWidth={customWidth}
            customHeight={customHeight}
            WJ_imageDivMouseDownHandler={WJ_imageDivMouseDownHandler}
            WJ_imageDivMoveHandler={WJ_imageDivMoveHandler}
            WJ_imageDivMouseUpHandler={WJ_imageDivMouseUpHandler}
            customDivNumber={customDivNumber}
            tableActivePage={tableActivePage}
            setTableActivePage={setTableActivePage}
            openTable={openTable}
            WJ_validateText={WJ_validateText}
            fieldsError={{ ...fieldsError }}
            onScroll={WJ_updateXarrow}
            mappingBoxOpen={mappingBoxOpen}
            handleCollape={setMappingBoxOpen}
            tableCloseHandler={tableCloseHandler}
          />
        </Stack>
        <WJ_FileDownloadedModal openDownloadModal={openDownloadModal} onClose={setOpenDownloadModal} showDashboard />
      </Box>
    </>
  );
}

export default WJ_Document;
