/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Box, Stack, Button, Typography, Modal, Grid } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { NavLink } from 'react-router-dom';
import downloadSuccess from '../../WJ_assets/images/download-success.png';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  maxWidth: '500px',
  margin: 'auto',
  outline: 'none',
};

export default function WJ_FileDownloadedModal(props) {
  return (
    <div>
      <Modal
        open={props.openDownloadModal}
        onClose={() => {
          props.onClose(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container sx={style}>
          <Grid item md={12} xs={12} sx={{ p: '1% 4%' }}>
            <Button
              sx={{
                float: 'right',
                color: 'black',
                background: 'transparent !important',
                justifyContent: 'right',
                paddingRight: '0Px !important',
              }}
              onClick={() => {
                props.onClose();
              }}
              title="Close"
            >
              <HighlightOffOutlinedIcon />
            </Button>
          </Grid>
          <Grid item md={12} xs={12} sx={{ p: '2% 4%' }}>
            <Stack gap="1rem" sx={{ textAlign: 'center' }}>
              <Box>
                <img src={downloadSuccess} alt="" />
              </Box>
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  fontFamily: 'NunitoSans-Regular !important',
                  color: '#1AA816',
                }}
              >
                Your file has been downloaded successfully !
              </Typography>
              {props.showDashboard && (
                <Box>
                  <NavLink
                    to="/dashboard"
                    style={{
                      textDecoration: 'none',
                      color: '#ED1C24',
                      fontSize: '0.9rem',
                    }}
                  >
                    Go Back to Dashboard
                  </NavLink>
                </Box>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}
