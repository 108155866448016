/*eslint-disable*/

import React, { useReducer, useMemo } from "react";
import { initialState, actions } from "./state";
import reducer from "./reducer";
import { WJ_getFieldListApi } from "../../WJ_services/WJ_apiService/WJ_apis";

const uploadDocCntxt = React.createContext();

const UploadDocProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function getKeysData() {
    const res = await WJ_getFieldListApi();
    const tempData = res?.data?.data;
    if (tempData?.keys && tempData?.useraddedkeys) {
      const limitedKeys = {};
      Object.keys(tempData?.keys).forEach((type) => {
        limitedKeys[type] = tempData?.keys[type].slice(0, 15);
      });
      dispatch({ type: "SET_KEYSDATA", payload: limitedKeys });
      dispatch({ type: "SET_USERADDEDKEYS", payload: tempData?.useraddedkeys });

      const allDocumentTypes = Object.keys(limitedKeys).concat(Object.keys(tempData?.useraddedkeys));
      dispatch({
        type: "SET_DOCUMENTTYPEOPTIONS",
        payload: allDocumentTypes.map((type) => ({ key: type, value: type })),
      });
    }
  }

  const handleFileTypeChange = (event) => {
    dispatch({ type: "SET_FILETYPEERROR", payload: null });
    dispatch({ type: "SET_FILEERROR", payload: null });
    dispatch({ type: "SET_FILE", payload: {} });
    dispatch({ type: "SET_FILENAME", payload: "" });

    if (event.target.value === "") {
      dispatch({ type: "SET_FILETYPEERROR", payload: "This field is required" });
    }
    dispatch({ type: "SET_FILETYPEVAL", payload: event.target.value });
  };

  const WJ_fileClickHandler = (event) => {
    setFileTypeError(null);
    setDocumentTypeError(null);
    if (fileTypeVal === "") {
      setFileTypeError("This field is required");
      event.preventDefault();
    }
    if (documentTypeVal === "") {
      setDocumentTypeError("This field is required");
      event.preventDefault();
    }
  };

  // Use useMemo to prevent context value re-creation on each render
  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      getKeysData,
      handleFileTypeChange
    }),
    [state, dispatch]
  );

  return <uploadDocCntxt.Provider value={contextValue}>{props?.children}</uploadDocCntxt.Provider>;
};

export { UploadDocProvider, uploadDocCntxt };
