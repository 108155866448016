/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AppBar, Box, Stack, Toolbar, Button, Typography, IconButton, Menu, MenuItem, Grid } from "@mui/material";
import {
  Assignment as AssignmentIcon,
  AccountCircleOutlined as AccountCircleIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from "@mui/icons-material";
import WJ_AuthContext from "../../WJ_utils/WJ_Auth";
import logo from "../../WJ_assets/images/logo-grey.png";
import WJ_SignOutModal from "../WJ_Modal/WJ_SignOutModal";
import raphael_cloud2 from "../../WJ_assets/images/raphael_cloud2.png";
import DropdownWhite from "../../WJ_assets/images/DropdownWhite.png";
import { WJ_getUserDetails } from "../../WJ_services/WJ_apiService/WJ_apis";
import { HiMiniUser } from "react-icons/hi2";

export default function WJ_Header(props) {
  const authCtx = useContext(WJ_AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [WJ_setUserData, setWJ_setUserData] = useState("");
  const open = Boolean(anchorEl);
  const [backClicked, setBackClicked] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await WJ_getUserDetails({ id_Token: authCtx.idToken });
      if (response.data && response.data.status && response.data.status === 200) {
        setWJ_setUserData(response?.data?.data?.user_name);
      } else {
        authCtx.logout();
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = process.env.REACT_APP_COGNITO_POOL_LOGOUT_URL;
      }
    }
    fetchData();
  }, [authCtx.idToken]);
  const WJ_handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const WJ_handleClose = () => {
    setAnchorEl(null);
  };
  const WJ_logoutHandler = () => {
    if (props.changesDone) {
      setBackClicked(true);
    } else {
      authCtx.logout();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = process.env.REACT_APP_COGNITO_POOL_LOGOUT_URL;
    }
  };

  const WJ_handleNoClick = () => {
    setBackClicked(false);
  };
  const WJ_handleYesClick = () => {
    setBackClicked(false);
    authCtx.logout();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = process.env.REACT_APP_COGNITO_POOL_LOGOUT_URL;
  };
  // console.log('WJ_setUserData', WJ_setUserData);
  return (
    <>
      {/* <Box>
        <WJ_SignOutModal
          open={backClicked}
          noClicked={WJ_handleNoClick}
          yesClicked={WJ_handleYesClick}
        />
        <AppBar position="static" sx={{ background: '#ffffff' }}>
          <Toolbar className="inner-header">
            <Box className="left">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <img src={logo} alt="" />
              </IconButton>
            </Box>
            {props.docName && (
              <Stack gap="1rem" direction="row" className="doc-name">
                <Button
                  sx={{
                    background: '#F3F3F3',
                    color: '#000000',
                  }}
                  onClick={() => {
                    props.onClick();
                  }}
                >
                  <KeyboardBackspaceIcon />
                  Back
                </Button>
                <Typography sx={{ color: '#000000' }} title={props.docName}>
                  {props.docName.length > 35
                    ? `${props.docName.slice(0, 35)}...`
                    : props.docName}
                </Typography>
              </Stack>
            )}
            <Box className="right">
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{
                  color: 'secondary.main',
                }}
                title="WJ_Dashboard"
                onClick={() => {
                  props.onClick();
                }}
              >
                <AssignmentIcon />
              </IconButton>
              <IconButton
                className="icon4"
                onClick={WJ_handleClick}
                size="large"
                sx={{ color: 'black' }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={WJ_handleClose}
                onClick={WJ_handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                  <NavLink
                    to="/profile"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    My Profile
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={WJ_logoutHandler}>Sign Out</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </Box> */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          width: "100%", // Ensure the header spans the full width
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000, // Adjust the z-index based on your layout
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: "#034EA2" }}
          // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 50px",
            }}
          >
            <Grid item sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <img
                src={raphael_cloud2}
                alt=""
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <NavLink to="/dashboard" style={{ textDecoration: "none", color: "inherit" }}>
                <Typography variant="h6">Docusense</Typography>
              </NavLink>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                gap: "10px",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Typography variant="">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <HiMiniUser />
                  <Box sx={{ marginLeft: 1 }}>{WJ_setUserData}</Box>
                </Box>
              </Typography>
              <IconButton
                // className="icon4"
                onClick={WJ_handleClick}
                // size="large"
                // sx={{ color: 'black' }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <img
                  src={DropdownWhite}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={WJ_handleClose}
                onClick={WJ_handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <NavLink to="/profile" style={{ textDecoration: "none", color: "black" }}>
                    My Profile
                  </NavLink>
                </MenuItem>
                <MenuItem onClick={WJ_logoutHandler}>Sign Out</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </AppBar>
      </Box>
    </>
  );
}
