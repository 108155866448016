/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import { Box, Stack, Button, Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import logo from '../../WJ_assets/images/docusense_logo.png';
import WJ_RequestDemoModal from '../WJ_Modal/WJ_RequestDemoModal';

function WJ_MainHeader() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const WJ_handleOpen = () => setOpen(true);
  const WJ_handleClose = () => setOpen(false);
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleSection4 = () => {
    const element = document.getElementById('section-4');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const WJ_handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const WJ_handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const WJ_exploreButtonHandler = () => {
    window.location.href = process.env.REACT_APP_COGNITO_POOL_LOGIN_URL;
  };

  return (
    <AppBar position="static">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="home-header"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: 'white.main',
        }}
      >
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={WJ_handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={WJ_handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem onClick={WJ_handleCloseNavMenu}>
              <Link href="/" color="#000000" sx={{ textDecoration: 'none' }}>
                MALKOM
              </Link>
            </MenuItem>
            <MenuItem onClick={WJ_handleCloseNavMenu}>
              <Link
                onClick={WJ_handleOpen}
                sx={{ textDecoration: 'none', color: '#000000' }}
              >
                Features
              </Link>
              <WJ_RequestDemoModal open={open} onClose={WJ_handleClose} />
            </MenuItem>
            <MenuItem onClick={WJ_handleCloseNavMenu}>
              <a
                href={process.env.REACT_APP_DOMAIN_URL}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: '#000000',
                }}
              >
                Solution
              </a>
            </MenuItem>
            <MenuItem onClick={WJ_handleCloseNavMenu}>
              <a
                href={process.env.REACT_APP_DOMAIN_URL}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: '#000000',
                }}
              >
                Resources
              </a>
            </MenuItem>
          </Menu>
        </Box>
        <div className="home-header-img">
          <img src={logo} alt="WNS logo" />
        </div>
        <Stack direction="row" alignItems="center" gap="2rem">
          {/* <nav>
            <Stack
              direction="row"
              alignItems="center"
              gap="1.5rem"
              className="nav-menu"
            >
              <li>
                <a>Home</a>
              </li>
              <li>
                <a>Product</a>
              </li>
              <li>
                <a>Solution</a>
              </li>
              <li>
                <a>Support </a>
              </li>
              <li>
                <a>News</a>
              </li>
              <li>
                <a>Company</a>
              </li>
            </Stack>
          </nav>
          <Box className="nav-search">
            <img src={searchIcon} alt="" />
          </Box> */}
          <nav>
            <Stack
              direction="row"
              alignItems="center"
              gap="1.5rem"
              className="nav-menu"
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <li>
                <Link href="/" color="#000000" sx={{ textDecoration: 'none' }}>
                  Platform
                </Link>
              </li>
              <li>
                <Link
                  onClick={WJ_handleOpen}
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: '#000000',
                  }}
                >
                  Features
                </Link>
                <WJ_RequestDemoModal open={open} onClose={WJ_handleClose} />
              </li>
              <li>
                {/* <a
                  href={process.env.REACT_APP_DOMAIN_URL}
                  download
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: '#FFFFFF',
                  }}
                >
                  HOW IT WORKS
                </a> */}
                <Link
                  onClick={handleSection4}
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: '#000000',
                  }}
                >
                  Solution
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleSection4}
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: '#000000',
                  }}
                >
                  Resources
                </Link>
              </li>
            </Stack>
          </nav>
          <Button
            className="nav-button"
            variant="contained"
            endIcon={<EastIcon />}
            onClick={WJ_exploreButtonHandler}
            style={{ color: 'white' }}
          >
            Try it for FREE
          </Button>
        </Stack>
      </Stack>
    </AppBar>
  );
}
export default WJ_MainHeader;
