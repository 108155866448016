/* eslint-disable camelcase */
/* eslint react/prop-types: 0 */
import React from 'react';
import Xarrow, { Xwrapper } from 'react-xarrows';

const WJ_MappingLine = (props) => {
  const { activeTrId, activeDivId } = { ...props };
  // console.log(activeDivId,activeTrId)
  return (
    <Xwrapper>
      {activeTrId !== null &&
        activeDivId !== null &&
        activeTrId !== '' &&
        activeDivId !== '' &&
        activeTrId !== 'line_item' && (
          <Xarrow
            color="#ED1C24"
            strokeWidth={2}
            headSize={6}
            start={`${activeTrId}-tr`}
            end={`${activeDivId}-div`}
            zIndex={1}
          />
        )}
    </Xwrapper>
  );
  // return (
  //   <Xwrapper>
  //     {/* {activeTrId !== null &&
  //       activeDivId !== null &&
  //       activeTrId !== '' &&
  //       activeDivId !== '' &&
  //       activeTrId !== 'line_item' && ( */}
  //         <Xarrow
  //           color="#ED1C24"
  //           strokeWidth={2}
  //           headSize={6}
  //           start={`${activeTrId}-tr`}
  //           end={`${activeDivId}-div`}
  //           zIndex={99999999}
  //         />
  //       {/* )} */}
  //   </Xwrapper>
  // );
};

export default WJ_MappingLine;
