/* eslint-disable camelcase */
export const WJ_zoomConfig = {
  minWidth: 40,
  maxWidth: 200,
  frequency: 10,
};
export const WJ_getDocumentsCallFrequencyInMilliseconds = 30000;
export const WJ_getUploadPresignedApiUrl = 'get-presigned-url';
export const WJ_getUserDocumentsApiUrl = 'users/documents';
export const WJ_getDownloadPresignedApiUrl = 'documents/get-presigned-url';
export const WJ_getUserDetailsApiUrl = 'users/details';
export const WJ_updateUserDetailsApiUrl = '/users/update-user-details';
export const WJ_getConfigurationsApiUrl = 'configurations';
export const WJ_getSampleDocumentsApiUrl = 'documents/samples';
export const WJ_getExtractionDataApiUrl = 'documents/get-extraction-data';
export const WJ_getFieldListApiUrl = 'configurations/field-list';
export const WJ_saveExtractionDataApiUrl = 'documents/save-extraction-data';
export const WJ_exportApiUrl = 'documents/export-result';
export const WJ_contactUsApiUrl = '';
export const WJ_trialDays = 15;
export const WJ_OauthApiUrl = 'oauth2/token';
export const WJ_messageMaxLength = 500;
