/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import WJ_HomePageHeader from '../../WJ_components/WJ_Layout/WJ_HomePageHeader';
import WJ_RequestDemoModal from '../../WJ_components/WJ_Modal/WJ_RequestDemoModal';
import WJ_Footer from '../../WJ_components/WJ_Layout/WJ_Footer';
import WJ_Carousel from './WJ_Carousel/WJ_Carousel';
import WJ_Section1 from './WJ_StaticContents/WJ_Section1';
import WJ_Section2 from './WJ_StaticContents/WJ_Section2';
import WJ_Section3 from './WJ_StaticContents/WJ_Section3';
import WJ_Section4 from './WJ_StaticContents/WJ_Section4';
import WJ_AuthContext from '../../WJ_utils/WJ_Auth';
import { WJ_getTokens } from '../../WJ_services/WJ_apiService/WJ_apis';

function WJ_HomePage() {
  const [open, setOpen] = useState(false);
  const WJ_handleOpen = () => setOpen(true);
  const WJ_handleClose = () => setOpen(false);
  const authCtx = useContext(WJ_AuthContext);
  const { idToken: userToken } = authCtx;
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const idToken = urlSearchParams.get('code');

  // Handle id token
  useEffect(() => {
    async function WJ_getData() {
      console.log('IN GET DATA @@@@@');
      if (idToken != null) {
        authCtx.showLoader();
        const { data } = await WJ_getTokens(idToken);
        // console.log('DATA--', data);
        if (data) {
          // eslint-disable-next-line camelcase
          const { refresh_token, id_token } = data;
          authCtx.login(id_token);
          authCtx.WJ_updateRefreshToken(refresh_token);
          sessionStorage.setItem('id_token', id_token);
          sessionStorage.setItem('refresh_token', refresh_token);
          navigate('/dashboard');
        }
        authCtx.hideLoader();
      }
      if (idToken == null && userToken == null) {
        navigate('/');
        authCtx.hideLoader();
      }
    }
    WJ_getData();
    // eslint-disable-next-line
  }, [idToken]);
  return (
    <main>
      {/* contact us popup component */}
      <WJ_RequestDemoModal open={open} onClose={WJ_handleClose} />
      <section>
        {/* WJ_Header component */}
        <WJ_HomePageHeader />
      </section>
      {/* Carousel component */}
      <WJ_Carousel />
      {/* Static content section one component */}
      <WJ_Section1 />
      {/* <div style={{ height: '100px', background: 'red' }} /> */}
      {/* Static content section two component */}
      <WJ_Section3 />
      {/* Static content section two component */}
      {/* <WJ_Section4 /> */}
      {/* Static content section three component */}
      {/* <WJ_Section3 onClickRequestDemo={WJ_handleOpen} /> */}
      {/* Footer component */}
      <WJ_Footer />
    </main>
  );
}

export default WJ_HomePage;
