/* eslint-disable camelcase */
export function WJ_getRandomNumber() {
  const array = new Uint32Array(1);
  const randomValue = window.crypto.getRandomValues(array);
  return randomValue[0];
}

export function WJ_generateRandomString(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    const randomNumber = WJ_getRandomNumber();
    const res = randomNumber * 2 ** -32;
    result += characters.charAt(Math.floor(res * charactersLength));
  }
  return result;
}

export const getFriendlyFileType = (mimeType) => {
  switch (mimeType) {
    case "application/pdf":
      return "pdf";
    case "image/jpeg":
      return "jpeg";
    case "image/png":
      return "png";
    case "image/tiff":
      return "tiff";
    default: 
    return 'invalid'
  }
};
