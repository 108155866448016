/* eslint-disable camelcase */
import { Box, Typography, Paper, Stack, Grid, Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import bannerRightImage from '../../../WJ_assets/images/banner-img.png';

function WJ_HomeCarousel() {
  return (
    <Grid container className="bannerImg">
      <Grid item md={6}>
        <Stack className="carousel" direction="column" gap="1rem">
          <Typography
            className="carousel-content-main-div-text1"
            sx={{
              color: 'black',
            }}
          >
            AI/ML DIGITIZATION PLATFORM
          </Typography>
          <Typography
            className="carousel-content-main-div-text3"
            sx={{
              color: 'black',
            }}
          >
            A single platform designed to minimize the time your organization
            spends processing documents.
          </Typography>
          <Button
            className="carousel-button"
            // variant="contained"
            // endIcon={<EastIcon />}
            // onClick={WJ_exploreButtonHandler}
            style={{ color: 'white' }}
          >
            Book a Demo&nbsp;&nbsp;&nbsp;
            <EastIcon />
          </Button>
        </Stack>
      </Grid>
      <Grid item md={6} className="carousel-content-main-div-right">
        <img src={bannerRightImage} alt="" />
      </Grid>
    </Grid>
  );
}

export default WJ_HomeCarousel;
