/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable */
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import WJ_Header from "../../WJ_components/WJ_Layout/WJ_UserPagesHeader";
import WJ_UploadDocument from "./WJ_UploadDocument/WJ_UploadDocument";
import WJ_UserDocuments from "./WJ_UserDocuments/WJ_UserDocuments";
import WJ_SampleDocuments from "./WJ_SampleDocuments/WJ_SampleDocuments";
import WJ_InactiveUser from "../../WJ_components/WJ_InactiveUser.js/WJ_InactiveUser";


function WJ_Dashboard() {
  const navigate = useNavigate();
  const [isGetUserDocuments, setIsGetUserDocuments] = useState(false);
  const [maxPagesCount, setMaxPagesCount] = useState(100);
  const [userUploadedPagesCount, setUserUploadedPagesCount] = useState(0);
  const [toastId, setToastId] = useState(null);
  const WJ_navigateToDashboard = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <WJ_Header onClick={WJ_navigateToDashboard} />
 
      <Box sx={{ background: "white.dark", marginTop: "80px" }}>
        {/* <WJ_InactiveUser /> */}
        <Box className="content-box" sx={{ background: "white.main" }}>
          {/* <Stack className="main-content" direction="row"> */}
          <Box
            className="main-content-box"
            sx={{
              borderColor: "white.dark",
            }}
          >
            {/* <WJ_UploadDocument
            onUpdateDocument={setIsGetUserDocuments}
            maxPagesCount={maxPagesCount}
            userUploadedPagesCount={userUploadedPagesCount}
            toastId={toastId}
          /> */}
            
          
              <WJ_UserDocuments
                isUpdateUserDocuments={isGetUserDocuments}
                onUpdate={setIsGetUserDocuments}
                setMaxPagesCount={setMaxPagesCount}
                setUserUploadedPagesCount={setUserUploadedPagesCount}
                setToastId={setToastId}
                onUpdateDocument={setIsGetUserDocuments}
                maxPagesCount={maxPagesCount}
                userUploadedPagesCount={userUploadedPagesCount}
                toastId={toastId}
              />
           
          </Box>
          {/* <WJ_SampleDocuments /> */}
          {/* </Stack> */}
        </Box>
      </Box>
      
    </>
  );
}

export default WJ_Dashboard;
