/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import { Typography, Button } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function WJ_LeavePageConfirmationModal(props) {
  return (
    <Dialog maxWidth="xs" open={props.open}>
      <DialogTitle sx={{ padding: '16px 185px' }}>Alert</DialogTitle>
      <DialogContent dividers sx={{ padding: '25px 24px' }}>
        <Typography>
          Your changes will be lost. Are you sure you want to leave the page?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.noClicked}
          sx={{
            background: 'linear-gradient(90deg, #ED1C24 6.94%, #F04E30 100%)',
            color: 'white !important',
          }}
        >
          No
        </Button>
        <Button
          onClick={props.yesClicked}
          sx={{
            background: 'linear-gradient(90deg, #008000 6.94%, #008000 100%)',
            color: 'white !important',
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
