/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable */
import React, { useMemo, useState } from "react";

const DataContext = React.createContext({
  docList: null,
  subTypes: {},
  updateDocList: (documentList) => {}, // Updated default value
});

export const DataContextProvider = (props) => {
  const [list, setList] = useState(null);
  const [subType, setSubType] = useState({});

  const updateDocList = (documentList) => {
    if (Array.isArray(documentList)) {
      setList(documentList);
      subTypeListExtract(documentList);
    } else {
      console.error("Invalid documentList: Expected an array");
    }
  };

  const subTypeListExtract = (docList) => {
    if (docList) {
      const documentSubTypes = {};
      docList.forEach((document) => {
        const { document_type: type, document_sub_type: subtype } = document;

        if (!documentSubTypes[type]) {
          documentSubTypes[type] = [];
        }

        if (subtype && !documentSubTypes[type].includes(subtype)) {
          documentSubTypes[type].push(subtype);
        }
      });
      setSubType(documentSubTypes);
    } else {
      setSubType({});
    }
  };

  const contextValue = useMemo(() => ({
    docList: list,
    subTypes: subType,
    updateDocList: updateDocList,
  }),[list,subType]);

  return (
    <DataContext.Provider value={contextValue}>
      {props.children}
    </DataContext.Provider>
  );
};

export default DataContext;