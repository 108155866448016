/* eslint-disable camelcase */
import { Box, Stack, Typography, Grid } from '@mui/material';
import cardIconOne from '../../../WJ_assets/images/cardIconOne.png';
import cardIconTwo from '../../../WJ_assets/images/cardIconTwo.png';
import cardIconThree from '../../../WJ_assets/images/cardIconThree.png';
import cardIconFour from '../../../WJ_assets/images/cardIconFour.png';
import solutionsImg from '../../../WJ_assets/images/solutionsImg.png';

function WJ_Section2() {
  return (
    <Box
      sx={{
        margin: '4% 10%',
        width: '100%',
        height: '100%',
      }}
    >
      <Grid container>
        <Grid className="parenttext" item md={12}>
          <Typography className="content2-box-left-text1" sx={{ color: 'red' }}>
            Case Studies
          </Typography>
          <Stack className="childtext" direction="column" gap="1rem">
            <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
              <Box direction="row" className="content2-box-left-box">
                <img src={cardIconOne} alt="" />
                <Typography className="bold-text">
                  AI-enabled extraction engine for Up to 60% automation
                </Typography>
                <Typography>Reads structured / unstructured formats</Typography>
              </Box>
              <Box direction="row" className="content2-box-left-box">
                <img src={cardIconTwo} alt="" width="42" height="49" />
                <Typography className="bold-text">
                  Serverless architecture powered by AWS
                </Typography>
                <Typography>Scale up or down without any Hassle</Typography>
              </Box>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
              <Box direction="row" className="content2-box-left-box">
                <img src={cardIconThree} alt="" />
                <Typography className="bold-text">
                  Supervised and Unsupervised Learning
                </Typography>
                <Typography>
                  Advanced analytics for enhanced accuracy
                </Typography>
              </Box>
              <Box direction="row" className="content2-box-left-box">
                <img src={cardIconFour} alt="" />
                <Typography className="bold-text">
                  In-built business rules engine for Highest accuracy
                </Typography>
                <Typography>
                  Custom validations through database or business rules
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        {/* <Grid item md={5}>
          <img
            src={solutionsImg}
            alt="solutionsImg"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default WJ_Section2;
