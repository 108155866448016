import React from 'react';

const BoundingBox = ({ boundingBox, imgHeight, imgWidth, activeIndex, children }) => {
    // console.log(activeIndex)
    return (
        <div
            className={` ${activeIndex != null ? 'border-2 border-blue-200 hover:border-blue-600 z-10' : ''}`}

            style={{
                position: 'absolute',
                left: `${boundingBox.Left * imgWidth}px`,
                top: `${boundingBox.Top * imgHeight}px`,
                width: `${boundingBox.Width * imgWidth}px`,
                height: `${boundingBox.Height * imgHeight}px`,

                
            }}
        >
            {children}
        </div>
    );
};

export default BoundingBox;