/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable spaced-comment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable */
import { useEffect, useContext, useState, useRef, React } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Box, Typography, LinearProgress, Stack, Divider, Drawer, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Table, Switch, Space, Input, Button, DatePicker } from "antd";
import { styled } from "@mui/material/styles";
import {
  WJ_getUserDocuments,
  WJ_getUserDetails,
  WJ_getConfigurations,
  WJ_getFieldListApi,
  WJ_getSampleDocuments,
} from "../../../WJ_services/WJ_apiService/WJ_apis";
import WJ_AuthContext from "../../../WJ_utils/WJ_Auth";
import DataContext from "../../../WJ_utils/WJ_docList";
import { WJ_getDocumentsCallFrequencyInMilliseconds } from "../../../WJ_utils/WJ_Constants";
import WJ_Export from "../../../WJ_components/WJ_Export/WJ_Export";
import { colors } from "../../../WJ_assets/css/color";
import View from "../../../WJ_assets/images/view.png";
import Download from "../../../WJ_assets/images/download.png";
import UploadDoc from "../../../WJ_assets/images/UploadDoc.png";
import Dropdown from "../../../WJ_assets/images/Dropdown.png";
import DownloadBlue from "../../../WJ_assets/images/DownloadBlue.png";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { format } from "date-fns";
const { RangePicker } = DatePicker;
import { SearchOutlined } from "@mui/icons-material";
// import { WavyBackground } from "../../../components/wavy-background.tsx";

const WJ_CustomNoRowsOverlay = () => {
  return <Typography className="no-rows">No document uploaded yet.</Typography>;
};
const drawerWidth = 240;
const Root = styled("div")({
  display: "flex",
});

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

// const SideBar = styled(Drawer)(({ theme }) => ({
//   // width: drawerWidth,
//   flexShrink: 0,
//   overflow: 'auto',
//   '& .MuiDrawer-paper': {
//     // width: drawerWidth,
//     boxSizing: 'border-box',
//   },
// }));
const SideBar = styled(Drawer)(({ theme, open }) => ({
  flexShrink: 0,
  overflow: "auto",
  // width: open ? '500px' : '0',
  transition: "width 0.3s ease-in-out", // Add a smooth transition for better user experience
  "& .MuiDrawer-paper": {
    marginTop: "65px",
    boxSizing: "border-box",
    transition: "width 0.3s ease-in-out", // Match the transition here as well
  },
  // '& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper': {
  //   marginTop:"65px",
  // },
  [theme.breakpoints.down("md")]: {
    "& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper": {
      width: open ? "300px" : "0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper": {
      width: open ? "250px" : "0",
    },
  },
}));
/// /// styles ////////
const useStyles = makeStyles(() => ({
  customTypography: {
    color: "#525252",
    fontSize: "12px",
  },
  customTypographyBoldText: {
    fontWeight: "bold",
    // backgroundColor: 'red',
  },
  customDisplayFlexRow: {
    display: "flex",
    flexDirection: "row",
  },
  customDisplayFlexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  customBorderRadius: {
    borderRadius: "6px",
  },
  customStyleForStatus: {
    cursor: "pointer",
    padding: "5px",
  },
}));
function WJ_UserDocuments(props) {
  const authCtx = useContext(WJ_AuthContext);
  const { updateDocList } = useContext(DataContext);
  const userToken = authCtx.idToken;
  const [rows, setRows] = useState([]);
  const [uploadedPages, setUploadedCount] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [updatedRowsCount, setUpdatedRowsCount] = useState(rows.length);
  const [prevUploadedPagesCount, setPrevUploadedPagesCount] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const toastId = useRef(null);
  const toastIdupload = useRef(null);
  const [maxFileSize, setMaxFileSize] = useState(0);
  const [fileSizeString, setFileSizeString] = useState("1MB");
  const [documents, setDocuments] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [documentTypes, setDocumentTypes] = useState({});
  const [pageNumber, setpageNumber] = useState(1);

  const { userUploadedPagesCount, maxPagesCount, toastIdupload: userDocumentToastId } = { ...props };

  /// ///////// updated //////////////
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  // Convert file size string into bytes
  const WJ_convertToBytes = (size, type) => {
    const types = ["B", "KB", "MB", "GB", "TB"];
    const key = types.indexOf(type.toUpperCase());
    if (typeof key !== "boolean") {
      return size * 1024 ** key;
    }
    return 0;
  };
  // console.log(rows);
  const columns = [
    {
      title: "Document Name",
      sorter: (record1, record2) => record1.name.localeCompare(record2.name),
      width: open ? 100 : "",
      showSorterTooltip: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ display: "grid", gridTemplateRows: "auto auto", gap: "8px", padding: "8px" }}>
          <Input
            placeholder="Search Document Name"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 4 }}
            />
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
      render: (record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontWeight: "600",
          }}
        >
          <span
            data-label="Country"
            className="documwntRowData fontstyle"
            onClick={() => {
              if (record.status.toLowerCase() === "completed") {
                WJ_extractionDocumentNavigation(record);
              }
            }}
          >
            {record.name}
          </span>
        </div>
      ),
    },
    {
      title: "Document Type",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Document Type`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined size={22} />} size="small" style={{ width: 90 }}>
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90, marginLeft: 4 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.document_type.toLowerCase().includes(value.toLowerCase()),
      sorter: (record1, record2) => {
        return record1.created_date > record2.created_date;
      },
      defaultSortOrder: ["descend"],
      showSorterTooltip: true,
      render: (record) => (
        <span className="documwntRowData fontstyle" data-label="City" style={{ fontWeight: "600" }}>
          {record.document_type}
          {/* {format(new Date(record.created_date), 'dd/MM/yyyy HH:mm:ss')} */}
        </span>
      ),
    },
    {
      title: "Created at",
      sorter: (record1, record2) => new Date(record1.created_date) - new Date(record2.created_date),
      defaultSortOrder: "descend",
      showSorterTooltip: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            onChange={(dates) => {
              setSelectedKeys(dates ? [dates] : []);
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"  
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        if (!value || value.length === 0) {
          return true;
        }
        console.log(value);
        const [start, end] = value;
        console.log("start", start.format());
        const recordDate = moment(record.created_date).startOf("day");
        const startDate = moment(start).startOf("day");
        const endDate = moment(end).startOf("day");

        console.log("Record date:", recordDate.format("YYYY-MM-DD"));
        console.log("Start date:", startDate.format("YYYY-MM-DD"));
        console.log("End date:", endDate.format("YYYY-MM-DD"));

        const condition = recordDate.isBetween(start.format(), end.format(), null, "[]");
        console.log("Condition:", condition);

        return condition;
      },
      showSorterTooltip: true,
      render: (record) => (
        <span className="documwntRowData fontstyle" data-label="City" style={{ fontWeight: "600" }}>
          {WJ_updateReceivedAt(record.created_date)}
          {/* {format(new Date(record.created_date), 'dd/MM/yyyy HH:mm:ss')} */}
        </span>
      ),
    },
    {
      title: "Status",
      sorter: (record1, record2) => {
        return record1.status > record2.status;
      },
      filters: [
        { text: "Pending", value: "pending" },
        { text: "failed", value: "failed" },
        { text: "Completed", value: "completed" },
      ],
      onFilter: (value, record) => record.status.toLowerCase() == value.toLowerCase(),
      render: (record) => (
        <span
          className={clsx(classes.customBorderRadius, classes.customStyleForStatus, "fontstyle")}
          style={{
            backgroundColor:
              record.status.toLowerCase() === "completed"
                ? colors.completed
                : record.status.toLowerCase() === "extracted"
                ? colors.extracted
                : record.status.toLowerCase() === "failed"
                ? colors.failed
                : record.status.toLowerCase() === "in progress"
                ? colors.inprocess
                : record.status.toLowerCase() === "pending"
                ? colors.inprocess
                : "",
            color: "#1C1D1E",
            fontWeight: "600",
          }}
        >
          {record.status}
        </span>
      ),
    },
    {
      title: "View",
      render: (record) =>
        record?.status == "Completed" && (
          <img
            title={
              record.status.toLowerCase() === "pending" || record.status.toLowerCase() === "In progress"
                ? "once document completed you are able to view document"
                : ""
            }
            src={View}
            alt=""
            style={{
              width: "30px",
              height: "30px",
              cursor: "pointer",
              // pointerEvents: record.status === 'Completed' ? 'auto' : 'none',
            }}
            onClick={() => {
              WJ_extractionDocumentNavigation(record);
            }}
          />
        ),
    },
    // {
    //   title: "View",
    //   render: (record) =>
    //     record?.llmstatus == "Completed" && (
    //       <img
    //         title={
    //           record.status.toLowerCase() === "pending" ||
    //           record.status.toLowerCase() === "In progress"
    //             ? "once document completed you are able to view document"
    //             : ""
    //         }
    //         src={View}
    //         alt=""
    //         style={{
    //           width: "30px",
    //           height: "30px",
    //           cursor: "pointer",
    //           // pointerEvents: record.status === 'Completed' ? 'auto' : 'none',
    //         }}
    //         onClick={() => {
    //           WJ_Gemini_Response(record);
    //         }}
    //       />
    //     ),
    // },
    // {
    //   title: 'Download',
    //   render: () => (
    //     <img
    //       src={Download}
    //       alt=""
    //       style={{
    //         width: '30px',
    //         height: '30px',
    //         cursor: 'pointer',
    //       }}
    //     />
    //   ),
    // },
  ];

  /// ////////////////
  const WJ_usePrevious = (value) => {
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
      setPrevUploadedPagesCount(ref.current);
    }, [value]); // Only re-run if value changes
  };

  WJ_usePrevious(uploadedPages);
  const navigate = useNavigate();
  const WJ_getDocumentType = (value) => {
    let val;
    switch (value) {
      case "invoice":
        val = "Commercial Invoice";
        break;
      case "si":
        val = "Shipping Instructions";
        break;
      case "bol":
        val = "Bill of Lading";
        break;
      default:
        val = "-";
    }
    return val;
  };
  const WJ_getHeight = (row) => {
    let val;
    if (row.length === 0) return "150px";
    switch (pageSize) {
      case 10:
        val = "631px";
        break;
      case 20:
        val = "1100px";
        break;

      default:
        val = "371px";
    }
    return val;
  };
  const WJ_extractionDocumentNavigation = (params) => {
    if (params.status !== "pending" && params.status !== "failed" && params.status !== "in-progress") {
      const link = `/document/${params.document_id}/${params.document_type}/${encodeURIComponent(params.name)}`;
      navigate(link, {
        state: {
          docStatus: params.status,
        },
      });
    }
  };
  const WJ_Gemini_Response = (params) => {
    if (params.llmstatus != "failed" && params.llmstatus != "pending") {
      const link = `/LlmResponse/${params.document_id}/${params.document_type}/${encodeURIComponent(params.name)}`;
      navigate(link, {
        state: {
          docStatus: params.status,
        },
      });
    }
  };
  // Return local time using UTC time
  const WJ_updateReceivedAt = (params) => {
    let dateTime = "-";
    // if (params?.row?.created_date) {
    //   const date = new Date(`${params.row.created_date}+00:00`);
    //   if (date) {
    //     dateTime = `${date.getFullYear()}-${
    //       date.getMonth() + 1
    //     }-${date.getDate()} ${String(date.getHours()).padStart(
    //       2,
    //       '0'
    //     )}:${String(date.getMinutes()).padStart(2, '0')}:${String(
    //       date.getSeconds()
    //     ).padStart(2, '0')}`;
    //   }
    // }
    if (params) {
      const date = new Date(`${params}+00:00`);
      if (date) {
        dateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${String(date.getHours()).padStart(
          2,
          "0"
        )}:${String(date.getMinutes()).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}`;
      }
    }
    return dateTime;
  };
  // Define table columns
  const WJ_tableColumns = [
    {
      field: "name",
      headerName: "WJ_Document Name",
      width: 250,
      headerClassName: "super-app-theme--header",
      renderCell: (params) =>
        params.value &&
        (params.row.status.toLowerCase() === "completed" || params.row.status.toLowerCase() === "extracted") ? (
          <NavLink
            to={`/document/${params.row.document_id}/${params.row.document_type}/${encodeURIComponent(
              params.row.name
            )}`}
            style={{ textDecoration: "none" }}
            title={params.value}
            state={{ docStatus: params.row.status ?? "" }}
          >
            {params.value !== "" && params.value.length > 25
              ? `${params.value.substring(0, 25)}...`
              : params.value ?? "-"}
          </NavLink>
        ) : (
          <span title={params.value}>
            {params.value !== "" && params.value.length > 25
              ? `${params.value.substring(0, 25)}...`
              : params.value ?? "-"}
          </span>
        ),
    },
    {
      field: "document_type",
      headerName: "WJ_Document Type",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: ({ value }) => WJ_getDocumentType(value),
    },
    {
      field: "created_date",
      headerName: "Received At",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: WJ_updateReceivedAt,
    },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      headerClassName: "super-app-theme--header",
      renderCell: (params) =>
        params.value != null && params.value.toLowerCase() === "in-progress" ? (
          <Stack gap="0.2rem">
            <span
              style={{
                padding: "7px 10px",
                borderRadius: "7px",
                backgroundColor:
                  params.value === "completed"
                    ? colors.completed
                    : params.value === "extracted"
                    ? colors.extracted
                    : params.value === "failed"
                    ? colors.failed
                    : params.value === "in-progress"
                    ? colors.inprocess
                    : params.value === "pending"
                    ? colors.inprocess
                    : "",
                color: "#1C1D1E",
                fontWeight: "600",
              }}
            >
              {params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            </span>
            {/* <CircularProgress
              size="1.5rem"
              sx={{ color: '#0072E5', marginLeft: '10px' }}
            />#0072e566 */}
            <LinearProgress sx={{ color: "#0072E5", backgroundColor: "#deaaac" }} />
          </Stack>
        ) : (
          <span
            style={{
              padding: "7px 10px",
              borderRadius: "7px",
              backgroundColor:
                params.value === "completed"
                  ? colors.completed
                  : params.value === "extracted"
                  ? colors.extracted
                  : params.value === "failed"
                  ? colors.failed
                  : params.value === "in-progress"
                  ? colors.inprocess
                  : params.value === "pending"
                  ? colors.inprocess
                  : "",
              color: "#1C1D1E",
              fontWeight: "600",
            }}
          >
            {params.value ? params.value.charAt(0).toUpperCase() + params.value.slice(1) : "-"}
          </span>
        ),
    },
    {
      field: "view",
      headerName: "View",
      // width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <img
          src={View}
          alt=""
          style={{
            width: "30px",
            height: "30px",
            cursor: "pointer",
            pointerEvents: params.status === "completed" || params.status === "extracted" ? "auto" : "none",
          }}
          onClick={() => {
            WJ_extractionDocumentNavigation(params);
          }}
        />
      ),
    },
    {
      field: "download",
      headerName: "Download",
      // width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <img
          src={Download}
          alt=""
          style={{
            width: "30px",
            height: "30px",
            cursor: "pointer",
          }}
        />
      ),
    },
  ];

  const WJ_handleSuccess = (response) => {
    if (response?.data?.data.length > 0) {
      const newRows = response.data.data ? response.data.data : [];
      const newDocument = JSON.parse(localStorage.getItem("newUploadedDocument"));
      setRows(newRows);

      if (newDocument && Object.keys(newDocument) > 0) {
        const firstDoc = response.data?.data[0] ?? {};
        if (
          newDocument.name !== firstDoc.name ||
          newDocument.document_type !== firstDoc.document_type ||
          newDocument.id.length !== firstDoc.id.length
        ) {
          setRows([newDocument, newRows]);
        }
      }
    } else {
      setRows(response.data.data ? response.data.data : []);
    }
  };
  const WJ_handleError = (response) => {
    if (response?.data && response.data.status && response.data.status === 201) {
      setRows([]);
      authCtx.inactivateUser();
    } else {
      setRows([]);
    }
  };
  // Define get user documents api function
  const WJ_getUserDocumentsApiHandler = async (isRefresh = false) => {
    if (!isRefresh) {
      authCtx.showLoader();
    }
    const response = await WJ_getUserDocuments({ id_Token: authCtx.idToken });
    if (response?.data?.status === 200) {
      const pages = response.data.data ? response.data.data.reduce((total, obj) => obj.page_count + total, 0) : 0;
      setTotalPages(pages);
      // const l11 = [12]
      // console.log("response data:",response.data.data)
      updateDocList(response.data.data);

      // console.log("docList:", docList)
      authCtx.setTotalPages(pages);
      // console.log("authCtx", authCtx);
      WJ_handleSuccess(response);
    } else {
      WJ_handleError(response);
    }
  };
  // Define get user details api function
  const WJ_getUserDetailsApiHandler = async () => {
    const response = await WJ_getUserDetails({ id_Token: authCtx.idToken });
    if (response.data && response.data?.data) {
      const userData = response.data?.data ?? {};
      setUploadedCount(userData.uploaded_pages_count ?? 0);
      props.setUserUploadedPagesCount(userData.uploaded_pages_count ?? 0);
      props.setMaxPagesCount(userData.page_limit_per_week ?? 0);
      setMaxPages(userData.page_limit_per_week ?? 0);
      userData.status = 0;
      if (userData.status) {
        authCtx.inactivateUser();
      }
      let totalDays;
      const expiryDate = new Date(userData.expiry_date);
      if (expiryDate) {
        const udpatedExpiryDate = new Date(
          `${expiryDate.getMonth() + 1}/${expiryDate.getDate()}/${expiryDate.getFullYear()} 00:00:00`
        );
        const currentDate = new Date();
        const updatedCurrentDate = new Date(
          `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()} 00:00:00`
        );
        const difference = udpatedExpiryDate.getTime() - updatedCurrentDate.getTime();
        totalDays = difference / (1000 * 3600 * 24);
      } else {
        totalDays = 0;
      }
      authCtx.setRemainingTrialDays(totalDays >= 0 ? totalDays : 0);
    }
  };
  // Call api to get fields to extract
  const WJ_getFieldListApiHandlerr = async (type) => {
    const fieldsResponse = await WJ_getFieldListApi({ type });

    if (
      fieldsResponse.status &&
      fieldsResponse.status === 200 &&
      fieldsResponse.data &&
      Array.isArray(fieldsResponse.data)
    ) {
      const keyValueData = fieldsResponse.data ?? [];
      const localFields = JSON.parse(localStorage.getItem("docTypeFields"));
      if (localFields) {
        localFields[type] = keyValueData;
        localStorage.setItem("docTypeFields", JSON.stringify(localFields));
      } else {
        const newFields = {};
        newFields[type] = keyValueData;
        localStorage.setItem("docTypeFields", JSON.stringify(newFields));
      }
    }
  };
  // Define get configurations api function
  const WJ_getConfigurationsApiHandler = async () => {
    const configurationResponse = await WJ_getConfigurations();
    if (configurationResponse.status && configurationResponse.status === 200) {
      localStorage.setItem("docConfigurations", JSON.stringify(configurationResponse.data ?? {}));
      if (configurationResponse?.data?.document_types) {
        for (const [key] of Object.entries(configurationResponse?.data?.document_types)) {
          WJ_getFieldListApiHandlerr(key);
        }
      }
    }
  };
  // Call get user documents api after component loaded
  useEffect(() => {
    if (userToken != null) {
      WJ_getUserDocumentsApiHandler();
      WJ_getUserDetailsApiHandler();
      WJ_getConfigurationsApiHandler();
      WJ_getSampleDocumentsApiHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, react/prop-types, react/destructuring-assignment
  }, [userToken]);
  // Handle in-progress document status
  useEffect(() => {
    let updateDocumentTrigger;
    const WJ_updateDocumentList = () => {
      const arrPendingDocs = rows.filter(
        (doc) => doc.status === "In progress" || doc.status.toLowerCase() === "pending"
      );
      if (arrPendingDocs.length > 0 || rows.length + 1 === updatedRowsCount) {
        WJ_getUserDocumentsApiHandler(true);
      }
    };
    if (authCtx) {
      updateDocumentTrigger = window.setInterval(
        WJ_updateDocumentList,
        WJ_getDocumentsCallFrequencyInMilliseconds ?? 30000
      );
    }

    return () => {
      window.clearInterval(updateDocumentTrigger);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps, react/prop-types, react/destructuring-assignment
  }, [authCtx, rows, updatedRowsCount]);

  // Handle toast message in case of weekly upload limit exceeds maximum value
  useEffect(() => {
    if (uploadedPages >= maxPages && prevUploadedPagesCount !== 0 && prevUploadedPagesCount !== undefined) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("You have reached the weekly document upload limit");
        props.setToastId(toastId.current);
      }
    }
    // eslint-disable-next-line
  }, [uploadedPages]);

  // Handle document upload to call get user documents api function
  useEffect(() => {
    if (props.isUpdateUserDocuments) {
      setActivePage(0);
      const newUploadedDoc = localStorage.getItem("newUploadedDocument");
      if (newUploadedDoc) {
        setRows([JSON.parse(newUploadedDoc), ...rows]);
      }
      setUpdatedRowsCount(rows.length + 1);
      props.onUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, react/prop-types, react/destructuring-assignment
  }, [props.isUpdateUserDocuments]);
  const WJ_pageSizeHandler = (newPageSize) => {
    setPageSize(newPageSize);
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 300);
  };
  // Handle call for updating used pages
  useEffect(() => {
    if (userToken != null) {
      WJ_getUserDetailsApiHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, react/prop-types, react/destructuring-assignment
  }, [totalPages]);
  const height = WJ_getHeight(rows);

  /// /////////update /////////

  // Define get sample documents api function
  const WJ_getSampleDocumentsApiHandler = async () => {
    authCtx.showLoader();
    const response = await WJ_getSampleDocuments({ id_Token: authCtx.idToken });
    if (response?.data?.status === 200) {
      setDocuments(response.data.data ?? []);
    }
    authCtx.hideLoader();
  };

  // Call sample documents api function after component loaded
  ///////////////////// upload button ////////
  const WJ_handleUploadDocumentButtonClick = () => {
    // console.log("check123----", userUploadedPagesCount, maxPagesCount);
    if (userUploadedPagesCount >= 100) {
      if (!toast.isActive(toastIdupload.current) && !toast.isActive(userDocumentToastId)) {
        toastIdupload.current = toast.error("You have reached the weekly document upload limit");
      }
    } else {
      navigate("/uploaddocument");
    }
  };
  // Define get configurations api function
  const WJ_getConfigurationsApiHandlerupload = async () => {
    authCtx.showLoader();
    const configurationResponse = await WJ_getConfigurations();
    if (configurationResponse.status && configurationResponse.status === 200) {
      setFileTypes(configurationResponse.data.upload_file_type ?? []);
      setDocumentTypes(configurationResponse.data.document_types ?? {});
      if (configurationResponse.data.document_upload_size) {
        setFileSizeString(configurationResponse.data.document_upload_size);
        const last2characters = configurationResponse.data.document_upload_size.slice(-2).trim();
        const removedLast2 = configurationResponse.data.document_upload_size.slice(0, -2).trim();
        setMaxFileSize(WJ_convertToBytes(removedLast2, last2characters));
      }
    } else {
      setFileTypes([]);
      setDocumentTypes({});
      setMaxFileSize(0);
      setFileSizeString("");
      setOpen(false);
      toast.error("Something went wrong, Please try again later.");
    }
    authCtx.hideLoader();
  };
  ///////////////////// upload button end////////
  const handleDownload = (url, filename) => {
    // Create a new link
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.target = "_blank";

    // Append to the DOM
    document.body.appendChild(link);

    // Programmatically click the link to trigger download
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  };
  return (
    <>
      {/* <Box className="user-documents">
        <Stack direction="row" gap="1rem">
          <Typography
            className="text"
            sx={{
              color: 'primary.main',
            }}
          >
            Uploaded Documents
          </Typography>
          <Typography className="text-count">
            {uploadedPages} page(s) of the weekly {maxPages} Free Extraction
            pages used.
          </Typography>
        </Stack>
        <div
          className="documents"
          style={{
            height,
          }}
        >
          <DataGrid
            rows={rows}
            columns={WJ_tableColumns}
            pageSize={pageSize}
            onPageSizeChange={WJ_pageSizeHandler}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            page={activePage}
            onPageChange={(newPage) => setActivePage(newPage)}
            onRowClick={WJ_extractionDocumentNavigation}
            components={{
              NoRowsOverlay: WJ_CustomNoRowsOverlay,
            }}
            className="headers"
            sx={{
              '& .brown': {
                color: 'brown',
              },
              '& .orange': {
                color: 'orange',
              },
              '& .green': {
                color: 'green',
              },
              '& .red': {
                color: 'red',
              },
              zIndex: 0,
            }}
            getCellClassName={(params) => {
              if (
                params.field === 'status' &&
                params.value.toLowerCase() === 'in-progress'
              ) {
                return 'brown';
              }
              if (
                params.field === 'status' &&
                params.value.toLowerCase() === 'extracted'
              ) {
                return 'orange';
              }
              if (
                params.field === 'status' &&
                params.value.toLowerCase() === 'completed'
              ) {
                return 'green';
              }
              if (
                params.field === 'status' &&
                params.value.toLowerCase() === '  '
              ) {
                return 'red';
              }
              return '';
            }}
          />
        </div>
      </Box> */}
      <Box sx={{ backgroundColor: colors.appBackgroundLightBlue }} className="z-10">
        <Root
          sx={{
            padding: "0px 30px",
          }}
        >
          <SideBar
            className="sidebar"
            sx={{
              width: {
                lg: open ? "500px" : "0",
                // md: open ? '300px' : '0',
                // sm: open ? '280px' : '0',
              },
              display: { xs: "block", sm: "block" },
              height: {
                lg: open ? "90vh" : "0",
                sm: open ? "500px" : "0",
              },
              top: "64px !important",
              overflowY: "auto",
              // '@media (max-width: 1201)': {
              //   top: '64px !important',
              //   width: open ?'350px !important':'',
              //   backgroundColor:'red'
              // },
              // '@media (max-width: 894px)': {
              //   top: '64px !important',
              //   width: open ?'350px !important':'',
              // },
            }}
            variant="persistent"
            open={open}
          >
            <Box
              className={classes.customDisplayFlexColumn}
              sx={{
                padding: "20px 40px",
                gap: "30px",
                // justifyContent: 'center',
              }}
            >
              <Typography className={classes.customTypographyBoldText} sx={{ fontWeight: 700 }}>
                Annual TAX Invoice
              </Typography>
              <Box
                className={classes.customDisplayFlexColumn}
                sx={{
                  // margin: '0px 50px',
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <Box>
                  {documents.length === 0 && <span>No document found.</span>}
                  {documents.map((document, index) => (
                    <Box className="document" key={index}>
                      <Box style={{ overflow: "auto" }}>
                        {" "}
                        {/* Allow scrolling if content is larger than container */}
                        <Document file={document.url}>
                          {/* You can use the scale prop to fit the page to the desired size */}
                          <Page pageNumber={pageNumber} scale={0.7} />
                        </Document>
                      </Box>
                      <Typography className="document-name">{document.name}</Typography>
                      <Box
                        className={classes.customDisplayFlexRow}
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                        onClick={() => handleDownload(document.url, document.name)}
                      >
                        <Typography
                          className={classes.customTypographyBoldText}
                          style={{
                            cursor: "pointer",
                          }}
                          sx={{ color: colors.navyBlue, gap: "10px" }}
                        >
                          Download
                        </Typography>
                        <img
                          src={DownloadBlue}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
                {/* <Box
                  className={classes.customBorderRadius}
                  sx={{
                    backgroundColor: '#F4F4F4',
                    padding: '10px',
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%',
                      }}
                    >
                      <Typography className={classes.customTypography}>
                        Document Type
                      </Typography>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                        Sample Document
                      </Typography>
                      <Grid
                        container
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid>
                          <Typography className={classes.customTypography}>
                            Received at
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '14px' }}
                          >
                            22-09-2022
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography className={classes.customTypography}>
                            Modified at
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '14px' }}
                          >
                            22-09-2022
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography className={classes.customTypography}>
                            Modified by
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '14px' }}
                          >
                            22-09-2022
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box> */}
              </Box>
            </Box>
          </SideBar>
          <Main>
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Grid item sx={{ display: "flex", gap: "10px" }}>
                  <Typography className={classes.customTypographyBoldText} sx={{ fontWeight: 800, fontSize: "25px" }}>
                    Document
                  </Typography>
                  {/* <img
                    src={Dropdown}
                    alt=""
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  /> */}
                </Grid>
                <Grid item sx={{ display: "flex", gap: "30px" }}>
                  <Grid sx={{ display: "flex", gap: "10px" }}>
                    <Typography variant="">Show Sample Document</Typography>
                    <Space direction="vertical">
                      <Switch
                        className="switchButton"
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        // defaultChecked
                        onClick={handleToggle}
                      />
                    </Space>
                  </Grid>
                  <Grid
                    sx={{ display: "flex", gap: "10px", cursor: "pointer" }}
                    onClick={WJ_handleUploadDocumentButtonClick}
                  >
                    <Typography
                      className={classes.customTypographyBoldText}
                      sx={{ color: colors.navyBlue }}
                      disabled={!authCtx.active}
                    >
                      Upload Document
                    </Typography>
                    <img
                      src={UploadDoc}
                      alt=""
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Table
                className="DocumentTable"
                columns={columns}
                dataSource={rows}
                responsive={true}
                bordered={true}
                showSorterTooltip={true}
              />
              {/* <DataGrid
                rows={rows}
                columns={WJ_tableColumns}
                getRowId={(row) => row.document_id}
                pageSize={pageSize}
                onPageSizeChange={WJ_pageSizeHandler}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
                // getRowSpacing={5}
                page={activePage}
                onPageChange={(newPage) => setActivePage(newPage)}
                onRowClick={WJ_extractionDocumentNavigation}
                components={{
                  NoRowsOverlay: WJ_CustomNoRowsOverlay,
                }}
                className="headers"
                sx={{
                  '&>.MuiDataGrid-main': {
                    '&>.MuiDataGrid-columnHeaders': {
                      borderBottom: 'none',
                    },

                    '& div div div div >.MuiDataGrid-cell': {
                      borderBottom: 'none',
                    },
                  },
                  '& .brown': {
                    color: 'brown',
                  },
                  '& .orange': {
                    color: 'orange',
                  },
                  '& .green': {
                    color: 'green',
                  },
                  '& .red': {
                    color: 'red',
                  },
                  zIndex: 0,
                }}
                getCellClassName={(params) => {
                  if (
                    params.field === 'status' &&
                    params.value.toLowerCase() === 'in-progress'
                  ) {
                    return 'brown';
                  }
                  if (
                    params.field === 'status' &&
                    params.value.toLowerCase() === 'extracted'
                  ) {
                    return 'orange';
                  }
                  if (
                    params.field === 'status' &&
                    params.value.toLowerCase() === 'completed'
                  ) {
                    return 'green';
                  }
                  if (
                    params.field === 'status' &&
                    params.value.toLowerCase() === 'failed'
                  ) {
                    return 'red';
                  }
                  return '';
                }}
              /> */}
              <Divider orientation="horizontal" />
              <Typography
                sx={{
                  margin: "10px 45px",
                  textAlign: "right",
                  // marginLeft: "auto",
                  fontSize: "14px",
                }}
              >
                Copyright © 2024 Winjit Inc | All Rights Reserved | Powered by Winjit Technologies
              </Typography>
            </Box>
          </Main>
        </Root>
      </Box>
    </>
    // </WavyBackground>
  );
}

export default WJ_UserDocuments;
