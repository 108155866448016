/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CardContent,
  Container,
  CardHeader,
  Card,
  Collapse,
  Divider,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { React, useState } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import WJ_HeaderNew from '../../WJ_components/WJ_Layout/WJ_HeaderNew';
import backarrow from '../../WJ_assets/images/backarrow.png';
import DownloadBlue from '../../WJ_assets/images/DownloadBlue.png';
import tick from '../../WJ_assets/images/tick.png';
import { colors } from '../../WJ_assets/css/color';
import WJ_Header from '../../WJ_components/WJ_Layout/WJ_UserPagesHeader';
/// /// styles ////////
const useStyles = makeStyles(() => ({
  shadow: {
    borderRight: '10px',
  },
  customDisplayFlexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  customDisplayFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  customBorderRadius: {
    borderRadius: '6px',
  },
  // customTypographyBoldText: {
  //   fontWeight: 'bold',
  //   // backgroundColor: 'red',
  // },
}));
/// /// styles end////////
function createData(Sno, Item, Qty, Price, Amount) {
  return { Sno, Item, Qty, Price, Amount };
}
const rows = [
  createData('1', 'Calender app', 6, '$234', '$3652'),
  createData('2', 'chat App', 4, '$22', '$3652'),
  createData('3', 'Larave App', 5, '$633', '$3652'),
  createData('4', 'Backend App', 5, '$992', '$3652'),
];
function WJ_DocumentDetails() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <WJ_Header />
      <Grid
        container
        className={classes.shadow}
        sx={{ boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.2)' }}
      >
        <Grid
          item
          lg={4}
          xs={12}
          md={12}
          sm={12}
          className="customDisplayFlexColumn"
        >
          <Box
            className="customDisplayFlexColumn"
            sx={{ margin: '20px 60px' }}
            width="100%"
            gap="20px"
          >
            <form>
              <TextField
                sx={{ width: '100%' }}
                label="Search keyword here"
                variant="outlined"
              />
            </form>
            <Box
              className={clsx(
                classes.customBorderRadius,
                classes.customDisplayFlexColumn
              )}
              sx={{
                backgroundColor: open ? colors.grey : 'none',
                padding: '10px',
              }}
            >
              <Card
                sx={{
                  // minWidth: 300,
                  border: 'none',
                  // height: '50px',
                }}
              >
                <CardHeader
                  title="Basic Info"
                  action={
                    <IconButton
                      onClick={() => setOpen(!open)}
                      aria-label="expand"
                      size="small"
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  }
                />
                <div
                  style={{
                    backgroundColor: 'rgba(211,211,211,0.4)',
                  }}
                >
                  <Collapse
                    className="collapse"
                    in={open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <CardContent>
                      <Box
                        className="customDisplayFlexRow"
                        sx={{ justifyContent: 'space-between' }}
                      >
                        <Box className="customDisplayFlexRow" gap="10px">
                          <img
                            src={tick}
                            alt=""
                            style={{
                              width: '20px',
                              height: '20px',
                              cursor: 'pointer',
                            }}
                          />

                          <Typography>Pro Number</Typography>
                        </Box>
                        <Box className="customDisplayFlexRow">
                          <Typography>8388837378</Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Collapse>
                </div>
              </Card>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          lg={8}
          xs={12}
          md={12}
          sm={12}
          sx={{
            backgroundColor: colors.appBackgroundLightBlue,
            height: '100%',
          }}
        >
          <Box
            className="customDisplayFlexRow"
            sx={{
              justifyContent: 'space-between',
              padding: '20px 30px',
              alignItems: 'center',
            }}
          >
            <Box className="customDisplayFlexRow" gap="10px">
              <img
                src={backarrow}
                alt=""
                style={{
                  width: '30px',
                  height: '30px',
                  cursor: 'pointer',
                }}
              />
              <Box>
                <Typography sx={{ fontWeight: 600 }}>
                  Annual TAX Invoice
                </Typography>
                <Typography>Sample Document</Typography>
              </Box>
            </Box>
            <Box className="customDisplayFlexRow" gap="40px">
              <Box
                className="customDisplayFlexRow"
                gap="10px"
                alignItems="center"
              >
                <Typography sx={{ fontWeight: 600, color: colors.navyBlue }}>
                  Export results
                </Typography>
                <img
                  src={DownloadBlue}
                  alt=""
                  style={{
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                  }}
                />
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: colors.navyBlue }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            className={clsx(
              classes.customBorderRadius,
              classes.customDisplayFlexColumn
            )}
            gap="30px"
            sx={{
              margin: '10px 45px',
              backgroundColor: '#FFFFFF',
              padding: '20px',
              overflowY: 'auto',
              height: '80vh',
            }}
          >
            <Box
              className="customDisplayFlexRow"
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Box className="customDisplayFlexColumn" gap="20px">
                <Box
                  className="customDisplayFlexRow"
                  sx={{ alignItems: 'center' }}
                >
                  <img
                    // src={backarrow}
                    alt=""
                    style={{
                      width: '30px',
                      height: '30px',
                      cursor: 'pointer',
                    }}
                  />
                  <Typography>Cark Inc.</Typography>
                </Box>
                <Box className="customDisplayFlexColumn">
                  <Typography>XYA Delta Street</Typography>
                  <Typography>cccwec@gmail.com</Typography>
                  <Typography>837662882999</Typography>
                </Box>
              </Box>
              <Box className="customDisplayFlexColumn" gap="20px">
                <Box
                  className="customDisplayFlexRow"
                  sx={{ alignContent: 'center' }}
                >
                  <Typography>Invoice :</Typography>
                  <Typography>#0001</Typography>
                </Box>
                <Box className="customDisplayFlexColumn">
                  <Box
                    className="customDisplayFlexRow"
                    sx={{ alignContent: 'center' }}
                  >
                    <Typography>Invoice Date :</Typography>
                    <Typography>20 Aug 2020</Typography>
                  </Box>
                  <Box
                    className="customDisplayFlexRow"
                    sx={{ alignContent: 'center' }}
                  >
                    <Typography>Due Date :</Typography>
                    <Typography>26 Aug 2020</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider orientation="horizontal" />
            <Box
              className="customDisplayFlexRow"
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Box className="customDisplayFlexColumn" gap="20px">
                <Box
                  className="customDisplayFlexRow"
                  sx={{ alignItems: 'center' }}
                >
                  <Typography>Invoice To</Typography>
                </Box>
                <Box className="customDisplayFlexColumn">
                  <Typography>Jesse cary</Typography>
                  <Typography>402 mulberry Rd, Mc dardy, NC,2867</Typography>
                  <Typography>ddjqw@gmail.com</Typography>
                  <Typography>837662882999</Typography>
                </Box>
              </Box>
              <Box className="customDisplayFlexColumn" gap="20px">
                <Box
                  className="customDisplayFlexRow"
                  sx={{ alignContent: 'center' }}
                >
                  <Typography>Payment Info :</Typography>
                </Box>
                <Box className="customDisplayFlexColumn">
                  <Box
                    className="customDisplayFlexRow"
                    sx={{ alignContent: 'center' }}
                  >
                    <Typography>Bank Name :</Typography>
                    <Typography>Bank of india</Typography>
                  </Box>
                  <Box
                    className="customDisplayFlexRow"
                    sx={{ alignContent: 'center' }}
                  >
                    <Typography>Account Number :</Typography>
                    <Typography>32342424</Typography>
                  </Box>
                  <Box
                    className="customDisplayFlexRow"
                    sx={{ alignContent: 'center' }}
                  >
                    <Typography>Swift code :</Typography>
                    <Typography>Vs42424</Typography>
                  </Box>
                  <Box
                    className="customDisplayFlexRow"
                    sx={{ alignContent: 'center' }}
                  >
                    <Typography>Country :</Typography>
                    <Typography>India</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S no</TableCell>
                      <TableCell align="right">Item</TableCell>
                      <TableCell align="right">Qty</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Amount (Rs)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.Sno}
                        </TableCell>
                        <TableCell align="right">{row.Item}</TableCell>
                        <TableCell align="right">{row.Price}</TableCell>
                        <TableCell align="right">{row.Amount}</TableCell>
                        <TableCell align="right">{row.Qty}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              className="customDisplayFlexColumn"
              alignItems="end"
              gap="10px"
            >
              <Box className="customDisplayFlexRow" gap="80px">
                <Typography sx={{ alignItems: 'start' }}>
                  Sub Total :
                </Typography>
                <Typography>$3322</Typography>
              </Box>
              <Box className="customDisplayFlexRow" gap="80px">
                <Typography>Tax Amount :</Typography>
                <Typography>$421</Typography>
              </Box>
              <Box className="customDisplayFlexRow" gap="80px">
                <Box className="customDisplayFlexRow">
                  <Typography>Discount :</Typography>
                  <Typography>5%</Typography>
                </Box>
                <Typography>$10</Typography>
              </Box>
              <Box className="customDisplayFlexRow" gap="80px">
                <Typography>Grand Total :</Typography>
                <Typography>$7266</Typography>
              </Box>
            </Box>
          </Box>
          <Divider orientation="horizontal" />
          <Typography
            sx={{
              margin: '10px 45px',
            }}
          >
            Copyright © 2024 Winjit Inc | All Rights Reserved | Powered by
            Winjit Technologies
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WJ_DocumentDetails;
