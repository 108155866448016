/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable-next-line react/prop-types */
/* eslint-disable-next-line array-callback-return */
/* eslint-disable */
import { useContext } from "react";
import { Box, Stack, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import WJ_AuthContext from "../../WJ_utils/WJ_Auth";
import TableComponent from "./table";

function WJ_Extraction(props) {
  // console.log('props', props.docStatus.toLowerCase() !== 'completed',props.docStatus.toLowerCase(), props.disableExport);
  let tableContent = "";
  const authCtx = useContext(WJ_AuthContext);
  // Create row of key and values
  const getInnerContent = (key) => {
    let innerContent = "";
    innerContent = props.data[key]?.map((keyValue, index1) => {
      let value = "";
      if (keyValue.key === "line_item") {
        // value = props.tableData ? props.tableData.length : 0;
      } else {
        value = keyValue?.value.length > 20 ? `${keyValue.value.slice(0, 20)}...` : keyValue.value;
      }
      return (
        <tr
          className={`row ${props.activeTrId === `${keyValue.key ?? ""}` ? "active-tr" : ""}`}
          key={index1}
          id={`${keyValue.key ?? ""}-tr`}
          onClick={() =>
            props.onClick(
              `${keyValue.key ?? ""}`,
              `${keyValue.Id ?? ""}`,
              `${keyValue.parentKey ?? ""}`,
              `${keyValue.label ?? ""}`,
              keyValue.value ?? ""
            )
          }
        >
          <td className="icon">{keyValue.value !== "" && <CheckIcon fontSize="small" />}</td>
          <td className="key font-semibold">
            <span className="text">{keyValue.label ?? ""}</span>
          </td>
          <td className="value" title={keyValue.value}>
            <span>{value}</span>
            {keyValue.key === "line_item" && (
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ paddingLeft: "10px" }}
              >
                <path
                  d="M2.33268 0.333252H15.666C16.108 0.333252 16.532 0.508847 16.8445 0.821407C17.1571 1.13397 17.3327 1.55789 17.3327 1.99992V11.9999C17.3327 12.4419 17.1571 12.8659 16.8445 13.1784C16.532 13.491 16.108 13.6666 15.666 13.6666H2.33268C1.89065 13.6666 1.46673 13.491 1.15417 13.1784C0.84161 12.8659 0.666016 12.4419 0.666016 11.9999V1.99992C0.666016 1.55789 0.84161 1.13397 1.15417 0.821407C1.46673 0.508847 1.89065 0.333252 2.33268 0.333252ZM9.83268 8.66658V11.9999H15.666V8.66658H9.83268ZM2.33268 8.66658V11.9999H8.16602V8.66658H2.33268ZM9.83268 3.66658V6.99992H15.666V3.66658H9.83268ZM2.33268 3.66658V6.99992H8.16602V3.66658H2.33268Z"
                  fill={props.activeTrId === "line_item" ? "white" : "black"}
                />
              </svg>
            )}
          </td>
        </tr>
      );
    });
    return innerContent;
  };

  if (props.data) {
    tableContent = Object.keys(props.data).map((key, index) => {
      const innerContent = getInnerContent(key);
      const words = key.split(" ");
      for (let i = 0; i < words.length; i += 1) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
      }
      const tableLabel = words.join(" ");
      return (
        <div key={index} className="key-value rounded-md">
          <Typography
            sx={{
              color: "#175EAE",
            }}
          >
            {tableLabel}
          </Typography>
          <div className="table-container">
            <table className="extraction-table">
              <tbody>{innerContent}</tbody>
            </table>
          </div>
        </div>
      );
    });
  }
  return (
    <Box className="info">
      <div
        className="left"
        id="extraction-div"
        onScroll={props.onScroll}
        // style={{ backgroundColor: 'white' }}
      >
        {tableContent}
      </div>
      {props?.extractedTable?.length > 0 && (
        <Box className="p-4">
          <h3 className="text-lg font-semibold mb-4">Tables</h3>
          <div className="grid grid-cols-2 gap-2">
            {props?.extractedTable?.length > 0 &&
              props?.extractedTable.map((table, index) => (
                <button
                  key={table?.id}
                  onClick={(e) => {
                    e.preventDefault();
                    props.handleShowTable(table?.id);
                  }}
                  className="bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  Table {index + 1}
                </button>
              ))}
          </div>
        </Box>
      )}
      {/* {tableContent.length > 0 && (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => {
            props.setOpen(false);
          }}
        >
          <Box>
            <Stack
              className="buttons"
              direction="row"
              gap="1rem"
              justifyContent="center"
            >
              <Button
                className="confirm-all"
                sx={{
                  background: 'green',
                  color: 'white !important',
                }}
                onClick={props.onConfirm}
                disabled={!authCtx.active}
              >
                <CheckIcon fontSize="small" />
                <Typography>Confirm All</Typography>
              </Button>
              <Button
                className="export"
                sx={{
                  background:
                    'linear-gradient(90deg, #ED1C24 6.94%, #F04E30 100%)',
                  color: 'white !important',
                }}
                onClick={() => {
                  props.setOpen(true);
                }}
                // disabled={
                //   props.disableExport &&
                //   props.docStatus.toLowerCase() !== 'completed'
                // }
                disabled={
                  props.disableExport? false : true
                }
              >
                <FileDownloadOutlinedIcon fontSize="small" />
                <Typography>Export Result</Typography>
              </Button>
            </Stack>
            {props.open ? (
              <Box className="export-button">
                <Stack gap="2rem" direction="row">
                  <Stack>
                    <Button
                      onClick={() => {
                        props.onExport('xml');
                      }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 32 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.3351 0.5L20.2751 1.1L14.6651 27.5L11.7251 26.9L17.3351 0.5ZM27.3851 14L22.0001 8.615V4.37L31.6301 14L22.0001 23.615V19.37L27.3851 14ZM0.370117 14L10.0001 4.37V8.615L4.61512 14L10.0001 19.37V23.615L0.370117 14Z"
                          fill="white"
                        />
                      </svg>
                    </Button>
                    <Typography>XML</Typography>
                  </Stack>
                  <Stack>
                    <Button
                      onClick={() => {
                        props.onExport('json');
                      }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 34 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.5 16.5V18.75H8.65925L1.25 26.1593L2.84075 27.75L10.25 20.3407V25.5H12.5V16.5H3.5ZM31.625 7.5L29.375 0.75H27.125V12H29.375V5.25L31.625 12H33.875V0.75H31.625V7.5ZM23.0007 0.75H20.0004C19.5031 0.750298 19.0263 0.947977 18.6746 1.29961C18.323 1.65125 18.1253 2.12809 18.125 2.62537V10.1257C18.1256 10.6226 18.3233 11.099 18.6746 11.4504C19.026 11.8017 19.5024 11.9994 19.9993 12H22.9996C23.4967 11.9997 23.9734 11.8022 24.325 11.4508C24.6766 11.0994 24.8744 10.6228 24.875 10.1257V2.62537C24.8747 2.12828 24.6772 1.65162 24.3258 1.30001C23.9744 0.948407 23.4978 0.750596 23.0007 0.75ZM22.625 9.75H20.375V3H22.625V9.75ZM9.125 2.62537V5.25C9.1256 5.84655 9.36284 6.4185 9.78467 6.84033C10.2065 7.26216 10.7784 7.4994 11.375 7.5H13.625V9.75H9.125V12H13.9996C14.4967 11.9997 14.9734 11.8022 15.325 11.4508C15.6766 11.0994 15.8744 10.6228 15.875 10.1257V7.5C15.8744 6.90345 15.6372 6.3315 15.2153 5.90967C14.7935 5.48784 14.2216 5.2506 13.625 5.25H11.375V3H15.875V0.75H10.9993C10.5022 0.750596 10.0256 0.948407 9.67422 1.30001C9.32282 1.65162 9.1253 2.12828 9.125 2.62537ZM4.625 9.75H2.375V7.5H0.125V10.1246C0.125298 10.6219 0.322977 11.0988 0.674614 11.4504C1.02625 11.802 1.50309 11.9997 2.00038 12H5.00075C5.49765 11.9994 5.97403 11.8017 6.32539 11.4504C6.67675 11.099 6.8744 10.6226 6.875 10.1257V0.75H4.625V9.75Z"
                          fill="white"
                        />
                      </svg>
                    </Button>
                    <Typography>JSON</Typography>
                  </Stack>
                </Stack>
              </Box>
            ) : null}
          </Box>
        </ClickAwayListener>
      )} */}
    </Box>
  );
}

export default WJ_Extraction;
