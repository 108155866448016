/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import { useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from './WJ_navigation/WJ_Navigation';
import WJ_AuthContext from './WJ_utils/WJ_Auth';
import WJ_theme from './WJ_components/WJ_Theme';
import WJ_Loader from './WJ_components/WJ_Loader/WJ_Loader';
import './WJ_assets/css/main.css';
import WJ_useAutoLogout from './WJ_useAutoLogout';

const WJ_logoutTime = 3600; // seconds
function App() {
  const authCtx = useContext(WJ_AuthContext);
  const idToken = sessionStorage.getItem('id_token');
  // eslint-disable-next-line prefer-destructuring
  const loading = authCtx.loading;
  useEffect(() => {
    if (idToken != null) {
      
      authCtx.login(idToken);
      // window.pendo.initialize({
      //   visitor: {
      //     id: idToken,
      //   },
      //   account: {
      //     id: '',
      //   },
      // });
    }
  }, [authCtx, idToken]);
  const timer = WJ_useAutoLogout(WJ_logoutTime);

  if (idToken) {
    if (timer === 0) {
      authCtx.logout();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = process.env.REACT_APP_COGNITO_POOL_LOGOUT_URL;
    }
  }
  return (
    <ThemeProvider theme={WJ_theme}>
      <ToastContainer
        autoClose={3000}
        position="top-center"
        transition={Zoom}
      />
      <WJ_Loader isLoading={loading} />
      <Navigation />
    </ThemeProvider>
  );
}

export default App;
