/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable */
/* eslint-disable import/no-named-as-default-member */
import { useState, useContext, useRef, useEffect } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { Delete, Edit, Add } from "@mui/icons-material";
import {
  Box,
  Typography,
  Stack,
  Button,
  InputLabel,
  Select,
  Modal,
  MenuItem,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import icon from "../../../WJ_assets/images/icon.png";
import upload from "../../../WJ_assets/images/upload.png";
import {
  WJ_Gemeni_url,
  WJ_getConfigurations,
  WJ_getFieldListApi,
  WJ_getUploadPresignedUrl,
  WJ_uploadDocument,
} from "../../../WJ_services/WJ_apiService/WJ_apis";
import WJ_AuthContext from "../../../WJ_utils/WJ_Auth";
import { WJ_getRandomNumber } from "../../../WJ_utils/WJ_Utils";
import WJ_Header from "../../../WJ_components/WJ_Layout/WJ_UserPagesHeader";
import backarrow from "../../../WJ_assets/images/backarrow.png";
import { colors } from "../../../WJ_assets/css/color";
import DataContext from "../../../WJ_utils/WJ_docList";
import axios from "axios";
import api from "../../../WJ_services/WJ_networkService/WJ_api";


function WJ_UploadDocument(props) {
  const navigate = useNavigate();

  const {
    userUploadedPagesCount,
    maxPagesCount,
    toastId: userDocumentToastId,
  } = { ...props };
  const [open, setOpen] = useState(false);
  const [fileTypeVal, setFileTypeVal] = useState("");
  const [documentTypeVal, setDocumentTypeVal] = useState("");
  const [documentSubTypeVal, setDocumentSubTypeVal] = useState("");
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const [fileTypeError, setFileTypeError] = useState(null);
  const [documentTypeError, setDocumentTypeError] = useState(null);
  const [documentSubTypeError, setDocumentSubTypeError] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [fileTypes, setFileTypes] = useState([]);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [documentTypes, setDocumentTypes] = useState({});
  const [maxFileSize, setMaxFileSize] = useState(0);
  const [fileSizeString, setFileSizeString] = useState("1MB");
  const authCtx = useContext(WJ_AuthContext);
  const { docList } = useContext(DataContext);
  const [userAddedKeys, setUserAddedKeys] = useState({});
  const [keys, setKeys] = useState([]);
  const [keyError, setKeysError] = useState("");
  const [keysData, setKeysData] = useState();

  // const [uploadIconDisable, setUploadIconDisable] = useState(false);
  const toastId = useRef(null);

  async function getData() {
    try {
      const res = await WJ_getFieldListApi();
      // console.log(res.data.data.keys)
      return res?.data?.data
    } catch (error) {
      // console.error("Error fetching data:", error);
      return null; // Return null in case of an error
    }
  }

  const fetchData = async () => {
    const data = await getData();
    // console.log("keys====>", data);
    const limitedKeys = {};

    if (data?.keys) {
      Object.keys(data?.keys).forEach(type => {
        limitedKeys[type] = data.keys[type].slice(0, 15);
      });

      console.log(limitedKeys, "limited")
      setKeysData(limitedKeys);
      setUserAddedKeys(data?.useraddedkeys);
      const allDocumentTypes = Object.keys(limitedKeys).concat(Object.keys(data?.useraddedkeys));
      setDocumentTypeOptions(allDocumentTypes.map(type => ({ key: type, value: type })));
      // console.log("documentTypeOptions", documentTypeOptions);
    }// Iterate over each document type in data.keys and limit to first 15 keys



  }
  useEffect(() => {

    fetchData();
    const docConfigurationsObj = JSON.parse(
      localStorage.getItem("docConfigurations")
    );
    // console.log(documentSubTypeVal);
    // const subTypesobj = JSON.parse(localStorage.getItem("subtypes"));
    // console.log("subTypesobj==", subTypesobj);
    // const parsedSubtpyes = subTypesobj?.data;
    // console.log(parsedSubtpyes);
    // setSubTypes(parsedSubtpyes);

    const parsedData = JSON.parse(docConfigurationsObj?.body);
    const fileTypesObj = parsedData?.upload_file_type ?? [];
    const docTypesObj = parsedData?.document_types ?? {};
    const documentUploadSize = parsedData?.document_upload_size ?? "";

    setFileTypes(fileTypesObj);
    setDocumentTypes(docTypesObj);
    setFileSizeString(documentUploadSize);
    const last2characters = documentUploadSize.slice(-2).trim();
    const removedLast2 = documentUploadSize.slice(0, -2).trim();
    setMaxFileSize(WJ_convertToBytes(removedLast2, last2characters));
    if (
      fileTypesObj.length === 0 ||
      Object.keys(docTypesObj).length === 0 ||
      documentUploadSize === ""
    ) {
      WJ_getConfigurationsApiHandler();
      subTypeListExtract(docList);
    }
  }, []);

  // Convert file size string into bytes
  const WJ_convertToBytes = (size, type) => {
    const types = ["B", "KB", "MB", "GB", "TB"];
    const key = types.indexOf(type.toUpperCase());
    if (typeof key !== "boolean") {
      return size * 1024 ** key;
    }
    return 0;
  };
  // Define get configurations api function
  const WJ_getConfigurationsApiHandler = async () => {
    authCtx.showLoader();
    const configurationResponse = await WJ_getConfigurations();

    // const subtypesResponse = await WJ_getSubTypes(authCtx.idToken);
    // setSubTypes(subtypesResponse.data ?? {});

    if (configurationResponse.status && configurationResponse.status === 200) {
      setFileTypes(configurationResponse.data.upload_file_type ?? []);
      setDocumentTypes(configurationResponse.data.document_types ?? {});
      if (configurationResponse.data.document_upload_size) {
        setFileSizeString(configurationResponse.data.document_upload_size);
        const last2characters = configurationResponse.data.document_upload_size
          .slice(-2)
          .trim();
        const removedLast2 = configurationResponse.data.document_upload_size
          .slice(0, -2)
          .trim();
        setMaxFileSize(WJ_convertToBytes(removedLast2, last2characters));
      }
    } else {
      setFileTypes([]);
      setDocumentTypes({});
      setMaxFileSize(0);
      setFileSizeString("");
      setOpen(false);
      toast.error("Something went wrong, Please try again later.");
    }
    authCtx.hideLoader();
  };

  // Reset form
  const WJ_resetForm = () => {
    setFileTypeVal("");
    setDocumentTypeVal("");
    setFile({});
    setFileName("");
    setFileTypeError(null);
    setDocumentTypeError(null);
    setFileError(null);
  };

  // File type dropdown change handler
  const WJ_handleFileTypeChange = (event) => {
    setFileTypeError(null);
    setFileError(null);
    setFile({});
    setFileName("");
    if (event.target.value === "") {
      setFileTypeError("This field is required");
    }
    setFileTypeVal(event.target.value);
  };
  // WJ_Document type dropdown change handler
  const WJ_handleDocumentTypeChange = (event) => {
    setDocumentTypeVal(event.target.value);
    // console.log(keysData);
    if (keysData[event.target.value]) {
      setKeys(keysData[event.target.value]);
    } else if (userAddedKeys[event.target.value]) {
      setKeys(userAddedKeys[event.target.value]);
    } else {
      setKeys([]);
    }
  }

  // Browse button click handler
  const WJ_fileClickHandler = (event) => {
    setFileTypeError(null);
    setDocumentTypeError(null);
    if (fileTypeVal === "") {
      setFileTypeError("This field is required");
      event.preventDefault();
    }
    if (documentTypeVal === "") {
      setDocumentTypeError("This field is required");
      event.preventDefault();
    }
  };
  // Handle selected file
  const WJ_handleFileChange = (event) => {
    setFileError(null);
    setFile({});
    setFileName("");
    const selectedFile = event.target.files[0];
    if (selectedFile !== undefined) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      const fileSize = selectedFile.size;
      if (
        fileTypeVal !== "" &&
        fileTypeVal === "tiff" &&
        fileExtension !== "tiff" &&
        fileExtension !== "tif"
      ) {
        setFileError("Selected file type & Uploaded file type should be same.");
        const inputFile = document.getElementById("file");
        inputFile.value = "";
        return;
      }
      if (
        fileTypeVal !== "" &&
        fileTypeVal === "jpeg" &&
        fileExtension !== "jpeg" &&
        fileExtension !== "jpg"
      ) {
        setFileError("Selected file type & Uploaded file type should be same.");
        const inputFile = document.getElementById("file");
        inputFile.value = "";
        return;
      }
      if (
        fileTypeVal !== "" &&
        fileTypeVal !== "tiff" &&
        fileTypeVal !== "jpeg" &&
        fileExtension !== fileTypeVal
      ) {
        setFileError(
          "Selected file type & Uploaded file type should be sameee."
        );
        const inputFile = document.getElementById("file");
        inputFile.value = "";
        return;
      }
      if (fileSize > maxFileSize) {
        setFileError(`
          File size should be less than or equal to ${fileSizeString}
        `);
        return;
      }
      // Check if the selected file is a PDF
      if (fileExtension === "pdf") {
        const reader = new FileReader();

        reader.onload = (e) => {
          const buffer = e.target.result;
          // Check for the PDF signature (PDF files start with %PDF)
          if (buffer.slice(0, 4).toString() === "%PDF") {
            // Extract the number of pages from the PDF
            const pageCountMatch = buffer
              .toString()
              .match(/\/Type[\s]*\/Page[^s]/g);
            const pageCount = pageCountMatch ? pageCountMatch.length : 0;

            // Now you have the page count
            // console.log("Page Count:", pageCount);
            if (pageCount > 10) {
              setFileError(`
            File page count should be less than 10
          `);
              setFileName("");
              setFileTypeVal("");
              setDocumentTypeVal("");
              return;
            }
          }
        };

        reader.readAsBinaryString(selectedFile);
      } else {
        // Handle other file types as before
        // ... (your existing code)
      }
      setFile(selectedFile);
      setFileName(selectedFile.name.replace(/[\s+]+/g, "-"));
    }
  };
  // Generate id for custom mapping
  const WJ_generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
      const randomNumber = WJ_getRandomNumber();
      const res = randomNumber * 2 ** -32;
      result += characters.charAt(Math.floor(res * charactersLength));
    }
    return result;
  };
  // Define upload document api function
  const WJ_uploadDocumentApiHandler = async (data) => {
    authCtx.showLoader();
    const formData = new FormData();
    Object.entries(data.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", file);
    const uploadDocumentResponse = await WJ_uploadDocument({
      url: data.url,
      data: formData,
    });
    if (uploadDocumentResponse.status === 204) {
      WJ_resetForm();
      authCtx.hideLoader();
      let dateTime = "-";
      const date = new Date();
      if (date && date.toString().toLowerCase() !== "invalid date") {
        dateTime = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1
          }-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
      }
      const newDocObj = {
        id: WJ_generateRandomString(10),
        name: fileName,
        document_type: documentTypeVal,
        status: "in-progress",
        page_count: 0,
        created_date: dateTime,
      };
      localStorage.setItem("newUploadedDocument", JSON.stringify(newDocObj));
      toast.success("Document uploaded successfully.");
      navigate("/dashboard");
      setOpen(false);
      // eslint-disable-next-line react/prop-types, react/destructuring-assignment
      // props.onUpdateDocument(true);
    } else {
      authCtx.hideLoader();
      toast.error("Document upload failed, Please try again later.");
    }
  };
  // Define get upload presigned url api function
  const WJ_getUploadSignedUrlApiHandler = async () => {
    authCtx.showLoader();
    const presignedUrlResponse = await WJ_getUploadPresignedUrl({
      documentType: documentTypeVal,
      // documentSubType: documentSubTypeVal,
      extractionKeys: keys,
      fileName,
      fileType: fileTypeVal,
      id_Token: authCtx.idToken,
    });
    if (
      presignedUrlResponse.data &&
      presignedUrlResponse.data.status &&
      presignedUrlResponse.data.status === 200
    ) {
      geminiAPIcalling(presignedUrlResponse?.data?.data?.fields?.key);
      WJ_uploadDocumentApiHandler(presignedUrlResponse.data.data);
    } else if (
      presignedUrlResponse.data &&
      presignedUrlResponse.data.status &&
      presignedUrlResponse.data.status === 201
    ) {
      authCtx.inactivateUser();
      WJ_resetForm();
      setOpen(false);
      authCtx.hideLoader();
      toast.error(
        presignedUrlResponse.data?.message ??
        "Something went wrong, Please try again later."
      );
    } else {
      authCtx.hideLoader();
      toast.error(
        presignedUrlResponse.data?.message ??
        "Something went wrong, Please try again later."
      );
    }
  };

  // Upload button click handler
  const WJ_handleSubmit = (event) => {
    event.preventDefault();
    console.log("keys ====> ", keys.length);
    setFileError(null);
    if (fileTypeVal === "") {
      setFileTypeError("This field is required");
      return;
    }
    if (documentTypeVal === "") {
      setDocumentTypeError("This field is required");
      return;
    }
    if (fileName === "") {
      setFileError("Please select the file.");
      return;
    }
    if (
      fileTypeVal === "tiff" &&
      file.name.split(".").pop().toLowerCase() !== "tiff" &&
      file.name.split(".").pop().toLowerCase() !== "tif"
    ) {
      setFileError("Selected file type & Uploaded file type should be same.");
      return;
    }
    if (
      fileTypeVal === "jpeg" &&
      file.name.split(".").pop().toLowerCase() !== "jpeg" &&
      file.name.split(".").pop().toLowerCase() !== "jpg"
    ) {
      setFileError("Selected file type & Uploaded file type should be same.");
      return;
    }
    if (
      fileTypeVal !== "tiff" &&
      fileTypeVal !== "jpeg" &&
      file.name.split(".").pop().toLowerCase() !== fileTypeVal
    ) {
      setFileError("Selected file type & Uploaded file type should be same.");
      return;
    }
    console.log("Key Infor", keys, keys.length);
    if (keys.length < 1) {
      setKeysError("Atleast 1 key is required");
      return;
    }
    if (keyError.length > 1) {
      return;
    }

    WJ_getUploadSignedUrlApiHandler();
  };


  const geminiAPIcalling = async (keyString) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("extraction_keys", keys);
    formData.append("document_type", documentTypeVal);
    formData.append("user", authCtx.idToken);
    formData.append("key", keyString);
    try {
      await axios.post(`${WJ_Gemeni_url}/process_invoice`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  // const documentTypeOptions = [];
  // for (const [key, value] of Object.entries(documentTypes)) {
  //   documentTypeOptions.push({ key, value });
  // }

  const fileTypeOptions = [];
  for (const [key, value] of Object.entries(fileTypes)) {
    fileTypeOptions.push({ key, value });
  }
  // console.log("rendering check",fileTypeOptions)
  // Drag and Drop Handler
  const WJ_handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  // Drag and Drop Handler
  const WJ_handleDrop = (event) => {
    setFileTypeError(null);
    setDocumentTypeError(null);
    setFileError(null);
    setFile({});
    setFileName("");
    event.preventDefault();
    event.stopPropagation();
    if (fileTypeVal === "") {
      setFileTypeError("This field is required");
      return false;
    }
    if (documentTypeVal === "") {
      setDocumentTypeError("This field is required");
      return false;
    }
    if (event.dataTransfer.files && event.dataTransfer.files.length > 1) {
      setFileError("Upload only one file.");
    } else {
      const selectedFile = event.dataTransfer.files[0];
      if (selectedFile !== undefined) {
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
        const fileSize = selectedFile.size;
        console.log("fileTypeVal", fileTypeVal, fileExtension);
        if (
          fileTypeVal !== "" &&
          fileTypeVal === "tiff" &&
          fileExtension !== "tiff" &&
          fileExtension !== "tif"
        ) {
          setFileError(
            "Selected file type & Uploaded file type should be same."
          );
          const inputFile = document.getElementById("file");
          inputFile.value = "";
          return;
        }
        if (
          fileTypeVal !== "" &&
          fileTypeVal === "jpeg" &&
          fileExtension !== "jpeg" &&
          fileExtension !== "jpg"
        ) {
          setFileError(
            "Selected file type & Uploaded file type should be same."
          );
          const inputFile = document.getElementById("file");
          inputFile.value = "";
          return;
        }
        if (
          fileTypeVal !== "" &&
          fileTypeVal !== "tiff" &&
          fileTypeVal !== "jpeg" &&
          fileExtension !== fileTypeVal
        ) {
          setFileError(
            "Selected file type & Uploaded file type should be sameee."
          );
          const inputFile = document.getElementById("file");
          inputFile.value = "";
          return;
        }
        if (fileSize > maxFileSize) {
          setFileError(`
            File size should be less than or equal to ${fileSizeString}
          `);
          return;
        }
        // Check if the selected file is a PDF
        if (fileExtension === "pdf") {
          const reader = new FileReader();

          reader.onload = (e) => {
            const buffer = e.target.result;
            // Check for the PDF signature (PDF files start with %PDF)
            if (buffer.slice(0, 4).toString() === "%PDF") {
              // Extract the number of pages from the PDF
              const pageCountMatch = buffer
                .toString()
                .match(/\/Type[\s]*\/Page[^s]/g);
              const pageCount = pageCountMatch ? pageCountMatch.length : 0;

              // Now you have the page count
              // console.log("Page Count:", pageCount);
              if (pageCount > 10) {
                setFileError(`
              File page count should be less than 10
            `);
                setFileName("");
                setFileTypeVal("");
                setDocumentTypeVal("");
                return;
              }
            }
          };

          reader.readAsBinaryString(selectedFile);
        } else {

        }
        // if (fileTypeVal !== '' && fileExtension !== fileTypeVal) {
        //   setFileError(
        //     'Selected file type & Uploaded file type should be same.'
        //   );
        //   return;
        // }
        // if (fileSize > maxFileSize) {
        //   setFileError(`
        //     File size should be less than or equal to ${fileSizeString}
        //   `);
        //   return;
        // }
        // if (fileExtension === 'pdf') {
        //   const reader = new FileReader();
        //   reader.onload = (e) => {
        //     const buffer = e.target.result;
        //     // Check for the PDF signature (PDF files start with %PDF)
        //     if (buffer.slice(0, 4).toString() === '%PDF') {
        //       // Extract the number of pages from the PDF
        //       const pageCountMatch = buffer.toString().match(/\/Type[\s]*\/Page[^s]/g);
        //       const pageCount = pageCountMatch ? pageCountMatch.length : 0;

        //       // Now you have the page count
        //       console.log('Page Count:', pageCount);
        //       if (pageCount>10){
        //         setFileError(`
        //         File page count should be less than 10
        //       `);
        //       setFileName("")
        //       setFileTypeVal("")
        //       setDocumentTypeVal ("")
        //         return;
        //       }
        //     }
        //   };

        //   reader.readAsBinaryString(selectedFile);
        // } else {
        //   // Handle other file types as before
        //   // ... (your existing code)
        // }
        setFile(selectedFile);
        setFileName(selectedFile.name);
      }
    }
  };


  const [modalOpen, setModalOpen] = useState(false);
  const [newDocumentType, setNewDocumentType] = useState("");
  const [newKeys, setNewKeys] = useState([]);
  const [newKeyInput, setNewKeyInput] = useState("");
  const [editDocumentType, setEditDocumentType] = useState(null);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    setNewDocumentType("");
    setNewKeys([]);
    setNewKeyInput("");
    setEditDocumentType(null); // Reset edit mode
  };

  const handleEditDocumentType = (index) => {
    const selectedType = documentTypeOptions[index];
    setEditDocumentType(selectedType);
    setNewDocumentType(selectedType.value);
    console.log("useraddedkeys===>", userAddedKeys, selectedType.value); // Set existing value for editing
    if (userAddedKeys[selectedType.value]) {
      setNewKeys(userAddedKeys[selectedType.value]);
    } else {
      setNewKeys([]);
    }
    setModalOpen(true);
  };

  const handleAddNewKey = () => {
    if (newKeyInput.trim()) {
      // Split input by commas, trim each key, and filter out any empty strings
      const keysToAdd = newKeyInput.split(',').map(key => key.trim()).filter(key => key);

      // Filter out keys that are already in the newKeys array
      // const uniqueKeysToAdd = keysToAdd.filter(key => !newKeys.includes(key));

      // Check if there are any duplicate keys in the input
      const duplicateKeys = keysToAdd.length !== uniqueKeysToAdd.length;
      if (duplicateKeys) {
        setKeyError("Some keys already exist");
      }

      // Add unique keys to the newKeys array if total keys are less than 15
      if (newKeys.length + uniqueKeysToAdd.length <= 15) {
        setNewKeys([...newKeys, ...uniqueKeysToAdd]);
        setNewKeyInput("");
      } else {
        setKeyError("Cannot add more than 15 keys");
      }
    }
  };

  const handleRemoveNewKey = (index) => {
    setNewKeys(newKeys.filter((_, i) => i !== index));
  };

  const handleAddDocumentType = async () => {
    if (newDocumentType.trim() && newKeys.length > 0) {
      const apiEndpoint = "doc-type/addDocType";
      const payload = {
        document_type: newDocumentType,
        keys: newKeys,
      };

      try {
        const response = await api.post(apiEndpoint, payload);

        if (response.status === 200) {
          console.log("Document type saved successfully:", response.data);
          setDocumentTypeVal("");
          setKeys([]);
          // Refetch data after adding/editing document type
          await fetchData();

          handleCloseModal();
        } else {
          console.error("Failed to save document type:", response.data);
        }
      } catch (error) {
        console.error("Error saving document type:", error);
      }
    } else {
      console.error("Document type or keys are missing");
    }
  };

  const deleteDocumentType = async (type) => {
    authCtx.showLoader();
    // setDeleting(true);
    try {
      const payload = {
        document_type: type,
      };
      const response = await api.post("doc-type/delete-doc-type", payload);
      if (response.status === 200) {
        setDocumentTypeVal("");
        setKeys([]);
        // Refetch data after adding/editing document type
        await fetchData();
      }
    } catch (error) {
      console.error("Error deleting document type:", error);
    } finally {
      authCtx.hideLoader();
      // setDeleting(false);
    }
  };

  const [menuOpen, setMenuOpen] = useState("");

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };
  return (
    <>
      <WJ_Header />
      <Box sx={{ marginTop: "80px" }}>
        <Box
          className="customDisplayFlexColumn"
          sx={{
            padding: "40px 20px",
            gap: "30px",
            minHeight: "100vh",
            backgroundColor: "#f0f4f8",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            <img src={backarrow} alt="Back" width="30" height="30" />
            <Typography sx={{ fontWeight: 800, fontSize: "24px" }}>Upload Document</Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            <Box sx={{ flex: "1 1 250px" }}>
              <InputLabel htmlFor="select-file-type" className="label">
                File Type <em className="required">*</em>
              </InputLabel>
              <Select
                variant="outlined"
                labelId="file-type"
                id="select-file-type"
                className="input-select"
                name="file-type"
                value={fileTypeVal}
                onChange={WJ_handleFileTypeChange}
                displayEmpty
                sx={{ backgroundColor: "#fff" }}
              >
                <MenuItem value="" disabled>
                  Select file type
                </MenuItem>
                {fileTypeOptions.map((type, index) => (
                  <MenuItem value={type.value.toLowerCase()} key={index}>
                    {type.value.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              {fileTypeError && (
                <Typography color="error" sx={{ marginTop: "10px" }}>
                  {fileTypeError}
                </Typography>
              )}
            </Box>

            <Box sx={{ flex: "1 1 250px" }}>
              <InputLabel htmlFor="select-document-type" className="label">
                Document Type <em className="required">*</em>
              </InputLabel>
              <Select
                variant="outlined"
                labelId="document-type"
                id="select-document-type"
                className="input-select"
                name="document-type"
                value={documentTypeVal}
                onOpen={handleMenuOpen}
                onClose={handleMenuClose}
                onChange={WJ_handleDocumentTypeChange}
                displayEmpty
                sx={{ backgroundColor: '#fff' }}
              >
                <MenuItem value="" disabled>
                  Select document type
                </MenuItem>
                {documentTypeOptions.map((type, index) => (
                  <MenuItem value={type.value} key={index}>
                    {type.value}
                    {menuOpen && userAddedKeys?.hasOwnProperty(type.value) && (
                      <>
                        <IconButton aria-label="edit" onClick={() => handleEditDocumentType(index)}>
                          <Edit />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => deleteDocumentType(type.key)}>
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </MenuItem>
                ))}
              </Select>
              {documentTypeError && (
                <Typography color="error" sx={{ marginTop: '10px' }}>
                  {documentTypeError}
                </Typography>
              )}



              <button
                className="bg-blue-800 text-white px-4 py-2 mt-4 rounded hover:bg-blue-700 active:bg-blue-900 transition duration-300 transform active:scale-100"
                onClick={handleOpenModal}
              >
                Create New Document Type
              </button>
            </Box>

          </Box>



          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "20px",
              }}
            >
              {keys.map((key, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "4px",
                    padding: "10px",
                  }}
                >
                  <Typography>{key}</Typography>
                </Box>
              ))}
            </Box>

            {keyError && <Typography color="error">{keyError}</Typography>}
          </Box>

          <Box
            className="upload-section"
            onDragEnter={WJ_handleDrag}
            onDragLeave={WJ_handleDrag}
            onDragOver={WJ_handleDrag}
            onDrop={WJ_handleDrop}
            sx={{
              backgroundColor: "#ffffff",
              padding: "60px",
              border: "3px dashed #007bff",
              borderRadius: "7px",
              textAlign: "center",
            }}
          >
            <Stack gap="1rem" direction="column" alignItems="center">
              <Box>
                <img src={upload} alt="Upload" height="85" width="85" />
              </Box>
              <input
                type="file"
                name="file"
                id="file"
                hidden
                onChange={WJ_handleFileChange}
                onClick={WJ_fileClickHandler}
                accept="application/pdf,image/png,image/jpeg,image/tif,image/tiff"
              />
              <Typography>
                Drag and drop, or
                <Button
                  className="browse-button"
                  onClick={() => document.getElementById("file").click()}
                  sx={{ textTransform: 'none', color: '#007bff' }}
                >
                  browse
                </Button>
                your files
              </Typography>
              {fileName && (
                <Typography sx={{ wordBreak: "break-word" }}>
                  {fileName}
                </Typography>
              )}
              {fileError && (
                <Typography className="error">{fileError}</Typography>
              )}
            </Stack>
          </Box>

          <Stack gap="1rem" direction="row" justifyContent="center">
            <button
              className="p-3 bg-blue-800 font-semibold rounded-md text-white hover:bg-blue-800 transition duration-200 ease-in-out"
              onClick={WJ_handleSubmit}
            >
              Upload
            </button>
          </Stack>
        </Box>
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '500px',
              maxHeight: '80vh',
              overflowY: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: '8px',
            }}
          >
            <Typography variant="h6" component="h2">
              {editDocumentType ? 'Edit Document Type' : 'Add New Document Type'}
            </Typography>
            <TextField
              fullWidth
              label="Document Type"
              variant="outlined"
              value={newDocumentType}
              disabled={!!editDocumentType}
              onChange={(e) => setNewDocumentType(e.target.value)}
              sx={{ marginTop: '20px', backgroundColor: "#fff" }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
              <TextField
                fullWidth
                label="Key"
                variant="outlined"
                value={newKeyInput}
                disabled={newKeys.length > 14}
                onChange={(e) => setNewKeyInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleAddNewKey();
                }}
                sx={{ backgroundColor: "#fff" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNewKey}
                disabled={newKeys?.length >= 15}
                sx={{ backgroundColor: '#007bff' }}
              >
                Add Key
              </Button>
              {keyError && (
                <Typography color="error" sx={{ marginTop: '10px' }}>
                  {keyError}
                </Typography>
              )}
              {documentTypeError && (
                <Typography className="error">{documentTypeError}</Typography>
              )}
              {newKeys.map((key, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#e0e0e0',
                    borderRadius: '4px',
                    padding: '10px',
                  }}
                >
                  <Typography>{key}</Typography>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveNewKey(index)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: '10px',
              marginTop: '20px',
            }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddDocumentType}
                sx={{ backgroundColor: '#007bff' }}
              >
                Save Changes
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal >
      </Box >
    </>

  );
};


export default WJ_UploadDocument;
