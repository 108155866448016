/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */

import React, { useState, useMemo } from 'react';

const WJ_AuthContext = React.createContext({
  idToken: null,
  refreshToken: null,
  loading: false,
  active: true,
  remainingTrialDays: -1,
  totalPagesCount: 0,
  login: () => {},
  logout: () => {},
  showLoader: () => {},
  hideLoader: () => {},
  inactivateUser: () => {},
  setRemainingTrialDays: (input) => {},
  setTotalPagesCount: (input) => {},
});

export const WJ_AuthContextProvider = (props) => {
  const [userToken, setUserToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [remainingDays, setRemainingDays] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshToken, setRefreshToken] = useState(null);

  // Handle login to set token
  const WJ_loginHandler = (token) => {
    setUserToken(token);
  };
  // Set refresh token
  const WJ_updateRefreshToken = (token) => {
    setRefreshToken(token);
  };
  // Handle login to remove token
  const WJ_logoutHandler = () => {
    setUserToken(null);
  };
  // Handle show loader state
  const WJ_showLoaderHandler = () => {
    setIsLoading(true);
  };
  // Handle hide loader state
  const WJ_hideLoaderHandler = () => {
    setIsLoading(false);
  };
  // Handle inactive user state
  const inactiveUser = () => {
    setIsActive(false);
  };
  // Handle remaining days state
  const WJ_remainingDaysHandler = (days) => {
    setRemainingDays(days);
  };
  // Handle total pages count
  const totalPagesHandler = (count) => {
    setTotalPages(count);
  };

  const WJ_contextValue = useMemo(
    () => ({
      idToken: userToken,
      loading: isLoading,
      active: isActive,
      refreshToken,
      remainingTrialDays: remainingDays,
      totalPagesCount: totalPages,
      WJ_updateRefreshToken,
      login: WJ_loginHandler,
      logout: WJ_logoutHandler,
      showLoader: WJ_showLoaderHandler,
      hideLoader: WJ_hideLoaderHandler,
      inactivateUser: inactiveUser,
      setRemainingTrialDays: WJ_remainingDaysHandler,
      setTotalPages: totalPagesHandler,
    }),
    [userToken, isLoading, isActive, remainingDays, totalPages]
  );

  return (
    <WJ_AuthContext.Provider value={WJ_contextValue}>
      {props.children}
    </WJ_AuthContext.Provider>
  );
};

export default WJ_AuthContext;
