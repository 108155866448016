/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Stack,
  Button,
  Typography,
  Modal,
  InputLabel,
  TextField,
  Grid,
  Checkbox,
} from '@mui/material';
import { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import EastIcon from '@mui/icons-material/East';
import { WJ_contactUs } from '../../WJ_services/WJ_apiService/WJ_apis';
import WJ_AuthContext from '../../WJ_utils/WJ_Auth';
import * as WJ_constants from '../../WJ_utils/WJ_Constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  maxWidth: '500px',
  margin: 'auto',
  outline: 'none',
  maxHeight: '95vh',
  overflow: 'auto',
};

export default function WJ_RequestDemoModal(props) {
  const [contactNumber, setContactNumber] = useState('');
  const [contactNumberError, setContactNumberError] = useState(null);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [organisation, setOrganisation] = useState('');
  const [organisationError, setOrganisationError] = useState(null);
  const [designation, setDesignation] = useState('');
  const [designationError, setDesignationError] = useState(null);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(null);
  const [privacyPolicyCheck, setPrivacyPolicyCheck] = useState(false);
  const [privacyPolicyError, setPrivacyPolicyError] = useState(null);
  const [promotionalEmailsCheck, setPromotionalEmailCheck] = useState(false);
  const authCtx = useContext(WJ_AuthContext);

  const WJ_resetForm = () => {
    setNameError(null);
    setContactNumberError(null);
    setEmailError(null);
    setOrganisationError(null);
    setDesignationError(null);
    setMessageError(null);
    setPrivacyPolicyError(null);
    setName('');
    setEmail('');
    setOrganisation('');
    setDesignation('');
    setContactNumber('');
    setMessage('');
    setPrivacyPolicyCheck(false);
    setPromotionalEmailCheck(false);
  };

  const WJ_contactUsApiHandler = async () => {
    authCtx.showLoader();
    const response = await WJ_contactUs({
      data: {
        name,
        email,
        contact_number: contactNumber,
        organisation_name: organisation,
        designation,
        message,
        promotional_email: promotionalEmailsCheck,
      },
    });
    authCtx.hideLoader();
    if (response.data && response.data.status && response.data.status === 200) {
      WJ_resetForm();
      toast.success(response.data.message ?? 'Enquiry sent successfully');
      props.onClose();
    } else {
      toast.error(
        response.data && response.data.message
          ? response.data.message
          : 'Something went wrong, Please try again later.'
      );
    }
  };

  const WJ_validateName = (userName) => {
    let valid = true;
    if (userName.trim() === '') {
      setNameError('This field is required.');
      valid = false;
    }
    if (userName.trim().length > 50) {
      setNameError('Enter Name with less than 50 characters.');
      valid = false;
    }
    return valid;
  };

  const WJ_validateEmail = (userEmail) => {
    let valid = true;
    if (userEmail.trim() === '') {
      setEmailError('This field is required.');
      valid = false;
    }
    if (userEmail.trim() !== '') {
      const result = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        userEmail.trim()
      );
      if (!result) {
        setEmailError('Enter valid email id.');
        valid = false;
      }
    }
    return valid;
  };

  const WJ_validateContactNumber = (userContactNumber) => {
    let valid = true;
    if (userContactNumber.trim() === '') {
      setContactNumberError('This field is required.');
      valid = false;
    }
    if (userContactNumber.trim() !== '') {
      const result = /^\+?\d+$/.test(userContactNumber.trim());
      if (!result) {
        setContactNumberError(
          'Contact number accepts digits and + at the start.'
        );
        valid = false;
      }
      if (
        userContactNumber.trim().length <= 6 ||
        userContactNumber.trim().length > 15
      ) {
        setContactNumberError('Contact number length must be between 7 to 15.');
        valid = false;
      }
    }
    return valid;
  };

  const WJ_validateOrganization = (userOrganization) => {
    let valid = true;
    if (userOrganization.trim() === '') {
      setOrganisationError('This field is required.');
      valid = false;
    }
    if (userOrganization.trim().length > 20) {
      setOrganisationError('Enter less than 20 characters.');
      valid = false;
    }
    return valid;
  };

  const WJ_validateDesignation = (userDesignation) => {
    let valid = true;
    if (userDesignation.trim() === '') {
      setDesignationError('This field is required.');
      valid = false;
    }
    if (userDesignation.trim().length > 20) {
      setDesignationError('Enter than 20 characters.');
      valid = false;
    }
    return valid;
  };

  const WJ_submitHandler = (event) => {
    event.preventDefault();
    setNameError(null);
    setContactNumberError(null);
    setEmailError(null);
    setOrganisationError(null);
    setDesignationError(null);
    setMessageError(null);
    setPrivacyPolicyError(null);

    if (!WJ_validateName(name)) {
      return;
    }
    if (!WJ_validateEmail(email)) {
      return;
    }
    if (!WJ_validateContactNumber(contactNumber)) {
      return;
    }
    if (!WJ_validateOrganization(organisation)) {
      return;
    }
    if (!WJ_validateDesignation(designation)) {
      return;
    }
    if (message.trim().length > WJ_constants.WJ_messageMaxLength) {
      setMessageError('Enter message with less than 500 characters.');
      return;
    }
    if (!privacyPolicyCheck) {
      setPrivacyPolicyError('This field is required.');
      return;
    }
    WJ_contactUsApiHandler();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 1,
      }}
    >
      <Grid container sx={style}>
        <Grid item md={12} xs={12} sx={{ p: '1% 4%' }}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{
              fontWeight: '600 !important',
              fontFamily: 'NunitoSans-Regular !important',
            }}
          >
            Contact Us
            <Button
              sx={{
                float: 'right',
                color: 'black',
                background: 'transparent !important',
                justifyContent: 'right',
                paddingRight: '0Px !important',
              }}
              onClick={() => {
                WJ_resetForm();
                props.onClose();
              }}
            >
              <HighlightOffOutlinedIcon />
            </Button>
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} sx={{ p: '1% 4%' }}>
          <Typography
            sx={{
              fontSize: '0.7rem',
              fontFamily: 'NunitoSans-Regular !important',
            }}
          >
            Book a live demo with industry and AI experts, discuss your unique
            use case & see how Malkom can transform your business operations.
          </Typography>
        </Grid>
        <Grid component="form" onSubmit={WJ_submitHandler} item md={12} xs={12}>
          <Box sx={{ p: '1% 4%' }}>
            <InputLabel
              htmlFor="name"
              sx={{
                fontSize: '0.8rem !important',
                fontFamily: 'NunitoSans-Regular !important',
                fontWeight: '600 !important',
              }}
            >
              Name<span style={{ color: 'red' }}> *</span>
            </InputLabel>
            <TextField
              sx={{
                width: '100%',
                marginTop: '1%',
                background: '#F0F0F0',
              }}
              size="small"
              id="name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="text-field"
            />
            {nameError && (
              <Typography className="error">{nameError}</Typography>
            )}
            <InputLabel
              htmlFor="email"
              sx={{
                marginTop: '3%',
                fontSize: '0.8rem !important',
                fontFamily: 'NunitoSans-Regular !important',
                fontWeight: '600 !important',
              }}
            >
              Email<span style={{ color: 'red' }}> *</span>
            </InputLabel>
            <TextField
              sx={{
                width: '100%',
                marginTop: '1%',
                background: '#F0F0F0',
              }}
              size="small"
              id="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="text-field"
            />
            {emailError && (
              <Typography className="error">{emailError}</Typography>
            )}
            <InputLabel
              htmlFor="contact-number"
              sx={{
                marginTop: '3%',
                fontSize: '0.8rem !important',
                fontFamily: 'NunitoSans-Regular !important',
                fontWeight: '600 !important',
              }}
            >
              Contact Number<span style={{ color: 'red' }}> *</span>
            </InputLabel>
            <TextField
              sx={{
                width: '100%',
                marginTop: '1%',
                background: '#F0F0F0',
              }}
              size="small"
              id="contact-number"
              name="contact-number"
              onChange={(e) => {
                setContactNumber(e.target.value);
              }}
              className="text-field"
            />
            {contactNumberError && (
              <Typography className="error">{contactNumberError}</Typography>
            )}
            <InputLabel
              htmlFor="organisation"
              sx={{
                marginTop: '3%',
                fontSize: '0.8rem !important',
                fontFamily: 'NunitoSans-Regular !important',
                fontWeight: '600 !important',
              }}
            >
              Organisation Name<span style={{ color: 'red' }}> *</span>
            </InputLabel>
            <TextField
              sx={{
                width: '100%',
                marginTop: '1%',
                background: '#F0F0F0',
              }}
              size="small"
              id="organisation"
              onChange={(e) => {
                setOrganisation(e.target.value);
              }}
              className="text-field"
            />
            {organisationError && (
              <Typography className="error">{organisationError}</Typography>
            )}
            <InputLabel
              htmlFor="designation"
              sx={{
                marginTop: '3%',
                fontSize: '0.8rem !important',
                fontFamily: 'NunitoSans-Regular !important',
                fontWeight: '600 !important',
              }}
            >
              Designation<span style={{ color: 'red' }}> *</span>
            </InputLabel>
            <TextField
              sx={{
                width: '100%',
                marginTop: '1%',
                background: '#F0F0F0',
              }}
              size="small"
              id="designation"
              onChange={(e) => {
                setDesignation(e.target.value);
              }}
              className="text-field"
            />
            {designationError && (
              <Typography className="error">{designationError}</Typography>
            )}
            <InputLabel
              htmlFor="message"
              sx={{
                marginTop: '3%',
                fontSize: '0.8rem !important',
                fontFamily: 'NunitoSans-Regular !important',
                fontWeight: '600 !important',
              }}
            >
              Message
            </InputLabel>
            <TextField
              sx={{
                width: '100%',
                marginTop: '1%',
                background: '#F0F0F0',
              }}
              size="small"
              multiline
              minRows="2"
              id="message"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="textarea-field"
            />
            {messageError && (
              <Typography className="error">{messageError}</Typography>
            )}
            <Stack direction="row">
              <Checkbox
                checked={privacyPolicyCheck}
                onChange={() => setPrivacyPolicyCheck(!privacyPolicyCheck)}
                sx={{
                  paddingLeft: '0px',
                }}
              />
              <Typography
                sx={{
                  marginTop: '3%',
                  fontSize: '0.8rem !important',
                  fontFamily: 'NunitoSans-Regular !important',
                  fontWeight: '600 !important',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              >
                By submitting this form, I understand that my data will be
                processed in accordance with the&nbsp;
                <a
                  href="https://www.winjit.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  Privacy Policy
                </a>
              </Typography>
            </Stack>
            {privacyPolicyError && (
              <Typography className="error">{privacyPolicyError}</Typography>
            )}
            <Stack direction="row">
              <Checkbox
                checked={promotionalEmailsCheck}
                onChange={() =>
                  setPromotionalEmailCheck(!promotionalEmailsCheck)
                }
                sx={{
                  paddingLeft: '0px',
                }}
              />
              <Typography
                sx={{
                  marginTop: '3%',
                  fontSize: '0.8rem !important',
                  fontFamily: 'NunitoSans-Regular !important',
                  fontWeight: '600 !important',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              >
                Yes, I would like to receive promotional emails and product
                updates.
              </Typography>
            </Stack>
          </Box>
          <Stack
            sx={{ p: '1% 4%', textAlign: 'end' }}
            gap="1rem"
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              sx={{
                background:
                  'linear-gradient(90deg, #F0F0F0 6.94%, #F0F0F0 100%)',
                color: 'black !important',
                padding: '8px 16px',
                borderRadius: '0px !important',
                fontSize: '0.8rem !important',
                fontFamily: 'NunitoSans-Regular !important',
                marginBottom: '2%',
              }}
              onClick={() => {
                WJ_resetForm();
                props.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              sx={{
                background:
                  'linear-gradient(90deg, #ED1C24 6.94%, #F04E30 100%)',
                color: 'white !important',
                padding: '8px 16px',
                borderRadius: '0px !important',
                fontSize: '0.8rem !important',
                fontFamily: 'NunitoSans-Regular !important',
                marginBottom: '2%',
              }}
            >
              Send&nbsp;&nbsp;&nbsp;
              <EastIcon />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Modal>
  );
}
