/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import {
  Grid,
  Box,
  Link,
  Typography,
  Stack,
  Paper,
  Button,
  Divider,
} from '@mui/material';
import { useState } from 'react';
import raphael_cloud2 from '../../WJ_assets/images/raphael_cloud2.png';
import footerfacebook from '../../WJ_assets/images/footerfacebook.png';
import footerlinkedin from '../../WJ_assets/images/footerlinkedin.png';
import footertwitter from '../../WJ_assets/images/footertwitter.png';
import WJ_RequestDemoModal from '../WJ_Modal/WJ_RequestDemoModal';
// import WJ_RequestDemoModal from '../WJ_Modal/WJ_RequestDemoModal';

export default function WJ_Footer() {
  const [open, setOpen] = useState(false);
  const WJ_handleOpen = () => setOpen(true);
  const WJ_handleClose = () => setOpen(false);
  const WJ_exploreButtonHandler = () => {
    window.location.href = process.env.REACT_APP_COGNITO_POOL_LOGIN_URL;
  };

  return (
    <Box className="background-color">
      <Box
        sx={{
          margin: '0% 15%',
        }}
      >
        <Grid container spacing={2} className="footermainGrid">
          <Grid item lg={6} xs={12}>
            <Grid container spacing={13}>
              <Grid item lg={8} md={8}>
                <Grid item container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <img
                      src={raphael_cloud2}
                      alt=""
                      style={{ width: '50px', height: '50px' }}
                    />
                    <Typography
                      sx={{
                        color: '#FFFFFF',
                        marginLeft: '10px',
                        marginRight: '30px',
                        fontWeight: 'bold',
                      }}
                    >
                      Docusense
                    </Typography>
                    <Grid style={{ display: 'flex', gap: '10px' }}>
                      <img
                        src={footerlinkedin}
                        alt=""
                        style={{ width: '20px', height: '20px' }}
                      />
                      <img
                        src={footertwitter}
                        alt=""
                        style={{ width: '20px', height: '20px' }}
                      />
                      <img
                        src={footerfacebook}
                        alt=""
                        style={{ width: '20px', height: '20px' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <p style={{ color: 'white' }}>
                    DocuSense A single platform designed to minimize the time
                    your organization spends processing documents.
                  </p>
                </Grid>
              </Grid>
              <Divider sx={{ backgroundColor: 'grey' }} />
              <Grid item lg={4} md={4}>
                <Grid
                  item
                  container
                  direction="column"
                  sx={{
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <Grid
                    item
                    sx={{
                      marginBottom: '25px',
                    }}
                  >
                    <a className="textColor">Privacy Policy</a>
                  </Grid>
                  <Grid item>
                    <a className="textColor">Resources</a>
                  </Grid>
                  <Grid item>
                    <a className="textColor">Features</a>
                  </Grid>
                  <Grid item>
                    <a className="textColor">Solutions</a>
                  </Grid>
                  <Grid item>
                    <a className="textColor">Platform</a>
                  </Grid>
                  <Grid item>
                    <a className="textColor">Product Suite</a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            sx={{
              textAlign: {
                // xs: 'center',
                sm: 'center',
                lg: 'right',
                md: 'center',
              },
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: '#FFFFFF',
                borderColor: '#FFFFFF',
                textTransform: 'none',
                marginRight: '10px',
                marginBottom: {
                  xs: '10px',
                  lg: '0px',
                  md: '0',
                  sm: '0',
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  // color: '',
                  borderColor: '#FFFFFF !important',
                },
              }}
              onClick={WJ_handleOpen}
            >
              Contact Us
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: '#FFFFFF',
                borderColor: '#FFFFFF',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                  // color: '',
                  borderColor: '#FFFFFF !important',
                },
              }}
              onClick={WJ_exploreButtonHandler}
            >
              Try it for FREE
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Typography
            sx={{
              color: '#FFFFFF',
              textAlign: 'center',
              paddingBottom: '10px',
              // marginLeft: '10px',
              // marginRight: '30px',
              // fontWeight: 'bold',
            }}
          >
            Copyright © 2024 Winjit Inc | All Rights Reserved | Powered by
            Winjit Technologies
          </Typography>
        </Grid>
        <WJ_RequestDemoModal open={open} onClose={WJ_handleClose} />
      </Box>
    </Box>
  );
}
