/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import { useState, useContext } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Box, Button, Stack, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { toast } from 'react-toastify';
import WJ_AuthContext from '../../WJ_utils/WJ_Auth';
import { WJ_exportDocument } from '../../WJ_services/WJ_apiService/WJ_apis';
import WJ_FileDownloadedModal from '../WJ_Modal/WJ_FileDownloadedModal';
// eslint-disable-next-line camelcase
function WJ_Export(props) {
  const authCtx = useContext(WJ_AuthContext);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const WJ_exportHandler = async (input) => {
    authCtx.showLoader();
    const name = input.name ? input.name.split('.')[0] : '';
    const exportResponse = await WJ_exportDocument({
      id_Token: authCtx.idToken,
      documentId: input.id,
      documentType: input.type,
      fileName: name,
      type: input.format,
    });
    if (exportResponse.status && exportResponse.status === 200) {
      setAnchorEl(null);
      if (
        exportResponse.data &&
        exportResponse.data.data !== null &&
        exportResponse.data.data !== ''
      ) {
        try {
          const res = exportResponse.data.data;
          const link = document.createElement('a');
          link.href = `data:application/${
            input.format
          };charset=utf-8,${encodeURIComponent(
            input.format === 'json' ? JSON.stringify(res) : res
          )}`;
          link.setAttribute('download', `${name}-output.${input.format}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          authCtx.hideLoader();
          setOpenDownloadModal(true);
        } catch (err) {
          authCtx.hideLoader();
          toast.error('Something went wrong, please try again later.');
        }
      } else {
        authCtx.hideLoader();
        toast.error('Something went wrong, please try again later.');
      }
    } else {
      setAnchorEl(null);
      authCtx.hideLoader();
      toast.error(
        exportResponse.message ??
          'Something went wrong, please try again later.'
      );
    }
  };

  return (
    <>
      <WJ_FileDownloadedModal
        openDownloadModal={openDownloadModal}
        onClose={() => setOpenDownloadModal(false)}
      />
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => setAnchorEl(null)}
      >
        <Box>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event) => {
              event.stopPropagation();
              return props.disabled ? null : setAnchorEl(event.currentTarget);
            }}
            sx={{
              cursor: 'pointer',
              color: `${props.disabled ? 'lightgray' : 'black'}`,
            }}
            title="Export"
          >
            <CloudDownloadIcon />
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{
              zIndex: 0,
            }}
          >
            <MenuItem className="export-menu">
              <Stack gap="2rem" direction="row">
                <Stack>
                  <Button
                    onClick={() =>
                      WJ_exportHandler({
                        format: 'xml',
                        id: props.id,
                        type: props.doc_type,
                        name: props.name,
                      })
                    }
                    sx={{
                      background:
                        'linear-gradient(90deg, #ED1C24 6.94%, #F04E30 100%)',
                    }}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 32 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.3351 0.5L20.2751 1.1L14.6651 27.5L11.7251 26.9L17.3351 0.5ZM27.3851 14L22.0001 8.615V4.37L31.6301 14L22.0001 23.615V19.37L27.3851 14ZM0.370117 14L10.0001 4.37V8.615L4.61512 14L10.0001 19.37V23.615L0.370117 14Z"
                        fill="white"
                      />
                    </svg>
                  </Button>
                  <Typography>XML</Typography>
                </Stack>
                <Stack>
                  <Button
                    onClick={() =>
                      WJ_exportHandler({
                        format: 'json',
                        id: props.id,
                        type: props.doc_type,
                        name: props.name,
                      })
                    }
                    sx={{
                      background:
                        'linear-gradient(90deg, #ED1C24 6.94%, #F04E30 100%)',
                    }}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 34 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.5 16.5V18.75H8.65925L1.25 26.1593L2.84075 27.75L10.25 20.3407V25.5H12.5V16.5H3.5ZM31.625 7.5L29.375 0.75H27.125V12H29.375V5.25L31.625 12H33.875V0.75H31.625V7.5ZM23.0007 0.75H20.0004C19.5031 0.750298 19.0263 0.947977 18.6746 1.29961C18.323 1.65125 18.1253 2.12809 18.125 2.62537V10.1257C18.1256 10.6226 18.3233 11.099 18.6746 11.4504C19.026 11.8017 19.5024 11.9994 19.9993 12H22.9996C23.4967 11.9997 23.9734 11.8022 24.325 11.4508C24.6766 11.0994 24.8744 10.6228 24.875 10.1257V2.62537C24.8747 2.12828 24.6772 1.65162 24.3258 1.30001C23.9744 0.948407 23.4978 0.750596 23.0007 0.75ZM22.625 9.75H20.375V3H22.625V9.75ZM9.125 2.62537V5.25C9.1256 5.84655 9.36284 6.4185 9.78467 6.84033C10.2065 7.26216 10.7784 7.4994 11.375 7.5H13.625V9.75H9.125V12H13.9996C14.4967 11.9997 14.9734 11.8022 15.325 11.4508C15.6766 11.0994 15.8744 10.6228 15.875 10.1257V7.5C15.8744 6.90345 15.6372 6.3315 15.2153 5.90967C14.7935 5.48784 14.2216 5.2506 13.625 5.25H11.375V3H15.875V0.75H10.9993C10.5022 0.750596 10.0256 0.948407 9.67422 1.30001C9.32282 1.65162 9.1253 2.12828 9.125 2.62537ZM4.625 9.75H2.375V7.5H0.125V10.1246C0.125298 10.6219 0.322977 11.0988 0.674614 11.4504C1.02625 11.802 1.50309 11.9997 2.00038 12H5.00075C5.49765 11.9994 5.97403 11.8017 6.32539 11.4504C6.67675 11.099 6.8744 10.6226 6.875 10.1257V0.75H4.625V9.75Z"
                        fill="white"
                      />
                    </svg>
                  </Button>
                  <Typography>JSON</Typography>
                </Stack>
              </Stack>
            </MenuItem>
          </Menu>
        </Box>
      </ClickAwayListener>
    </>
  );
}
// eslint-disable-next-line camelcase
export default WJ_Export;
