/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable */
import { useEffect, useState, useContext,React } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, InputLabel, TextField, Button, Typography } from "@mui/material";
import { AccountCircleOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { WJ_getUserDetails, WJ_updateUserDetails } from "../../WJ_services/WJ_apiService/WJ_apis";
import WJ_Header from "../../WJ_components/WJ_Layout/WJ_UserPagesHeader";
import WJ_AuthContext from "../../WJ_utils/WJ_Auth";
import { colors } from "../../WJ_assets/css/color";
import * as RadixLabel from '@radix-ui/react-label';
import * as RadixCheckbox from '@radix-ui/react-checkbox';


function WJ_UserProfile() {
  const authCtx = useContext(WJ_AuthContext);
  const [isEdit, setIsEdit] = useState(false);
  const [action, setAction] = useState("edit");
  const uploadPagesCount = authCtx.totalPagesCount ?? 0;
  const [remainingTrialDays, setRemainingTrialDays] = useState(authCtx.remainingTrialDays);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [nameOfOrganization, setNameOfOrganization] = useState("");
  const [roleInOrganization, setRoleInOrganization] = useState("");
  const [nameError, setNameError] = useState(null);
  const [contactNumberError, setContactNumberError] = useState(null);
  const [WJ_UserData, setWJ_UserData] = useState("");
  const navigate = useNavigate();
  const WJ_getTotalDays = (response) => {
    let totalDays;
    const date1 = new Date(response.data.data.expiry_date);
    if (date1.toString().toLowerCase() !== "Invalid Date".toLowerCase()) {
      const date2 = new Date();
      const difference = date1.getTime() - date2.getTime();
      totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    } else {
      totalDays = 0;
    }
    return totalDays;
  };
  const WJ_setUserData = async (response) => {
    console.log("Name", response);
    setName(response.data && response.data.data && response.data.data.user_name ? response.data.data.user_name : "");
    setEmail(response.data && response.data.data && response.data.data.user_email ? response.data.data.user_email : "");
    setContactNumber(
      response.data && response.data.data && response.data.data.contact_number ? response.data.data.contact_number : ""
    );
    setNameOfOrganization(
      response.data && response.data.data && response.data.data.name_of_organisation
        ? response.data.data.name_of_organisation
        : ""
    );
    setRoleInOrganization(
      response.data && response.data.data && response.data.data.role_in_organisation
        ? response.data.data.role_in_organisation
        : ""
    );
  };
  // Define get user details api function
  const WJ_getUserDetailsApiHandler = async () => {
    authCtx.showLoader();
    const response = await WJ_getUserDetails({ id_Token: authCtx.idToken });
    if (response.data && response.data.status && response.data.status === 200) {
      setIsEdit(true);
      setWJ_UserData(response?.data?.data[0]);
      // if (
      //   response.data &&
      //   response.data.data &&
      //   response.data.data.expiry_date
      // ) {
      //   const totalDays = WJ_getTotalDays(response);
      //   setRemainingTrialDays(totalDays >= 0 ? totalDays : 0);
      // }
      WJ_setUserData(response);
      console.log("UserData--->", response);
    } else {
      setName("");
      setEmail("");
      setContactNumber("");
      setNameOfOrganization("");
      setRoleInOrganization("");
      toast.error(response.message ?? "Something went wrong. Please try again later");
    }
    authCtx.hideLoader();
  };

  // Call get user documents api function after component loaded
  useEffect(() => {
    WJ_getUserDetailsApiHandler();
  }, []);
  // Define update user details api function
  const updateUserDetailsApiHandler = async () => {
    authCtx.showLoader();
    const formData = {
      user_name: name.trim(),
      contact_number: contactNumber.trim(),
      name_of_organisation: nameOfOrganization.trim(),
      role_in_organisation: roleInOrganization.trim(),
    };
    const response = await WJ_updateUserDetails({
      id_Token: authCtx.idToken,
      data: formData,
    });
    if (response.data?.status && response.data.status === 200) {
      setAction("edit");
      toast.success(response.data.message ?? "Profile updated successfully.");
    } else {
      toast.error(response.data?.message ?? "Something went wrong. Please try again later");
    }
    authCtx.hideLoader();
  };

  const WJ_validateName = (userName) => {
    let valid = true;
    if (userName.trim() === "") {
      setNameError("This field is required.");
      valid = false;
    }
    return valid;
  };

  const WJ_validateContactNumber = (number) => {
    let valid = true;
    if (number.trim() !== "") {
      const result = /^\+?\d+$/.test(number.trim());
      if (!result) {
        setContactNumberError("Contact number accepts digits and + at the start.");
        valid = false;
      }
      if (number.trim().length <= 6 || number.trim().length > 15) {
        setContactNumberError("Contact number length must be between 7 to 15.");
        valid = false;
      }
    }
    return valid;
  };
  // Handle form submit
  const WJ_submitHandler = (event) => {
    event.preventDefault();
    if (isEdit) {
      if (action === "edit") {
        setAction("update");
      } else {
        setNameError(null);
        setContactNumberError(null);
        if (!WJ_validateName(name)) {
          return;
        }
        if (!WJ_validateContactNumber(contactNumber)) {
          return;
        }
        updateUserDetailsApiHandler();
      }
    }
  };
  const WJ_navigateToDashboard = () => {
    navigate("/dashboard");
  };

  return (
      <>
        <Box sx={{ backgroundColor: colors.white, padding: '2rem', minHeight: '100vh' }}>
          <Stack direction="row" spacing={3} justifyContent="space-between">
            <Stack direction="column" spacing={3} className="w-1/4">
              <Box className="flex justify-center items-center">
                <AccountCircleOutlined style={{ fontSize: 80, color: colors.primary }} />
              </Box>
              {/* Uncomment if additional information is needed */}
              {/* <Stack direction="column" spacing={1}>
                <Typography variant="body1" color="textSecondary">Remaining Trial Days</Typography>
                <Typography variant="body1" color="textSecondary">Uploaded Pages</Typography>
                <Typography variant="h6" color="textPrimary">{remainingTrialDays ?? 0}</Typography>
                <Typography variant="h6" color="textPrimary">{uploadPagesCount ?? 0}</Typography>
              </Stack> */}
            </Stack>
            <Box className="w-3/4">
              <Stack
                component="form"
                spacing={3}
                onSubmit={WJ_submitHandler}
                className="bg-white shadow-lg rounded-lg p-6"
              >
                <Stack direction="row" spacing={3}>
                  <Box className="flex flex-col gap-2 w-1/2">
                    <Typography variant="subtitle1" className="font-semibold">
                      Name <span className="text-red-500">*</span>
                    </Typography>
                    <TextField
                      size="small"
                      id="name"
                      name="name"
                      value={name}
                      disabled={!isEdit || action !== 'update'}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                      variant="outlined"
                      sx={{
                        borderColor: 'gray.300',
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: colors.primary },
                        transition: 'border-color 0.3s ease',
                      }}
                    />
                    {nameError && <Typography color="error">{nameError}</Typography>}
                  </Box>
                  <Box className="flex flex-col gap-2 w-1/2">
                    <Typography variant="subtitle1" className="font-semibold">
                      Email <span className="text-red-500">*</span>
                    </Typography>
                    <TextField
                      disabled
                      size="small"
                      id="email"
                      name="email"
                      value={email}
                      fullWidth
                      variant="outlined"
                      sx={{ borderColor: 'gray.300' }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <Box className="flex flex-col gap-2 w-1/2">
                    <Typography variant="subtitle1" className="font-semibold">
                      Contact Number
                    </Typography>
                    <TextField
                      size="small"
                      id="contact-number"
                      name="contact-number"
                      value={contactNumber}
                      disabled={!isEdit || action !== 'update'}
                      onChange={(e) => setContactNumber(e.target.value)}
                      fullWidth
                      variant="outlined"
                      sx={{
                        borderColor: 'gray.300',
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: colors.primary },
                        transition: 'border-color 0.3s ease',
                      }}
                    />
                    {contactNumberError && <Typography color="error">{contactNumberError}</Typography>}
                  </Box>
                  <Box className="flex flex-col gap-2 w-1/2">
                    <Typography variant="subtitle1" className="font-semibold">
                      Name of Organization
                    </Typography>
                    <TextField
                      size="small"
                      id="name-of-organization"
                      name="name-of-organization"
                      value={nameOfOrganization}
                      disabled={!isEdit || action !== 'update'}
                      onChange={(e) => setNameOfOrganization(e.target.value)}
                      fullWidth
                      variant="outlined"
                      sx={{
                        borderColor: 'gray.300',
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: colors.primary },
                        transition: 'border-color 0.3s ease',
                      }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <Box className="flex flex-col gap-2 w-1/2">
                    <Typography variant="subtitle1" className="font-semibold">
                      Role in Organization
                    </Typography>
                    <TextField
                      size="small"
                      id="role-in-organization"
                      name="role-in-organization"
                      value={roleInOrganization}
                      disabled={!isEdit || action !== 'update'}
                      onChange={(e) => setRoleInOrganization(e.target.value)}
                      fullWidth
                      variant="outlined"
                      sx={{
                        borderColor: 'gray.300',
                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: colors.primary },
                        transition: 'border-color 0.3s ease',
                      }}
                    />
                  </Box>
                  <Box className="w-1/2" />
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    sx={{
                      backgroundColor: colors.navyBlue,
                      color: 'white !important',
                      '&:hover': {
                        backgroundColor: colors.navyBlueDark,
                      },
                      borderRadius: '8px',
                      padding: '0.5rem 2rem',
                      transition: 'background-color 0.3s ease',
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.navyBlue,
                      color: 'white !important',
                      '&:hover': {
                        backgroundColor: colors.navyBlueDark,
                      },
                      borderRadius: '8px',
                      padding: '0.5rem 2rem',
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    {action === 'edit' ? 'Edit' : 'Update'}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </>
  );
}

export default WJ_UserProfile;
