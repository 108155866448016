/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { useState } from 'react';
import { Typography, Button } from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  DeleteOutlined as DeleteIcon,
  Save as SaveIcon,
  Close as CancelIcon,
} from '@mui/icons-material';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { WJ_getRandomNumber } from '../../WJ_utils/WJ_Utils';

// Define add record component
function WJ_EditToolbar(props) {
  const { setRows, setRowModesModel, newRowColumns } = props;
  const WJ_handleClick = () => {
    const firsColumn = Object.keys(newRowColumns)[0] ?? '';
    const id = WJ_getRandomNumber();
    const newObj = {
      ...newRowColumns,
      id,
      isNew: true,
    };
    setRows((oldRows) => [
      ...oldRows,
      newObj,
      //   { id, Amount: '', Description: '', 'Package Quantity': '', isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: firsColumn },
      // [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Description' },
    }));
  };
  return (
    <GridToolbarContainer sx={{ padding: '10px' }}>
      <Button
        startIcon={<AddIcon />}
        onClick={WJ_handleClick}
        sx={{
          background: 'linear-gradient(90deg, #ED1C24 6.94%, #F04E30 100%)',
          color: 'white !important',
          padding: '3px 8px',
        }}
      >
        Add record
      </Button>
    </GridToolbarContainer>
  );
}
// Define no rows component
const WJ_CustomNoRowsOverlay = () => {
  return <Typography className="no-rows">No records.</Typography>;
};

function WJ_Table(props) {
  const [rows, setRows] = useState(props.data);
  const [rowModesModel, setRowModesModel] = useState({});
  let columns = [];
  const { newRowColumns } = props;
  const [promiseArguments, setPromiseArguments] = useState(null);
  // Handle row edit start
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  // Handle row edit stop
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  // Handle edit button click
  const handleEditClick = (id) => () => {
    const updatedRowModel = {
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    };
    setRowModesModel(updatedRowModel);
  };
  // Handle save button click
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  // Handle delete button click to show popup
  const handleDeleteClick = (id) => () => {
    setPromiseArguments({ id });
  };
  // Handle yes button click of delete confirmation popup
  const WJ_deleteClickHandler = (id) => () => {
    setPromiseArguments(null);
    props.onUpdate('delete', { id });
    setRows(rows.filter((row) => row.id !== id));
  };
  // Handle cancel button click
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  // Save data on save button click
  const processRowUpdate = (newRow) => {
    props.onUpdate(newRow.isNew ? 'add' : 'update', newRow);
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  // Define table columns
  if (rows.length > 0) {
    rows?.map((data, index) => {
      if (index === 0) {
        for (const [key] of Object.entries(data)) {
          if (key !== '' && key !== 'id' && key !== 'isNew') {
            columns.push({
              field: key,
              flex: 1,
              headerName: key.charAt(0).toUpperCase() + key.slice(1),
              // width: 200,
              minWidth: 200,
              maxWidth: 1000,
              editable: true,
              sortable: false,
              renderCell: (params) => (
                <span title={params.value}>{params.value}</span>
              ),
            });
          }
        }
      }
    });
  } else {
    columns = props.columns;
  }
  // Add actions columns
  if (columns.length > 0) {
    const firstColumn = columns[0] ?? {};
    if (firstColumn.field !== 'actions') {
      columns.unshift({
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(id)}
                title="Save"
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
                title="Cancel"
              />,
            ];
          }
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
              title="Edit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
              title="Delete"
            />,
          ];
        },
      });
    }
  }
  // Handle no button click of delete confirmation popup
  const WJ_handleNo = () => {
    setPromiseArguments(null);
  };
  // Define delete confirmation popup
  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { id } = promiseArguments;

    return (
      <Dialog maxWidth="xs" open={!!promiseArguments}>
        <DialogTitle sx={{ padding: '16px 80px' }}>
          Delete Confirmation
        </DialogTitle>
        <DialogContent dividers sx={{ padding: '25px 24px' }}>
          <Typography>Are you sure you want to delete record?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={WJ_handleNo}
            sx={{
              background: 'linear-gradient(90deg, #ED1C24 6.94%, #F04E30 100%)',
              color: 'white !important',
            }}
          >
            No
          </Button>
          <Button
            onClick={WJ_deleteClickHandler(id)}
            sx={{
              background: 'linear-gradient(90deg, #008000 6.94%, #008000 100%)',
              color: 'white !important',
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  // Set page number to show page
  if (rows.length > 0) {
    const lastRow = rows[rows.length - 1];
    if (lastRow.isNew) {
      const page = rows.length % 5;
      if (page === 0) {
        props.setTableActivePage(rows.length / 5 - 1);
      } else {
        props.setTableActivePage(Math.floor(rows.length / 5));
      }
    }
  }

  return (
    <div>
      {renderConfirmDialog()}
      <div style={{ height: '315px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: WJ_EditToolbar,
            NoRowsOverlay: WJ_CustomNoRowsOverlay,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel, newRowColumns },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          disableSelectionOnClick
          pageSize={5}
          page={props.tableActivePage}
          onPageChange={(newPage) => props.setTableActivePage(newPage)}
          sx={{
            height: '315px',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'white',
            },
            '& .css-yz9k0d-MuiInputBase-input': {
              padding: '1px 10px',
              margin: '0 5px',
              border: '1px solid black',
            },
            '& .MuiDataGrid-columnHeaders': {
              background: '#333333',
              color: 'white !important',
            },
            '& .css-mf45ow-MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .css-1b74o31-MuiInputBase-root-MuiDataGrid-editInputCell': {
              height: '60%',
            },
          }}
        />
      </div>
    </div>
  );
}

export default WJ_Table;
