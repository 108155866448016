import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaTrashAlt, FaUpload } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import {message} from 'antd'
import 'react-toastify/dist/ReactToastify.css';
import 'pdfjs-dist/build/pdf.worker';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const FileUploader = ({ maxFiles, allowedTypes, maxSize, uploadedFiles, uploadedPreviews, onFilesChange }) => {
  const [loading, setLoading] = useState(false);
  const maximumNoPages = 10

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    handleFiles(selectedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedFiles = Array.from(e.dataTransfer.files);
    handleFiles(selectedFiles);
  };

  const handleFiles = async (selectedFiles) => {
    if (selectedFiles.length + uploadedFiles?.length > maxFiles) {
      message.error(`You can only upload a maximum of ${maxFiles} files.`);
      return;
    }
  
    const validFiles = [];
    
    for (const file of selectedFiles) {
      const isValidType = allowedTypes.includes(file.type);
      const isValidSize = file.size <= maxSize;
  
      if (!isValidType) {
        message.error(`File type not allowed: ${file.name}`);
        continue;
      }
  
      if (!isValidSize) {
        message.error(`File size exceeds limit: ${file.name}`);
        continue;
      }
  
      if (file.type === 'application/pdf') {
        const pdf = await getDocument(URL.createObjectURL(file)).promise;
        const numPages = pdf.numPages;
  
        if (numPages > maximumNoPages) {
          message.error(`PDF exceeds the pages limit: ${maximumNoPages}: ${file.name}`);
          continue;
        }
      }
  
      validFiles.push(file);
    }
  
    if (validFiles.length > 0) {
      setLoading(true);
      const newFiles = [...uploadedFiles, ...validFiles];
      const newPreviews = await Promise.all(validFiles.map(file => generatePreview(file)));
      onFilesChange(newFiles, [...uploadedPreviews, ...newPreviews]);
      setLoading(false);
    }
  };
  

  const generatePreview = async (file) => {
    if (file.type === 'application/pdf') {
      const pdf = await getDocument(URL.createObjectURL(file)).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport }).promise;
      return canvas.toDataURL();
    } else {
      return URL.createObjectURL(file);
    }
  };

  const handleDelete = (index) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    const updatedPreviews = uploadedPreviews.filter((_, i) => i !== index);
    onFilesChange(updatedFiles, updatedPreviews);
  };

  return (
    <div className="flex flex-col items-center justify-center p-4 rounded-md">
      <div className='flex items-start justify-center p-3'>

        {/* <ToastContainer autoClose={3000} position="top-center" /> */}
        <div className='flex flex-col items-center justify-center rounded-md'>


         { uploadedFiles.length < maxFiles && <div
            className={`border-dashed border-4 w-full max-w-4xl border-gray-300 min-h-full p-10 text-center rounded-md cursor-pointer transition-transform transform hover:scale-105 ${uploadedFiles.length >= maxFiles ? 'cursor-not-allowed opacity-50' : ''
              }`}
            onDragOver={handleDragOver}
            onDrop={uploadedFiles.length < maxFiles ? handleDrop : null}
            onClick={uploadedFiles.length < maxFiles ? () => document.getElementById('fileInput').click() : null}
          >
            <input
              id="fileInput"
              type="file"
              accept=".pdf,.jpeg,.jpg,.png,.tiff"
              className="hidden"
              onChange={handleFileChange}
              multiple
              disabled={uploadedFiles.length >= maxFiles}
            />
            <FaUpload size={50} className="text-gray-500 mx-auto mb-4" />
            {uploadedFiles?.length === 0 ? (
              <>
                <p className="text-gray-700">Drag and drop files here, or click to select files <br /></p>
                <p className="text-gray-700 text-sm">Maximum file size: {maxSize / 1048576}MB</p>
                <p className="text-gray-700 text-sm">File Types: {"jpg/jpeg, PNG, PDF(10pages) "}</p>
              </>
            ) : (
              <p className="text-gray-700">{uploadedFiles.length} file(s) selected</p>
            )}
            {uploadedFiles.length >= maxFiles && (
              <p className="text-red-500 mt-2">Maximum file limit reached. Delete a file to upload more.</p>
            )}
          </div>}

          {loading && (
            <div className="">
              <div className="loader border-t-4 border-blue-500 rounded-full w-6 h-6 animate-spin"></div>
            </div>
          )}

          <div className="flex w-auto">
            <div className="">
              {uploadedFiles.length > 0 && <h2 className="text-lg font-semibold mb-2">File:</h2>}

              <ul className="space-y-2">
                {uploadedFiles.map((file, index) => (
                  <li key={index} className="flex justify-between items-center p-2 bg-white shadow rounded-md">
                    <div className="flex items-center">
                      <span className='text-lg'>{file.name}</span>
                    </div>
                    <button
                      className="text-red-500 hover:text-red-700 transition-colors"
                      onClick={() => handleDelete(index)}
                    >
                      <FaTrashAlt />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {uploadedPreviews.length > 0 && (
          <div className="w-1/2 ml-4 max-h-full">
            <h2 className="text-lg font-semibold mb-2">Preview:</h2>
            <div className="flex items-center justify-start">
              {uploadedPreviews.map((src, index) => (
                <div key={index} className="p-1 bg-white shadow rounded-md m-2">
                  {uploadedFiles[index].type.startsWith('image/') && (
                    <img src={src} alt="Preview" className="w-auto h-auto max-h-[70vh] fill-inherit m-2" />
                  )}
                  {uploadedFiles[index].type === 'application/pdf' && (
                    <img src={src} alt="PDF Preview" className="w-auto h-full max-h-[70vh] m-2" />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

FileUploader.propTypes = {
  maxFiles: PropTypes.number,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  maxSize: PropTypes.number,
  onFilesChange: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.arrayOf(PropTypes.object)
};

FileUploader.defaultProps = {
  maxFiles: 1,
  allowedTypes: ['image/jpeg', 'image/png', 'image/tiff', 'video/*', 'application/pdf'],
  maxSize: 10485760, // 10 MB
};

export default FileUploader;
