/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { Box, Stack, Typography, Button, Grid } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import tile2 from '../../../WJ_assets/images/tile2.png';
import tile3 from '../../../WJ_assets/images/tile3.png';

function WJ_Section3() {
  return (
    <Box className="section3-bg">
      <Box
        sx={{
          padding: '4% 10%',
        }}
      >
        <Grid container>
          <Grid item md={12}>
            <Typography className="section3-header">Case Studies</Typography>
          </Grid>
          <Grid item md={12} className="parenttext" sx={{ paddingLeft: '2%' }}>
            <Stack
              className="content1-box-right-box childtext1"
              direction={{ xs: 'column', md: 'row' }}
              gap="2rem"
            >
              <Box direction="row" className="content1-box-right-box1">
                <img src={tile2} alt="" />
                <Typography className="section3-text1">
                  Accelerate mortgage document processing
                </Typography>
                <Typography className="section3-text2">
                  Mortgage banks have become more efficient with a massive
                  improvement in decision-making and logic using robotics and
                  AI. With the introduction of AI technologies such as
                  DocuSense, mortgage banks have acquired efficiency and
                  velocity in the application, invoice, receipt, processing,
                  loan composition, services, underwriting, credit report and
                  recovery.
                </Typography>
                <Button
                  className="section3-button"
                  // variant="contained"
                  // endIcon={<EastIcon />}
                  // onClick={WJ_exploreButtonHandler}
                  style={{ color: 'white' }}
                >
                  Learn More&nbsp;&nbsp;
                  <EastIcon />
                </Button>
              </Box>
              <Box direction="row" className="content1-box-right-box1">
                <img src={tile2} alt="" />
                <Typography className="section3-text1">
                  Financial statement analyser
                </Typography>
                <Typography className="section3-text2">
                  The commercial banking process involves automating financial
                  documents to issuing commercial mortgages, preventing strict
                  money laundering and meeting customer verification
                  requirements, making it ideal for DocuSense automation. It
                  eliminates the process of repeating the same steps over and
                  over again. Whether it is the automation of the commercial
                  mortgage...
                </Typography>
              </Box>
              <Box direction="row" className="content1-box-right-box1">
                <img src={tile2} alt="" />
                <Typography className="section3-text1">
                  Manufacturing process forms analyser
                </Typography>
                <Typography className="section3-text2">
                  DocuSense automates paper-based order processing with
                  intelligent document processing, and it helps to transfer data
                  in a structured format to production/order dispatch, thus
                  speeding up the processing. After it is transferred to the
                  accounts receivable department, DocuSense helps with the
                  invoicing that can be shipped with the ordered item.
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default WJ_Section3;
